import styled from '../emotion/styled';

export const CanvasBar = styled('div')(p => ({
  marginBottom: p.theme.spacing() * 2,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  color: p.theme.palette.text.secondary,
  fontSize: p.theme.custom.fontSize.m
}));
