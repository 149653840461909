import firebase from 'firebase/app';
import { IBillingData } from './billing';
import { CurrencyCode } from './currency';
import { FeatureMap } from './features';
import { SpacePermissions } from './permissions';

/**
 * @deprecated
 */
export interface IScope {
  create?: boolean;
  read?: boolean;
  update?: boolean;
  delete?: boolean;
}

/**
 * @deprecated
 */
export interface IScopes {
  domain: IScope;
  product: IScope;
  scan: IScope;
  space: IScope;
  billing: IScope;
}

/**
 * @deprecated
 */
export const FULL_SCOPE: IScope = {
  create: true,
  read: true,
  update: true,
  delete: true
};

/**
 * @deprecated
 */
export const FULL_SCOPES: IScopes = {
  domain: FULL_SCOPE,
  product: FULL_SCOPE,
  scan: FULL_SCOPE,
  space: FULL_SCOPE,
  billing: FULL_SCOPE
};

export type ScopeCategory = keyof IScopes;
export type Scope = keyof IScope;

export interface IScopeGrant {
  granted: boolean;
  error?: {
    code: string;
    message: string;
  };
}

export interface IDomain {
  channelId: string;
  url: string;
  active: boolean;
  verified: boolean;
  subIdMatches: string[];
  subIdDefaultPrefix: string;
  sitemaps: string[] | null;
  redirectUrls: string[] | null;
  autoImport: boolean | null;
  urlFormat: 'url' | 'canonical';
  updateMethod: 'replace' | 'append';
  subidStrategy: 'network' | 'universal';
  ignoreParameters: string[] | null;
}

export interface IDomainCreateParams {
  spaceId: string;
  url: string;
}

export interface IDomainUpdateSettingsParams {
  spaceId: string;
  url: string;
  autoImport: boolean | null;
  sitemaps: string[] | null;
  redirectUrls: string[] | null;
  urlFormat: 'url' | 'canonical';
  updateMethod: 'replace' | 'append';
  subidStrategy: 'network' | 'universal';
  subIdMatches: string[];
  subIdDefaultPrefix: string;
  ignoreParameters: string[] | null;
}

export interface IDomainUpdateAutoImportParams {
  spaceId: string;
  url: string;
  autoImport: boolean | null;
}

export interface IDomainRemoveParams {
  spaceId: string;
  url: string;
}

export interface IDomainUpdateParams {
  spaceId: string;
  url: string;
  active: boolean;
}

export interface ISpaceConfig {
  name: string;
  tz: string;
  currency: CurrencyCode;
  // settings, brand design etc...
}

export interface ISpaceOnboardingItem {
  finishedAt: firebase.firestore.Timestamp;
  finishedBy: string;
}

export interface ISpace {
  id: string;
  /**
   * @deprecated
   */
  permissions: { [userId: string]: IScopes };
  permissionsV2: SpacePermissions;
  billing: IBillingData;
  apiToken: string;
  affiliateId: string;
  domains: IDomain[];
  config: ISpaceConfig;
  active: boolean;
  createdAt: firebase.firestore.Timestamp;
  createdBy: string;
  features: FeatureMap;
  crmId: string;

  onboarding: {
    verifiedWebsite?: ISpaceOnboardingItem | null;
    importedProducts?: ISpaceOnboardingItem | null;
  };
}

const HIDDEN_PREFIX = 'xxx-';

export const getHiddenSpaceId = (spaceId: string) =>
  `${HIDDEN_PREFIX}${spaceId}`;
export const isHiddenSpaceId = (spaceId: string) =>
  spaceId.startsWith(HIDDEN_PREFIX);

export const getTrueSpaceId = (spaceId: string) =>
  isHiddenSpaceId(spaceId) ? spaceId.slice(HIDDEN_PREFIX.length) : spaceId;

export const toArchivePath = (spaceId: string) =>
  `archive/spaces/${spaceId}.json`;

export const getSpaceDomainNames = (space: ISpace) =>
  space.domains.map((d) => d.url).join(', ');

export const DEFAULT_CURRENCY: CurrencyCode = 'USD';
