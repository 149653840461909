import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import firebase from 'firebase/app';
import { first, uniq, without } from 'lodash';
import React, { useState } from 'react';
import { Eye, EyeOff, Plus, X } from 'react-feather';
import { Link } from 'react-router-dom';
import { AlertBox } from '../../../../components/AlertBox';
import { AutocompleteSingleFreeSolo } from '../../../../components/Autocomplete';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { HelpIcon } from '../../../../components/HelpIcon';
import { Currency } from '../../../../components/Number';
import { IPostgresSale } from '../../../../domainTypes/performance';
import {
  AttributionRule,
  AttributionRuleApplication
} from '../../../../domainTypes/performanceLabelRules';
import { styled } from '../../../../emotion';
import { useRoutes } from '../../../../routes';
import { ARTICLES } from '../../../../services/beacon';
import { useCurrentUser } from '../../../../services/currentUser';
import { refreshTimestamp } from '../../../../services/db';
import { callFirebaseFunction } from '../../../../services/firebaseFunctions';
import { getAllPageMetadataInSpace } from '../../../../services/page';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { flushSalesCacheForSpace } from '../../../../services/sales/cache';
import { getActiveDomainUrls } from '../../../../services/space';
import { HUMAN_DATE_SHORT, toMoment } from '../../../../services/time';
import { EMPTY_RULE_GROUP, RuleBuilder } from './RuleBuilder';
import { config, createAttributionRule } from './service';
import { useBackgroundJobs } from '../../../../hooks/useBackgroundJobs';
import { useFlushAnalyticsV2Cache } from '../../../../services/analyticsV2/cache';

const ApplyOuterContainer = styled('div')`
  background: #f5faff;
  padding: ${(p) => p.theme.spacing(2)}px;

  &:hover {
    background: '#EBF5FF';
  }
`;

const ApplyContainer = styled('div')``;

const ApplyRow = styled('div')`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 3fr min-content;
  grid-column-gap: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(2)}px;
`;

export const EMPTY_RULE_APPLICATION: AttributionRuleApplication = {
  type: 'PAGE',
  value: ''
};

export const EMPTY_ATTRIBUTION_RULE = (spaceId: string) => ({
  spaceId,
  createdAt: firebase.firestore.Timestamp.now(),
  match: EMPTY_RULE_GROUP,
  apply: []
});

const possibleTypes: AttributionRuleApplication['type'][] = ['PAGE', 'SITE'];

const convertApplicationTypeToLabel = (
  t: AttributionRuleApplication['type']
) => {
  switch (t) {
    case 'PAGE':
      return 'Page URL';
    case 'SITE':
      return 'Origin domain';
    case 'PRODUCT':
      return 'Link';
  }
};

const matchesAny = (urls: string[], url: string) => {
  return urls.reduce((result, u) => {
    return result || url.indexOf(u) !== -1;
  }, false);
};

const DomainDropdown = ({
  value,
  onChange
}: {
  value: string;
  onChange: (newValue: string) => any;
}) => {
  const currentUser = useCurrentUser();
  const domains = getActiveDomainUrls(currentUser.space);

  return (
    <Select
      label="Domain"
      onChange={(e) => {
        const newValue = e.target.value as string;
        onChange(newValue);
      }}
      value={value}
      variant="outlined"
      fullWidth
    >
      {domains.map((d) => (
        <MenuItem key={d} value={d}>
          {d}
        </MenuItem>
      ))}
    </Select>
  );
};

const AttributionApplication = ({
  spaceId,
  attributions,
  onChange
}: {
  spaceId: string;
  attributions: AttributionRule['apply'];
  onChange: (attrs: AttributionRule['apply']) => any;
}) => {
  const typesInUse = attributions.map((a) => a.type);
  const hasDuplicateApplications =
    typesInUse.length !== uniq(typesInUse).length;
  const currentUser = useCurrentUser();
  const activeDomains = currentUser.space.domains
    .filter((d) => d.active)
    .map((d) => d.url);

  const getUpdatedAttrs = (
    newValue: string,
    field: 'type' | 'value',
    indexToUpdate: number,
    allAttributions: AttributionRuleApplication[]
  ) => {
    return allAttributions.map((el, j) => {
      return indexToUpdate === j ? { ...el, [field]: newValue } : el;
    });
  };

  return (
    <ApplyOuterContainer>
      <ApplyContainer>
        {attributions.map((attr, i) => (
          <ApplyRow>
            <div>
              <TextField
                value={attr.type}
                select
                variant="outlined"
                fullWidth
                onChange={(e) => {
                  const updatedValues = getUpdatedAttrs(
                    e.target.value as AttributionRuleApplication['type'],
                    'type',
                    i,
                    attributions
                  );
                  onChange(updatedValues);
                }}
              >
                {possibleTypes.map((t) => (
                  <MenuItem value={t} key={t}>
                    {convertApplicationTypeToLabel(t)}
                  </MenuItem>
                ))}
              </TextField>
            </div>
            <div>
              {attr.type === 'SITE' ? (
                <DomainDropdown
                  value={attr.value}
                  onChange={(newValue) => {
                    const updatedValues = getUpdatedAttrs(
                      newValue,
                      'value',
                      i,
                      attributions
                    );
                    onChange(updatedValues);
                  }}
                />
              ) : (
                <AutocompleteSingleFreeSolo
                  key="PAGE"
                  type="url"
                  label={'Page URL'}
                  fullWidth
                  value={attr.value}
                  onChange={(str) => {
                    const updatedValues = getUpdatedAttrs(
                      str,
                      'value',
                      i,
                      attributions
                    );
                    onChange(updatedValues);
                  }}
                  options={() =>
                    getAllPageMetadataInSpace(spaceId).then((docs) =>
                      docs
                        .map((d) => {
                          return d.data.url;
                        })
                        .filter((url) => {
                          try {
                            const { origin } = new URL(url);
                            return matchesAny(activeDomains, origin);
                          } catch (err) {
                            return false;
                          }
                        })
                        .sort()
                    )
                  }
                  required
                />
              )}
            </div>
            <IconButton
              onClick={() => {
                const withoutRow = attributions.filter((_, j) => {
                  return i !== j;
                });
                onChange(withoutRow);
              }}
            >
              <X size={16} />
            </IconButton>
          </ApplyRow>
        ))}
      </ApplyContainer>
      {attributions.length < 1 && (
        <Button
          color="primary"
          size="small"
          startIcon={<Plus size={18} />}
          onClick={() => {
            const typesAvailable = without(possibleTypes, ...typesInUse);
            const availableType = first(typesAvailable);
            if (availableType) {
              onChange([
                ...attributions,
                { ...EMPTY_RULE_APPLICATION, type: availableType }
              ]);
            }
          }}
        >
          Add attribution to apply
        </Button>
      )}
      {hasDuplicateApplications && (
        <AlertBox variant="pending">
          <strong>Attribution fields must be unique.</strong> You can only apply
          one change per field (e.g. Page URL, Site, or Link). Select a
          different field to update.
        </AlertBox>
      )}
      <br />
    </ApplyOuterContainer>
  );
};

const PreviewSectionWrapper = styled('div')`
  height: 200px;
  overflow-y: scroll;
  color: black;
  display: grid;
  grid-template-columns: 3fr 2fr 2fr 2fr 1fr;
  border: 1px solid ${(p) => p.theme.palette.grey[300]};
  padding: ${(p) => p.theme.spacing(2)}px;

  .heading {
    color: ${(p) => p.theme.palette.grey[500]};
    margin-bottom: ${(p) => p.theme.spacing(1)}px;
  }
`;

const NoResultsWrapper = styled('div')`
  height: 200px;
  overflow-y: scroll;
  color: black;
  border: 1px solid ${(p) => p.theme.palette.grey[300]};
  padding: ${(p) => p.theme.spacing(2)}px;
`;

const PreviewSection = ({
  transactions
}: {
  transactions: Partial<IPostgresSale>[];
}) => {
  if (transactions.length === 0) {
    return (
      <NoResultsWrapper>
        <Typography variant="body2" color="textSecondary" component="p">
          <strong>No transactions found matching this rule.</strong> Either no
          transactions match the conditions you've provided, or transactions
          that do match already have a rule applied to them.
        </Typography>
      </NoResultsWrapper>
    );
  }
  return (
    <PreviewSectionWrapper>
      <div className="heading">Product name</div>
      <div className="heading">Advertiser</div>
      <div className="heading">Platform</div>
      <div className="heading">Date</div>
      <div className="heading">Commission</div>
      {transactions.map((t) => {
        if (!t.partner_key) {
          return null;
        }
        const partner = getKnownPartnerForKey(t.partner_key);

        return (
          <>
            <div>{t.partner_product_name}</div>
            <div>{t.advertiser_name}</div>
            <div>{partner?.name}</div>
            <div>
              {t.sale_date &&
                toMoment(refreshTimestamp(t.sale_date)).format(
                  HUMAN_DATE_SHORT
                )}
            </div>
            <div>
              {t.r_currency && t.r_commission && (
                <Currency cents={t.r_commission} currency={t.r_currency} />
              )}{' '}
            </div>
          </>
        );
      })}
    </PreviewSectionWrapper>
  );
};

export const RuleCreateDialog = ({
  spaceId,
  open,
  onClose,
  onRuleCreated,
  value
}: {
  spaceId: string;
  open: boolean;
  onClose: () => void;
  onRuleCreated?: () => void;
  value?: AttributionRule;
}) => {
  const [attributionRule, setAttributionRule] = useState<AttributionRule>(
    value || EMPTY_ATTRIBUTION_RULE(spaceId)
  );
  const [applyToPastSales, setApplyToPastSales] = useState(true);
  const [transactions, setTransactions] = useState<Partial<IPostgresSale>[]>(
    []
  );
  const [showPreview, setShowPreview] = useState(false);
  const { ROUTES } = useRoutes();
  const { addJob } = useBackgroundJobs();
  const flushCache = useFlushAnalyticsV2Cache();

  const onSubmit = async () => {
    addJob({
      job: async () => {
        const doc = await createAttributionRule(attributionRule);
        if (applyToPastSales) {
          await callFirebaseFunction('labelRules-linkAttributionRule', {
            id: doc.id
          });
        }
      },
      onStart: () => {
        console.time('applying rule');
        onClose();
        return {
          message: (
            <>
              <CircularProgress color="inherit" size={16} /> &nbsp; Applying
              rule...
            </>
          )
        };
      },
      onSuccess: () => {
        if (onRuleCreated) {
          onRuleCreated();
        }
        flushSalesCacheForSpace(spaceId);
        flushCache(spaceId);
        console.timeEnd('applying rule');
        return {
          message: 'Applied rule successfully.'
        };
      },
      onError: (err) => {
        console.error(err);
        console.timeEnd('applying rule');
        return {
          message:
            'Applying rules has failed. Please delete the rule and try again.'
        };
      }
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      scroll="body"
      onClick={(e) => e.stopPropagation()}
    >
      <DialogTitle
        disableTypography
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}
      >
        <Typography
          variant="h6"
          style={{ display: 'flex', alignItems: 'center', gap: '12px' }}
        >
          <div>Create a rule</div>
          <HelpIcon color="blue" articleId={ARTICLES.labelRules.rulesV2}>
            How to use
          </HelpIcon>
        </Typography>
        <IconButton onClick={onClose}>
          <X size={24} />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body1"
          color="textSecondary"
          component="p"
          style={{ marginBottom: '24px' }}
        >
          Rules help you attribute transactions to your sites and pages where
          you can’t use{' '}
          <Link
            to={ROUTES.docs.knowledgeBase.url(ARTICLES.smartLabels.enable)}
            style={{ borderBottom: '1px solid' }}
          >
            Smart Labels
          </Link>{' '}
          to automate this process. For example, with historical data or
          platforms and commissions that lack SubIDs.
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          <strong>For transactions where:</strong>
        </Typography>
        <RuleBuilder
          value={attributionRule.match}
          onChange={(rule) => {
            setAttributionRule({ ...attributionRule, match: rule });
          }}
          config={config}
        />
        <div style={{ textAlign: 'right', margin: '12px 0' }}>
          <ButtonWithPromise
            pending={'Loading...'}
            color="primary"
            disabled={!attributionRule.match?.els.length}
            onClick={async () => {
              if (showPreview) {
                setShowPreview(false);
                return;
              }
              const res = await callFirebaseFunction(
                'labelRules-previewAttributionRuleMatches',
                { rule: attributionRule, spaceId, limit: 50 }
              );
              setTransactions(res as Partial<IPostgresSale>[]);
              setShowPreview(true);
            }}
          >
            {showPreview ? (
              <>
                <EyeOff size={16} /> &nbsp; Hide preview
              </>
            ) : (
              <>
                <Eye size={16} /> &nbsp; Preview matching transactions
              </>
            )}
          </ButtonWithPromise>
        </div>
        {showPreview && <PreviewSection transactions={transactions} />}
        <Typography
          variant="body1"
          component="p"
          paragraph
          style={{ marginTop: '24px' }}
        >
          <strong>Apply attribution:</strong>
        </Typography>
        <AttributionApplication
          spaceId={spaceId}
          attributions={attributionRule.apply}
          onChange={(attrs) => {
            setAttributionRule({ ...attributionRule, apply: attrs });
          }}
        />
      </DialogContent>
      <DialogActions>
        <div>
          <FormControlLabel
            control={
              <Checkbox
                checked={applyToPastSales}
                color="primary"
                onChange={(e) => {
                  setApplyToPastSales(e.target.checked);
                }}
                name="applyToPastSales"
              />
            }
            label="Apply to past transactions (Recommended)"
          />
        </div>
        <ButtonWithPromise
          color="primary"
          size="large"
          variant="contained"
          pending="Applying rule..."
          onClick={onSubmit}
        >
          Create rule
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};
