import { groupBy, memoize } from 'lodash';
import React from 'react';
import { IPartner, PARTNERS } from '../domainTypes/partners';
import { COLOR_SCALE, COLOR_UNKNOWN } from '../services/color';
import { crc32 } from '../services/crc32';
import { WithShape } from './Charts/Util';

const partners = groupBy(PARTNERS, (p) => `${p.key}---${p.name}`);

export const getAdvertiserColor = memoize(
  (advertiserName: string, partnerKey: string) => {
    if (advertiserName === 'Other' || advertiserName === 'Unknown') {
      return COLOR_UNKNOWN;
    }
    const isDirect = partners[`${partnerKey}---${advertiserName}`];
    if (isDirect && isDirect.length) {
      return isDirect[0].color;
    }
    const int = Math.abs(crc32(advertiserName));
    return COLOR_SCALE[int % COLOR_SCALE.length];
  }
);

export const AdvertiserWithColor = ({
  advertiserName,
  partnerKey
}: {
  advertiserName: string;
  partnerKey: IPartner['key'];
}) => {
  return (
    <WithShape large color={getAdvertiserColor(advertiserName, partnerKey)}>
      {advertiserName}
    </WithShape>
  );
};
