import moment from 'moment-timezone';
import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { styled } from '../../emotion';

type Props = {
  value: { start: moment.Moment; end: moment.Moment };
  onChange: (nextValue: { start: moment.Moment; end: moment.Moment }) => void;
};

const DateRangePickerContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiPickersBasePicker-pickerView {
    border: 1px solid ${(p) => p.theme.palette.text.hint};
  }
`;

const Separator = styled('div')`
  margin: ${(p) => p.theme.spacing(4)}px;
  font-size: 40px;
`;

export const DateRangePicker: React.FC<Props> = ({ value, onChange }) => {
  return (
    <DateRangePickerContainer>
      <DatePicker
        autoOk
        variant="static"
        openTo="date"
        value={value.start}
        clearable={false}
        onChange={(m) => onChange({ ...value, start: m! })}
      />

      <Separator>-</Separator>

      <DatePicker
        autoOk
        variant="static"
        openTo="date"
        value={value.end}
        clearable={false}
        onChange={(m) => onChange({ ...value, end: m! })}
      />
    </DateRangePickerContainer>
  );
};
