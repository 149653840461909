import { difference, keyBy, mapValues, sortBy } from 'lodash';
import moment from 'moment-timezone';
import { multiplyTimeframe } from '.';
import { toPercent } from '../../components/Number';
import {
  aggregateDailyCounters,
  DAY_FORMAT,
  EMPTY_COUNTER,
  EMPTY_DAILY_COUNTER,
  EMPTY_DEVICE_COUNTER,
  ICounter,
  ICounterWithTrend,
  ICountryCounter,
  IDailyCounter,
  IDeviceCounter,
  IDeviceShortCounter,
  IPageCounter,
  IProductCounter,
  IReferrerCounter,
  mergeCounter,
  reduceCounters,
  reduceTrendCounters,
  shortCounterToCounter,
  Timeframe,
  TIMEKEY_FORMAT
} from '../../domainTypes/analytics';
import {
  IDenomarlizationByPage,
  IDenormalization,
  IDenormalizationByClick,
  IDenormalizationByProduct,
  IDenormalizedClickEvent,
  PAGE_MIGRATIONS,
  PRODUCT_MIGRATIONS
} from '../../domainTypes/denormalization';
import { Doc, toDoc } from '../../domainTypes/document';
import { ISpace } from '../../domainTypes/space';
import { usePromise } from '../../hooks/usePromise';
import { DENORMALIZATION, FS } from '../../versions';
import { decompress } from '../compression';
import { refreshTimestamp, store, useMappedLoadingValue } from '../db';
import { idb } from '../idb';
import { createDeduplicationCache, logIdbError } from '../idb-tools';
import { splitInHalf } from '../list';
import {
  filterProductIdsByPartnerAtPointInTime,
  useProductsBySpaceId
} from '../products';
import { now } from '../time';
import { onlyHostname } from '../url';

const LOG_PERFORMANCE = false;

type Cache<T> = { [timeKey: string]: T };
type Caches<T> = { [spaceId: string]: Cache<T> };

type DbType =
  | 'denormalizationByProduct'
  | 'denormalizationByPage'
  | 'denormalizationByClick';

const byPageCaches: Caches<IDenomarlizationByPage> = {};
const byProductCaches: Caches<IDenormalizationByProduct> = {};
const byClickCaches: Caches<IDenormalizationByClick> = {};

const getCache = <T>(spaceId: string, caches: Caches<T>) => {
  if (!caches[spaceId]) {
    caches[spaceId] = {};
  }
  return caches[spaceId];
};

const toDayTimeKeyFromMoment = (d: moment.Moment): string => {
  return d.format(TIMEKEY_FORMAT);
};

export const padDailyCounters = (
  range: string[],
  counters: IDailyCounter[]
) => {
  if (process.env.NODE_ENV === 'development') {
    if (range.length < counters.length) {
      console.warn(
        `WARNING padDailyCounters - range (${range.length}) is shorter than counters (${counters.length}). Are counters unique?`
      );
    }
  }
  const byTimeKey = keyBy(counters, (c) => c.timeKey);
  return range.map(
    (timeKey) => byTimeKey[timeKey] || EMPTY_DAILY_COUNTER(timeKey)
  );
};

export const getTimekeyRangeFromDayFormat = (
  start: string,
  end: string
): string[] => {
  const s = moment(start, DAY_FORMAT);
  const e = moment(end, DAY_FORMAT);
  const result: string[] = [];
  while (s.isBefore(e)) {
    result.push(toDayTimeKeyFromMoment(s));
    s.add(1, 'day');
  }
  return result;
};

export const getTrend = (before: number, after: number) => {
  if (!after && !before) {
    return 0;
  }
  if (!before) {
    return 1;
  }
  return toPercent(after, before) - 1;
};

const collectTrends = (
  currentCounts: ICounter,
  lastCounts: ICounter
): ICounterWithTrend => {
  return {
    pageViews: {
      count: currentCounts.pageViews,
      lastCount: lastCounts.pageViews,
      trend: getTrend(lastCounts.pageViews, currentCounts.pageViews)
    },
    served: {
      count: currentCounts.served,
      lastCount: lastCounts.served,
      trend: getTrend(lastCounts.served, currentCounts.served)
    },
    viewed: {
      count: currentCounts.viewed,
      lastCount: lastCounts.viewed,
      trend: getTrend(lastCounts.viewed, currentCounts.viewed)
    },
    clicked: {
      count: currentCounts.clicked,
      lastCount: lastCounts.clicked,
      trend: getTrend(lastCounts.clicked, currentCounts.clicked)
    }
  };
};

export const toCounterWithTrend = (
  counters: IDailyCounter[],
  compare: boolean
): ICounterWithTrend => {
  if (!compare) {
    const counts = aggregateDailyCounters(counters);
    return collectTrends(counts, counts);
  }

  const [a, b] = splitInHalf(counters);
  const lastCounts = aggregateDailyCounters(a);
  const currentCounts = aggregateDailyCounters(b);

  return collectTrends(currentCounts, lastCounts);
};

const createQuery = (
  dbType: DbType,
  spaceId: string,
  start: string,
  end: string,
  tz: string
) => {
  const query = store().collection(dbType).where('spaceId', '==', spaceId);
  if (start === end) {
    return query.where('timeKey', '==', start).where('tz', '==', tz);
  }

  return query
    .where('timeKey', '>=', start)
    .where('timeKey', '<=', end)
    .where('tz', '==', tz);
};

const queriesInProgress: {
  [key: string]: Promise<Doc<IDenormalization>[]>;
} = {};

const toId = (spaceId: string, timeKey: string, tz: string) =>
  `${spaceId}-${timeKey}-${tz}`.replace('/', '-');

const idbCache = createDeduplicationCache();

const getFromIdb = async <T extends IDenormalization>(
  dbType: DbType,
  spaceId: string,
  range: string[],
  tz: string
): Promise<Doc<T>[]> => {
  const indexed: Doc<T>[] = [];
  try {
    // TX WITH CACHE
    // console.log('TX WIDTH CACHE');
    // const tx = (await idb).transaction(dbType, 'readwrite');
    // for (const tk of range) {
    //   const id = toId(spaceId, tk, tz);
    //   const promise = (idbCache[dbType][id] =
    //     idbCache[dbType][id] || (await tx.objectStore(dbType).get(id)));
    //   const idbData = await promise;
    //   if (idbData) {
    //     indexed.push((idbData as unknown) as Doc<T>);
    //   }
    // }
    // await tx.done;

    // TX NO CACHE
    // console.log('TX NO CACHE');
    // const tx = (await idb).transaction(dbType, 'readwrite');
    // for (const tk of range) {
    //   const id = toId(spaceId, tk, tz);
    //   const idbData = await tx.objectStore(dbType).get(id);
    //   if (idbData) {
    //     indexed.push((idbData as unknown) as Doc<T>);
    //   }
    // }
    // await tx.done;

    // SINGLES WITH CACHE
    // Due to us having so many duplicate requests, this proves to be the
    // fastest version. It might be overall a tiny bit slower than the
    // transaction version - but this feels much faster, as all results
    // are incoming as fast as possible. In the transaction version, even
    // small erquests might need to wait for a larger transaction (which happens
    // to contain an item the small request needs) to finish.
    // console.log('SINGLES WITH CACHE');
    for (const tk of range) {
      const id = toId(spaceId, tk, tz);
      const promise = idbCache.getOr(dbType, id, async () =>
        (await idb()).get(dbType, id)
      );
      const idbData = await promise;
      if (idbData) {
        indexed.push((idbData as unknown) as Doc<T>);
      }
    }

    // SINGLES NO CACHE
    // console.log('SINGLES NO CACHE');
    // for (const tk of range) {
    //   const id = toId(spaceId, tk, tz);
    //   const idbData = await (await idb).get(dbType, id);
    //   if (idbData) {
    //     indexed.push((idbData as unknown) as Doc<T>);
    //   }
    // }
  } catch (err) {
    logIdbError('ERR', err);
  }

  return indexed;
};

const saveInIdb = async <T extends IDenormalization>(
  dbType: DbType,
  docs: Doc<T>[]
) => {
  try {
    const tx = (await idb()).transaction(dbType, 'readwrite');
    for (const doc of docs) {
      await tx.store.put(doc as any);
    }
    await tx.done;
  } catch (err) {
    logIdbError('ERR', err);
  }
};

const getDataFromDb = async <T extends IDenormalization>(
  dbType: DbType,
  spaceId: string,
  range: string[],
  tz: string,
  normalize: (d: any) => T,
  noCache: boolean
): Promise<Doc<T>[]> => {
  if (!range.length) {
    return Promise.resolve([]);
  }

  const start = range[0];
  const end = range[range.length - 1];

  const queryKey = [dbType, spaceId, start, end, tz].join('---');

  if (!queriesInProgress[queryKey]) {
    const promise = new Promise<Doc<T>[]>((resolve, reject) => {
      const idbPromise: Promise<Doc<T>[]> = noCache
        ? Promise.resolve([])
        : getFromIdb<T>(dbType, spaceId, range, tz);
      idbPromise
        .then((docs) =>
          docs.map((d) => ({
            id: d.id,
            collection: dbType,
            data: normalize(d.data)
          }))
        )
        .then((docs) => {
          const diffRange = difference(
            range,
            docs.map((d) => d.data.timeKey)
          );
          if (!diffRange.length) {
            resolve(docs);
            return;
          }

          createQuery(
            dbType,
            spaceId,
            diffRange[0],
            diffRange[diffRange.length - 1],
            tz
          )
            .get()
            .then((s) => s.docs.map((d) => toDoc<T>(d, normalize)))
            .then((ds) => {
              if (noCache) {
                return ds;
              }
              // make sure we also save empty entries here, so tha twe don't constantly
              // refetch them?
              return saveInIdb(dbType, ds).then(
                () => ds,
                (err) => {
                  console.log('Failed to save in idb: ', err);
                  return ds;
                }
              );
            })
            .then((ds) => {
              delete queriesInProgress[queryKey];
              return sortBy([...docs, ...ds], (d) => d.data.timeKey);
            })
            .then(resolve, reject);
        });
    });
    queriesInProgress[queryKey] = promise;
  }
  return queriesInProgress[queryKey] as Promise<Doc<T>[]>;
};

const timings = {
  fastest: Infinity,
  slowest: 0,
  total: 0
};

const getData = async <T extends IDenormalization>(
  spaceId: string,
  timeframe: Timeframe,
  dbType: DbType,
  cache: Cache<T>,
  normalize: (d: any) => T,
  defaultEntry: (timeKey: string) => T,
  noCache = false
): Promise<T[]> => {
  const range = getTimekeyRangeFromDayFormat(timeframe.start, timeframe.end);
  const notInMemory = difference(range, Object.keys(cache)).sort();

  const start = Date.now();
  const docs = await getDataFromDb<T>(
    dbType,
    spaceId,
    notInMemory,
    timeframe.tz,
    normalize,
    noCache
  );
  const end = Date.now() - start;
  if (LOG_PERFORMANCE) {
    console.log('getData', end);
    if (timings.fastest > end) {
      timings.fastest = end;
    }
    if (timings.slowest < end) {
      timings.slowest = end;
    }
    timings.total = timings.total + end;
    console.log(timings);
  }

  docs.forEach((d) => (cache[d.data.timeKey] = d.data));

  return range.map((timeKey) => {
    if (!cache[timeKey]) {
      cache[timeKey] = defaultEntry(timeKey);
    }
    return cache[timeKey];
  });
};

export const normalizeDenormalizationByPage = (
  d: IDenomarlizationByPage
): IDenomarlizationByPage => {
  // We had some problems with window.location.origin returning undefined, therefore destroying
  // the href of pages - it can now look something like undefined/my-page-pathname.
  // Let's just ignore these.
  const pages = decompress((d.pages as unknown) as string);

  const result = PAGE_MIGRATIONS.reduce<IDenomarlizationByPage>(
    (m, data) => {
      if (m.version === data.from) {
        m.pages = mapValues(m.pages, data.migrate);
        m.version = data.to;
      }
      return m;
    },
    {
      ...d,
      pages,
      version: d.version || 'v1'
    }
  );

  for (const k in result.pages) {
    if (k.indexOf('undefined') === 0) {
      delete result.pages[k];
    }
  }

  return result;
};

export const normalizeDenormalizationByProduct = (
  d: IDenormalizationByProduct
): IDenormalizationByProduct => {
  const products = decompress((d.products as unknown) as string);
  const result = PRODUCT_MIGRATIONS.reduce<IDenormalizationByProduct>(
    (m, data) => {
      if (m.version === data.from) {
        m.products = mapValues(m.products, data.migrate);
        m.version = data.to;
      }
      return m;
    },
    {
      ...d,
      products,
      version: d.version || 'v1'
    }
  );

  // We had some problems with window.location.origin returning undefined, therefore destroying
  // the href of pages - it can now look something like undefined/my-page-pathname.
  // Let's just ignore these.
  for (const k in result.products) {
    const p = result.products[k];
    for (const pageUrl in p.byPage) {
      if (pageUrl.indexOf('undefined') === 0) {
        delete p.byPage[pageUrl];
      }
    }
  }

  return result;
};

export const normalizeDenormalizationByClick = (
  d: IDenormalizationByClick
): IDenormalizationByClick => {
  const events: IDenormalizedClickEvent[] = decompress(
    (d.events as unknown) as string
  );
  events.forEach((e) => {
    e.createdAt = refreshTimestamp(e.createdAt);
  });
  return {
    ...d,
    events
  };
};

/**
 * @deprecated Not in use
 */
export const getClickData = (
  spaceId: string,
  timeframe: Timeframe,
  noCache = false
) => {
  return getData<IDenormalizationByClick>(
    spaceId,
    timeframe,
    FS.denormalizationByClick as 'denormalizationByClick',
    noCache ? {} : getCache(spaceId, byClickCaches),
    normalizeDenormalizationByClick,
    (timeKey) => ({
      spaceId,
      timeKey,
      tz: timeframe.tz,
      denormalizedAt: now(),
      events: [],
      version: DENORMALIZATION.byClick.version
    }),
    noCache
  );
};

export const getClicksByTrackingIds = async (trackingIds: string[]) => {
  const refs = trackingIds.map((tId) =>
    store().collection(FS.clicksWithTrackingLabel).doc(tId)
  );
  return Promise.all(refs.map((ref) => ref.get())).then((snapshots) =>
    snapshots
      .filter((s) => s.exists)
      .map((s) => toDoc<IDenormalizedClickEvent>(s))
  );
};

// exported for admin app
export const getPageData = (
  spaceId: string,
  timeframe: Timeframe,
  noCache = false
) => {
  return getData<IDenomarlizationByPage>(
    spaceId,
    timeframe,
    FS.denormalizationByPage as 'denormalizationByPage',
    noCache ? {} : getCache(spaceId, byPageCaches),
    normalizeDenormalizationByPage,
    (timeKey) => ({
      spaceId,
      timeKey,
      tz: timeframe.tz,
      denormalizedAt: now(),
      pages: {},
      version: DENORMALIZATION.byPage.version
    }),
    noCache
  );
};

const getProductData = (
  spaceId: string,
  timeframe: Timeframe,
  noCache = false
) => {
  return getData<IDenormalizationByProduct>(
    spaceId,
    timeframe,
    FS.denormalizationByProduct as 'denormalizationByProduct',
    noCache ? {} : getCache(spaceId, byProductCaches),
    normalizeDenormalizationByProduct,
    (timeKey) => ({
      spaceId,
      timeKey,
      tz: timeframe.tz,
      denormalizedAt: now(),
      products: {},
      version: DENORMALIZATION.byProduct.version
    }),
    noCache
  );
};

const usePageData = <T>(
  spaceId: string,
  timeframe: Timeframe,
  mapFn: (data: IDenomarlizationByPage[]) => T,
  listeners: any[] = []
) => {
  return usePromise(() => getPageData(spaceId, timeframe).then(mapFn), [
    spaceId,
    timeframe.start,
    timeframe.end,
    timeframe.tz,
    ...listeners
  ]);
};

const useProductData = <T>(
  spaceId: string,
  timeframe: Timeframe,
  mapFn: (data: IDenormalizationByProduct[]) => T,
  listeners: any[] = []
) => {
  return usePromise(() => getProductData(spaceId, timeframe).then(mapFn), [
    spaceId,
    timeframe.start,
    timeframe.end,
    timeframe.tz,
    ...listeners
  ]);
};

export const usePageOccurrencesByProduct = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return useProductData(spaceId, timeframe, (data) => {
    const res: {
      [productId: string]: {
        [href: string]: Set<string>;
      };
    } = {};
    data.forEach(({ products }) => {
      Object.entries(products).forEach(([productId, p]) => {
        const containerA = (res[productId] = res[productId] || {});
        Object.entries(p.byPage).forEach(([href, occs]) => {
          const containerB = (containerA[href] = containerA[href] || new Set());
          Object.keys(occs).forEach((occ) => containerB.add(occ));
        });
      });
    });

    return mapValues(res, (v) => mapValues(v, (occs) => occs.size));
  });
};

export const useDenormalizedPageCountsInTimeFrame = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return usePageData(spaceId, timeframe, (data) => {
    return data.reduce<{ [href: string]: IDailyCounter[] }>((m, d) => {
      Object.keys(d.pages).forEach((href) => {
        const page = d.pages[href];
        const counters: IDailyCounter[] = m[href] || [];
        counters.push({
          pageViews: page.totalCounts.p,
          served: page.totalCounts.s,
          viewed: page.totalCounts.v,
          clicked: page.totalCounts.c,
          timeKey: d.timeKey
        });
        m[href] = counters;
      });
      return m;
    }, {});
  });
};

export const toProductCounters = (
  counters: {
    [productId: string]: {
      [occurrenceKey: string]: IDailyCounter[];
    };
  },
  timeframe: Timeframe,
  compare: boolean
) => {
  const range = getTimekeyRangeFromDayFormat(timeframe.start, timeframe.end);

  return Object.keys(counters).map<IProductCounter>((productId) => {
    const byOccurrence = Object.keys(counters[productId]).reduce<{
      [occurrenceKey: string]: ICounterWithTrend;
    }>((m, occurrenceKey) => {
      m[occurrenceKey] = toCounterWithTrend(
        padDailyCounters(range, counters[productId][occurrenceKey] || []),
        compare
      );
      return m;
    }, {});
    return {
      total: reduceTrendCounters(Object.values(byOccurrence)),
      byOccurrence,
      productId
    };
  });
};

export const useDenormalizedCountsInTimeframePerProductForProducts = (
  spaceId: string,
  filterProductIds: (timeKey: string, productIds: string[]) => string[],
  timeframe: Timeframe,
  compare: boolean
) => {
  const tf = compare ? multiplyTimeframe(timeframe, 2) : timeframe;
  return usePageData(
    spaceId,
    tf,
    (data) => {
      const counters = data.reduce<{
        [productId: string]: {
          [occurrenceKey: string]: IDailyCounter[];
        };
      }>((m, d) => {
        Object.values(d.pages).forEach((page) => {
          const pIds = filterProductIds(d.timeKey, Object.keys(page.byProduct));
          pIds.forEach((productId) => {
            const product = page.byProduct[productId];
            if (!product) {
              return;
            }
            const perProduct = (m[productId] = m[productId] || {});
            Object.keys(product).forEach((occurrenceKey) => {
              const cs: IDailyCounter[] = perProduct[occurrenceKey] || [];
              const occ = product[occurrenceKey];
              cs.push({
                pageViews: occ.p,
                served: occ.s,
                viewed: occ.v,
                clicked: occ.c,
                timeKey: d.timeKey
              });
              perProduct[occurrenceKey] = cs;
            });
          });
        });
        return m;
      }, {});

      return toProductCounters(counters, timeframe, compare);
    },
    [filterProductIds]
  );
};

export const useDenormalizedCountsinTimeframePerProductForPage = (
  spaceId: string,
  href: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const tf = compare ? multiplyTimeframe(timeframe, 2) : timeframe;
  return usePageData(
    spaceId,
    tf,
    (data) => {
      const counters = data.reduce<{
        [productId: string]: {
          [occurrenceKey: string]: IDailyCounter[];
        };
      }>((m, d) => {
        const page = d.pages[href];
        if (page) {
          Object.keys(page.byProduct).forEach((productId) => {
            const perProduct = (m[productId] = m[productId] || {});
            Object.keys(page.byProduct[productId]).forEach((occurrenceKey) => {
              const cs: IDailyCounter[] = perProduct[occurrenceKey] || [];
              const occ = page.byProduct[productId][occurrenceKey];
              cs.push({
                pageViews: occ.p,
                served: occ.s,
                viewed: occ.v,
                clicked: occ.c,
                timeKey: d.timeKey
              });
              perProduct[occurrenceKey] = cs;
            });
          });
        }
        return m;
      }, {});

      return toProductCounters(counters, tf, compare);
    },
    [href]
  );
};

export const useDenormalizedCountsInTimeframePerPageForProducts = (
  spaceId: string,
  filterProductIds: (timeKey: string, productIds: string[]) => string[],
  timeframe: Timeframe,
  compare: boolean
) => {
  const tf = compare ? multiplyTimeframe(timeframe, 2) : timeframe;
  return useProductData(
    spaceId,
    tf,
    (data) => {
      const counters = data.reduce<{
        [href: string]: {
          [occurrenceKey: string]: {
            [timeKey: string]: ICounter;
          };
        };
      }>((m, d) => {
        const productIds = filterProductIds(d.timeKey, Object.keys(d.products));
        productIds.forEach((productId) => {
          const product = d.products[productId];
          if (product) {
            Object.keys(product.byPage).forEach((href) => {
              const perPage = (m[href] = m[href] || {});
              Object.keys(product.byPage[href]).forEach((occurrenceKey) => {
                const cs: {
                  [timeKey: string]: ICounter;
                } = (perPage[occurrenceKey] = perPage[occurrenceKey] || {});
                const tkContainer = (cs[d.timeKey] =
                  cs[d.timeKey] || EMPTY_COUNTER());
                const occ = product.byPage[href][occurrenceKey];
                tkContainer.pageViews += occ.p;
                tkContainer.served += occ.s;
                tkContainer.viewed += occ.v;
                tkContainer.clicked += occ.c;
              });
            });
          }
        });
        return m;
      }, {});

      const range = getTimekeyRangeFromDayFormat(tf.start, tf.end);

      return Object.keys(counters).map<IPageCounter>((href) => {
        const byOccurrence = Object.keys(counters[href]).reduce<{
          [occurenceKey: string]: ICounterWithTrend;
        }>((m, occurenceKey) => {
          const dailyCounters = sortBy(
            Object.entries(counters[href][occurenceKey] || {}).map<
              IDailyCounter
            >(([timeKey, c]) => {
              return {
                timeKey,
                pageViews: c.pageViews,
                served: c.served,
                viewed: c.viewed,
                clicked: c.clicked
              };
            }),
            (x) => x.timeKey
          );
          m[occurenceKey] = toCounterWithTrend(
            padDailyCounters(range, dailyCounters),
            compare
          );
          return m;
        }, {});
        return {
          total: reduceTrendCounters(Object.values(byOccurrence)),
          byOccurrence,
          href
        };
      });
    },
    [filterProductIds]
  );
};

export const useDenormalizedCountsInTimeframe = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return usePageData(spaceId, timeframe, (data) => {
    const result: { [timekey: string]: ICounter[] } = {};
    data.forEach((d) => {
      Object.keys(d.pages).forEach((href) => {
        const r: ICounter[] = result[d.timeKey] || [];
        r.push(shortCounterToCounter(d.pages[href].totalCounts));
        result[d.timeKey] = r;
      });
    });

    return Object.keys(result).map<IDailyCounter>((timeKey) => ({
      ...reduceCounters(result[timeKey]),
      timeKey
    }));
  });
};

export const useDenormalizedCountsInTimeframeByProtocollessDomain = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return usePageData(spaceId, timeframe, (data) => {
    const result: { [domain: string]: { [timekey: string]: ICounter[] } } = {};
    data.forEach((d) => {
      Object.keys(d.pages).forEach((href) => {
        try {
          const domain = onlyHostname(href);
          const byDomain = (result[domain] = result[domain] || {});
          const r: ICounter[] = (byDomain[d.timeKey] =
            byDomain[d.timeKey] || []);
          r.push(shortCounterToCounter(d.pages[href].totalCounts));
        } catch {
          console.log('Could not parse href', href);
          return;
        }
      });
    });

    return mapValues(result, (v) => {
      return Object.keys(v).map<IDailyCounter>((timeKey) => ({
        ...reduceCounters(v[timeKey]),
        timeKey
      }));
    });
  });
};

export const useDenormalizedCountsInTimeframeForProduct = (
  spaceId: string,
  productId: string,
  timeframe: Timeframe
) => {
  return useProductData(
    spaceId,
    timeframe,
    (data) => {
      const result: { [timekey: string]: ICounter[] } = {};
      data.forEach((d) => {
        const p = d.products[productId];
        const r: ICounter[] = result[d.timeKey] || [];
        r.push(p ? shortCounterToCounter(p.totalCounts) : EMPTY_COUNTER());
        result[d.timeKey] = r;
      });

      return Object.keys(result).map<IDailyCounter>((timeKey) => ({
        ...reduceCounters(result[timeKey]),
        timeKey
      }));
    },
    [productId]
  );
};

export const useDenormalizedCountsInTimeframeForPartner = (
  spaceId: string,
  filterProductIds: (timeKey: string, productIds: string[]) => string[],
  timeframe: Timeframe
) => {
  return useProductData(
    spaceId,
    timeframe,
    (data) => {
      const result: { [timekey: string]: ICounter[] } = {};
      data.forEach((d) => {
        const productIds = filterProductIds(d.timeKey, Object.keys(d.products));
        productIds.forEach((productId) => {
          const p = d.products[productId];
          const r: ICounter[] = result[d.timeKey] || [];
          r.push(p ? shortCounterToCounter(p.totalCounts) : EMPTY_COUNTER());
          result[d.timeKey] = r;
        });
      });

      return Object.keys(result).map<IDailyCounter>((timeKey) => ({
        ...reduceCounters(result[timeKey]),
        timeKey
      }));
    },
    [spaceId, filterProductIds]
  );
};

export const useDenormalizedCountsInTimeframeForPage = (
  spaceId: string,
  href: string,
  timeframe: Timeframe
) => {
  return usePageData(
    spaceId,
    timeframe,
    (data) => {
      const result: { [timekey: string]: ICounter[] } = {};
      data.forEach((d) => {
        const p = d.pages[href];
        const r: ICounter[] = result[d.timeKey] || [];
        r.push(p ? shortCounterToCounter(p.totalCounts) : EMPTY_COUNTER());
        result[d.timeKey] = r;
      });

      return Object.keys(result).map<IDailyCounter>((timeKey) => ({
        ...reduceCounters(result[timeKey]),
        timeKey
      }));
    },
    [href]
  );
};

export const useDenormalizedProductCountsInTimeframe = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return useProductData(spaceId, timeframe, (data) => {
    return data.reduce<{ [productId: string]: IDailyCounter[] }>((m, d) => {
      Object.keys(d.products).forEach((productId) => {
        const product = d.products[productId];
        const counters: IDailyCounter[] = m[productId] || [];
        counters.push({
          timeKey: d.timeKey,
          // not using spread here, because it gives a big performance boost!
          pageViews: product.totalCounts.p,
          served: product.totalCounts.s,
          viewed: product.totalCounts.v,
          clicked: product.totalCounts.c
        });
        m[productId] = counters;
      });
      return m;
    }, {});
  });
};

export const addToDeviceCounter = (
  result: IDeviceCounter,
  counter: IDeviceShortCounter
) => {
  result.desktop = mergeCounter(
    result.desktop,
    shortCounterToCounter(counter.desktop)
  );
  result.mobile = mergeCounter(
    result.mobile,
    shortCounterToCounter(counter.mobile)
  );
  result.tablet = mergeCounter(
    result.tablet,
    shortCounterToCounter(counter.tablet)
  );
  result.unknown = mergeCounter(
    result.unknown,
    shortCounterToCounter(counter.unknown)
  );
  return result;
};

export const useDenormalizedDeviceClickCountsInTimeframe = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return usePageData(spaceId, timeframe, (data) => {
    return data.reduce<IDeviceCounter>((m, d) => {
      return Object.values(d.pages).reduce<IDeviceCounter>(
        (m2, p) => addToDeviceCounter(m2, p.byDevice),
        m
      );
    }, EMPTY_DEVICE_COUNTER());
  });
};

export const useDenormalizedDeviceClickCountsForPageInTimeframe = (
  spaceId: string,
  href: string,
  timeframe: Timeframe
) => {
  return usePageData(
    spaceId,
    timeframe,
    (data) => {
      return data.reduce<IDeviceCounter>((m, d) => {
        const p = d.pages[href];
        if (p) {
          return addToDeviceCounter(m, p.byDevice);
        }
        return m;
      }, EMPTY_DEVICE_COUNTER());
    },
    [href]
  );
};

export const useDenormalizedDeviceClickCountsForProductsInTimeframe = (
  spaceId: string,
  filterProductIds: (timeKey: string, productIds: string[]) => string[],
  timeframe: Timeframe
) => {
  return useProductData(
    spaceId,
    timeframe,
    (data) => {
      return data.reduce<IDeviceCounter>((m, d) => {
        return filterProductIds(d.timeKey, Object.keys(d.products)).reduce(
          (m2, productId) => {
            const p = d.products[productId];
            if (p) {
              return addToDeviceCounter(m2, p.byDevice);
            }
            return m2;
          },
          m
        );
      }, EMPTY_DEVICE_COUNTER());
    },
    [filterProductIds]
  );
};

export const useDenormalizedReferrerClickCountsForProductsInTimeframe = (
  spaceId: string,
  filterProductIds: (timeKey: string, productIds: string[]) => string[],
  timeframe: Timeframe
) => {
  return useProductData(
    spaceId,
    timeframe,
    (data) => {
      const result = data.reduce<{ [key: string]: IReferrerCounter }>(
        (m, d) => {
          // Deactivated - unused, but left for reference.
          // Couldn't store referrers this way in the denormalization,
          // as it was too expensive. Need to find a better way.

          // productIds.forEach(productId => {
          //   const p = d.products[productId];
          //   if (!p) {
          //     return;
          //   }
          //   Object.keys(p.byReferrer).forEach(referrer => {
          //     const counter = p.byReferrer[referrer];
          //     if (!m[referrer]) {
          //       m[referrer] = {
          //         name: referrer,
          //         href: referrer,
          //         total: 0
          //       };
          //     }
          //     m[referrer].total += counter.clicked;
          //   });
          // });
          return m;
        },
        {}
      );
      return Object.values(result);
    },
    [filterProductIds]
  );
};

export const useDenormalizedReferrerClickCountsForPageInTimeframe = (
  spaceId: string,
  href: string,
  timeframe: Timeframe
) => {
  return usePageData(
    spaceId,
    timeframe,
    (data) => {
      const result = data.reduce<{ [key: string]: IReferrerCounter }>(
        (m, d) => {
          // Deactivated - unused, but left for reference.
          // Couldn't store referrers this way in the denormalization,
          // as it was too expensive. Need to find a better way.

          // const p = d.pages[href];
          // if (p) {
          //   Object.keys(p.byReferrer).forEach(referrer => {
          //     const counter = p.byReferrer[referrer];
          //     if (!m[referrer]) {
          //       m[referrer] = {
          //         name: referrer,
          //         href: referrer,
          //         total: 0
          //       };
          //     }
          //     m[referrer].total += counter.clicked;
          //   });
          // }
          return m;
        },
        {}
      );
      return Object.values(result);
    },
    [href]
  );
};

export const useDenormalizedReferrerClickCounsInTimeframe = (
  spaceId: string,
  timeframe: Timeframe
) => {
  return usePageData(spaceId, timeframe, (data) => {
    const result = data.reduce<{ [key: string]: IReferrerCounter }>((m, d) => {
      // Deactivated - unused, but left for reference.
      // Couldn't store referrers this way in the denormalization,
      // as it was too expensive. Need to find a better way.

      // Object.keys(d.pages).forEach(href => {
      //   const p = d.pages[href];
      //   if (p) {
      //     Object.keys(p.byReferrer).forEach(referrer => {
      //       const counter = p.byReferrer[referrer];
      //       if (!m[referrer]) {
      //         m[referrer] = {
      //           name: referrer,
      //           href: referrer,
      //           total: 0
      //         };
      //       }
      //       m[referrer].total += counter.clicked;
      //     });
      //   }
      // });
      return m;
    }, {});
    return Object.values(result);
  });
};

export const toCountryCounters = (
  counters: {
    [timeKey: string]: {
      [countryCode: string]: ICounter;
    };
  },
  timeframe: Timeframe,
  compare: boolean
) => {
  const byCountry: {
    [countryCode: string]: IDailyCounter[];
  } = {};

  Object.keys(counters).forEach((timeKey) => {
    Object.keys(counters[timeKey]).forEach((country) => {
      const container = (byCountry[country] = byCountry[country] || []);
      // destructive operation, but that's ok - saving some CPU load here
      const counter = counters[timeKey][country] as IDailyCounter;
      counter.timeKey = timeKey;
      container.push(counter);
    });
  });
  const range = getTimekeyRangeFromDayFormat(timeframe.start, timeframe.end);
  return Object.keys(byCountry).map<ICountryCounter>((code) => ({
    code,
    total: toCounterWithTrend(
      padDailyCounters(range, byCountry[code] || []),
      compare
    )
  }));
};

export const useDenormalizedCountryClickCountsInTimeframe = (
  spaceId: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const tf = compare ? multiplyTimeframe(timeframe, 2) : timeframe;
  return usePageData(spaceId, tf, (data) => {
    const byTimeKey: {
      [timeKey: string]: {
        [countryCode: string]: ICounter;
      };
    } = {};
    data.forEach((d) => {
      const container = (byTimeKey[d.timeKey] = byTimeKey[d.timeKey] || {});
      Object.values(d.pages).forEach((p) => {
        Object.keys(p.byCountry).forEach((country) => {
          container[country] = mergeCounter(
            container[country] || EMPTY_COUNTER(),
            shortCounterToCounter(p.byCountry[country])
          );
        });
      });
    });

    return toCountryCounters(byTimeKey, tf, compare);
  });
};

export const useDenormalizedCountryClickCountsForProductsInTimeframe = (
  spaceId: string,
  filterProductIds: (timeKey: string, productIds: string[]) => string[],
  timeframe: Timeframe,
  compare: boolean
) => {
  const tf = compare ? multiplyTimeframe(timeframe, 2) : timeframe;
  return useProductData(
    spaceId,
    tf,
    (data) => {
      const byTimeKey: {
        [timeKey: string]: {
          [countryCode: string]: ICounter;
        };
      } = {};
      data.forEach((d) => {
        filterProductIds(d.timeKey, Object.keys(d.products)).forEach(
          (productId) => {
            const p = d.products[productId];
            if (!p) {
              return;
            }
            const container = (byTimeKey[d.timeKey] =
              byTimeKey[d.timeKey] || {});
            Object.keys(p.byCountry).forEach((country) => {
              container[country] = mergeCounter(
                container[country] || EMPTY_COUNTER(),
                shortCounterToCounter(p.byCountry[country])
              );
            });
          }
        );
      });

      return toCountryCounters(byTimeKey, tf, compare);
    },
    [filterProductIds]
  );
};

export const useDenormalizedCountryclickCountsForPageInTimeframe = (
  spaceId: string,
  href: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const tf = compare ? multiplyTimeframe(timeframe, 2) : timeframe;
  return usePageData(
    spaceId,
    tf,
    (data) => {
      const byTimeKey: {
        [timeKey: string]: {
          [countryCode: string]: ICounter;
        };
      } = {};
      data.forEach((d) => {
        const p = d.pages[href];
        if (!p) {
          return;
        }
        const container = (byTimeKey[d.timeKey] = byTimeKey[d.timeKey] || {});
        Object.keys(p.byCountry).forEach((country) => {
          container[country] = mergeCounter(
            container[country] || EMPTY_COUNTER(),
            shortCounterToCounter(p.byCountry[country])
          );
        });
      });

      return toCountryCounters(byTimeKey, tf, compare);
    },
    [href]
  );
};

export const usePartnerFilter = (space: ISpace, partnerKey: string) => {
  return useMappedLoadingValue(useProductsBySpaceId(space.id), (ps) => {
    const productsById = keyBy(ps, (p) => p.id);
    return (timeKey: string, productIds: string[]) => {
      return filterProductIdsByPartnerAtPointInTime(
        partnerKey,
        timeKey,
        space.config.tz || 'UTC',
        productIds,
        productsById
      );
    };
  });
};
