import { Tooltip } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { chunk, orderBy } from 'lodash';
import React, { createElement, CSSProperties, useMemo } from 'react';
import { Info as IconInfo } from 'react-feather';
import { Link } from 'react-router-dom';
import { styled } from '../../emotion';
import { useIsVisible } from '../../hooks/useIsVisible';
import { reverseDirection, SortDirection } from '../../hooks/useSort';
import {
  queryParamToList,
  setToQueryParam,
  useComboQueryParam,
  useQueryParam
} from '../../routes';
import { isSameSet } from '../../services/set';
import { IColumn } from '../Table/Column';

export type SortValue = string | number | boolean | null;

export type Group<Summary, Item> = {
  key: string;
  summary: Summary;
  items: Item[];
};

export type ItemSorter<Item> = {
  key: string;
  items: {
    sort: ((s: Item) => SortValue) | ((s: Item) => SortValue)[];
    dir: SortDirection;
  };
};

export type SummarySorter<Summary, Item> = {
  key: string;
  groups: {
    sort: (g: Group<Summary, Item>) => SortValue;
    dir: SortDirection;
  };
};

export type Sorter<Summary, Item> = ItemSorter<Item> &
  SummarySorter<Summary, Item> & { label: string };

export type Grouper<Type extends string, Summary, Item> = {
  key: Type;
  label: React.ReactNode;
  toKey: (t: Item) => string;
  toLabel: (g: Group<Summary, Item>) => React.ReactNode;
  defaultSorter: Sorter<Summary, Item>;
};

export type Groupers<Type extends string, Summary, Item> = {
  [K in Type]: Grouper<Type, Summary, Item>;
};

export type Sorters<Summary, Item> = { [key: string]: Sorter<Summary, Item> };
export type ItemSorters<Item> = { [key: string]: ItemSorter<Item> };

export const applySorterOnItems = <T extends object>(
  sorter: ItemSorter<T>,
  items: T[],
  direction: SortDirection | undefined
) => {
  const { sort, dir } = sorter.items;
  const fns = Array.isArray(sort) ? sort : [sort];
  return orderBy(items, fns, direction || dir);
};

export const applySorterOnGroups = <
  S extends object,
  T extends object,
  X extends Group<S, T>
>(
  sorter: SummarySorter<S, T>,
  groups: X[],
  direction: SortDirection | undefined
) => {
  return orderBy(
    groups,
    (x) => sorter.groups.sort(x),
    direction || sorter.groups.dir
  );
};

export const UNKNOWN = 'Unknown';

const TableHead = styled<'div', { sticky?: boolean; offset?: number }>('div')`
  position: ${(p) => (p.sticky ? 'sticky' : 'relative')};
  ${(p) => (p.sticky && p.offset !== undefined ? `top: ${p.offset}px;` : '')}
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
  padding-top: ${(p) => p.theme.spacing(2)}px;
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  color: ${(p) => p.theme.palette.grey[500]};
  border-top-left-radius: ${(p) => p.theme.shape.borderRadius}px;
  border-top-right-radius: ${(p) => p.theme.shape.borderRadius}px;
  min-height: 25px;
  background: inherit;
  z-index: 1;
`;

const TableRow = styled<'div', { rowHeight: number; cursor: string }>('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: ${(p) => p.cursor};
  padding: ${(p) => p.theme.spacing(1)}px ${(p) => p.theme.spacing(2)}px;
  min-height: ${(p) => p.rowHeight}px;

  :hover {
    background-color: rgba(0, 0, 0, 0.07);
  }
`;

const CellS = styled('div')`
  padding: 0 ${(p) => p.theme.spacing(1)}px;
`;

const HeadWithInfo = styled('span')`
  svg {
    position: relative;
    top: 3px;
    right: -2px;
  }
`;

const Head = <OtherProps extends object | undefined>({
  column,
  otherProps,
  sorted,
  sortable,
  onClick
}: {
  column: IColumn<any, any, OtherProps>;
  otherProps: OtherProps;
  sorted?: SortDirection | null | undefined;
  sortable?: boolean;
  onClick?: () => void;
}) => {
  const style = useMemo(
    () => ({
      flexGrow: column.flexGrow,
      width: column.width,
      textAlign: column.align,
      fontWeight: (sorted ? 'bolder' : 'normal') as CSSProperties['fontWeight'],
      cursor: sortable ? 'pointer' : 'default'
    }),
    [column, sorted, sortable]
  );
  const leader = sorted === 'asc' ? '▲ ' : sorted === 'desc' ? '▼ ' : '';
  const body = (
    <>
      {leader}
      {column.head(otherProps)}
    </>
  );
  const headInfo = column.headInfo ? column.headInfo(otherProps) : null;
  return (
    <CellS style={style} role={onClick && 'button'} onClick={onClick}>
      {headInfo ? (
        <Tooltip title={headInfo} aria-label={headInfo} placement="top">
          <HeadWithInfo>
            {body} <IconInfo size={14} />
          </HeadWithInfo>
        </Tooltip>
      ) : (
        body
      )}
    </CellS>
  );
};

const Cell = <T extends object, OtherProps extends object | undefined>({
  d,
  column,
  otherProps
}: {
  d: T;
  column: IColumn<T, any, OtherProps>;
  otherProps: OtherProps;
}) => {
  const style = useMemo(
    () => ({
      flexGrow: column.flexGrow,
      width: column.width,
      textAlign: column.align
    }),
    [column]
  );
  return <CellS style={style}>{column.cell(d, otherProps)}</CellS>;
};

const Rows = <
  T extends object,
  C extends string,
  OtherProps extends object | undefined
>({
  rows,
  columns,
  renderPlaceholder,
  rowHeight,
  rowToKey,
  rowToHref,
  rowToClassName,
  onRowClick,
  otherProps
}: {
  rows: T[];
  columns: IColumn<T, C, OtherProps>[];
  renderPlaceholder: boolean;
  rowHeight: number;
  rowToKey: (row: T, i: number) => string;
  rowToHref?: (d: T, o: OtherProps) => string | null;
  rowToClassName?: (d: T, o: OtherProps) => string;
  onRowClick?: (d: T, o: OtherProps) => void;
  otherProps: OtherProps;
}) => {
  if (renderPlaceholder) {
    return <div style={{ height: rows.length * rowHeight }} />;
  }
  return (
    <>
      {rows.map((d, i) => {
        const key = rowToKey(d, i);
        const href = rowToHref && rowToHref(d, otherProps);
        const body = (
          <TableRow
            key={key}
            rowHeight={rowHeight}
            className={rowToClassName && rowToClassName(d, otherProps)}
            cursor={onRowClick || href ? 'pointer' : 'default'}
            role={onRowClick || href ? 'button' : undefined}
            onClick={onRowClick && (() => onRowClick(d, otherProps))}
          >
            {columns.map((c) => (
              <Cell key={c.key} column={c} d={d} otherProps={otherProps} />
            ))}
          </TableRow>
        );
        if (href) {
          return (
            <Link key={key} to={href}>
              {body}
            </Link>
          );
        }
        return body;
      })}
    </>
  );
};

const RowsWithVisCheck = <
  T extends object,
  C extends string,
  OtherProps extends object | undefined
>({
  rootMargin,
  ...props
}: {
  rows: T[];
  columns: IColumn<T, C, OtherProps>[];
  rowHeight: number;
  rowToKey: (row: T, i: number) => string;
  rowToHref?: (d: T, o: OtherProps) => string | null;
  rowToClassName?: (d: T, o: OtherProps) => string;
  onRowClick?: (d: T, o: OtherProps) => void;
  otherProps: OtherProps;
  rootMargin: string;
}) => {
  const { isVisible, ref } = useIsVisible({ rootMargin });

  return (
    <div ref={ref as any}>
      <Rows {...props} renderPlaceholder={!isVisible} />
    </div>
  );
};

const Container = styled(Paper)`
  padding-top: 0;
`;

export type RowsRendererProps<
  T extends object,
  C extends string,
  OtherProps extends object | undefined
> = {
  rows: T[];
  columns: IColumn<T, C, OtherProps>[];
  sorter: ItemSorter<T>;
  sortDirection?: SortDirection | undefined;
  renderHead?: boolean;
  headProps?: {
    sticky?: boolean;
    offset?: number;
  };
  chunkSize?: number;
  rootMargin?: string;
  otherProps: OtherProps;
  rowHeight?: number;
  rowToKey: (row: T, i: number) => string;
  variant?: 'plain' | 'contained';
  onHeadClick?: (
    column: IColumn<T, C, OtherProps>,
    direction: SortDirection | undefined
  ) => void;
  rowToHref?: (d: T, o: OtherProps) => string | null;
  rowToClassName?: (d: T, o: OtherProps) => string;
  onRowClick?: (d: T, o: OtherProps) => void;
};

export const RowsRenderer = <
  T extends object,
  C extends string,
  OtherProps extends object | undefined
>({
  rows,
  columns,
  sorter,
  sortDirection,
  renderHead = false,
  headProps = {},
  chunkSize = 30,
  rootMargin = '400px',
  otherProps,
  rowHeight = 40,
  rowToKey,
  variant = 'contained',
  onHeadClick,
  rowToHref,
  rowToClassName,
  onRowClick
}: RowsRendererProps<T, C, OtherProps>) => {
  const sorted = applySorterOnItems(sorter, rows, sortDirection);
  const { isVisible, ref } = useIsVisible({ rootMargin });
  const [first = [], ...others] = chunk(sorted, chunkSize); // = [] so that destructuring over an empty array works
  const additionalPadding = !renderHead ? { paddingTop: 16 } : undefined;

  return createElement(
    variant === 'contained' ? Container : 'div',
    { ref, style: additionalPadding },
    renderHead && (
      <TableHead sticky={headProps.sticky} offset={headProps.offset}>
        {isVisible &&
          columns.map((c) => {
            const isSorted = c.key === sorter.key;
            const dir = sortDirection || sorter.items.dir;
            return (
              <Head
                key={c.key}
                column={c}
                otherProps={otherProps}
                sorted={isSorted ? dir : null}
                sortable={c.sortable}
                onClick={() => {
                  if (onHeadClick) {
                    const nextDir = isSorted
                      ? reverseDirection(dir)
                      : undefined;
                    onHeadClick(c, nextDir);
                  }
                }}
              />
            );
          })}
      </TableHead>
    ),
    <Rows
      rows={first}
      columns={columns}
      renderPlaceholder={!isVisible}
      rowHeight={rowHeight}
      rowToKey={rowToKey}
      rowToClassName={rowToClassName}
      otherProps={otherProps}
      rowToHref={rowToHref}
      onRowClick={onRowClick}
    />,
    others.map((xs, i) => (
      <RowsWithVisCheck
        key={i}
        rows={xs}
        columns={columns}
        rowHeight={rowHeight}
        rowToKey={rowToKey}
        otherProps={otherProps}
        rootMargin={rootMargin}
        rowToHref={rowToHref}
        rowToClassName={rowToClassName}
        onRowClick={onRowClick}
      />
    ))
  );
};

export const useSortQueryParam = <T extends { key: string }>(
  paramName: string,
  sorters: { [key: string]: T }
) => {
  return useComboQueryParam<[T | null, SortDirection | undefined]>(
    paramName,
    ([pSort, pDir]) => [
      pSort ? sorters[pSort] || null : null,
      pDir ? (pDir as SortDirection) : undefined
    ],
    ([s, d]) => [s ? s.key : undefined, d || undefined],
    '---',
    [sorters]
  );
};

export const useColumnsQueryParam = <ColumnName extends string>(
  param: string,
  defaultColumns: ColumnName[]
) => {
  return useQueryParam(
    param,
    (p) => new Set(p ? queryParamToList<ColumnName>(p) : defaultColumns),
    (cs) =>
      isSameSet(cs, new Set(defaultColumns)) ? undefined : setToQueryParam(cs)
  );
};

export const ROW_HEIGHTS = {
  dense: 40,
  airy: 80
};

export const ID_TO_ROW_KEY = <T extends { id: string }>(d: T) => d.id;
