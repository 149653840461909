import { IProductIssue } from './product';
import firebase from 'firebase/app';

export type Mode = 'absolute-numbers' | 'absolute-growth' | 'relative-growth';

export interface ICounter {
  pageViews: number;
  served: number;
  viewed: number;
  clicked: number;
}

export interface IShortCounter {
  p: number;
  s: number;
  v: number;
  c: number;
}

export const shortCounterToCounter = (
  shortCounter: IShortCounter
): ICounter => ({
  pageViews: shortCounter.p,
  served: shortCounter.s,
  viewed: shortCounter.v,
  clicked: shortCounter.c
});

export const counterToShortCounter = (counter: ICounter): IShortCounter => ({
  p: counter.pageViews,
  s: counter.served,
  v: counter.viewed,
  c: counter.clicked
});

export interface ICountWithTrend {
  count: number;
  lastCount: number;
  trend: number;
}

export interface ICounterWithTrend {
  pageViews: ICountWithTrend;
  served: ICountWithTrend;
  viewed: ICountWithTrend;
  clicked: ICountWithTrend;
}

export const mapCounter = (
  counter: ICounterWithTrend,
  mapFn: (val: ICountWithTrend, key: keyof ICounterWithTrend) => number
): ICounter => ({
  pageViews: mapFn(counter.pageViews, 'pageViews'),
  served: mapFn(counter.served, 'served'),
  viewed: mapFn(counter.viewed, 'viewed'),
  clicked: mapFn(counter.clicked, 'clicked')
});

export const toAbsoluteCounter = (
  counter: ICounterWithTrend
): ICounterWithTrend => {
  const toAbsolute = (c: ICountWithTrend): ICountWithTrend => ({
    count: c.count + c.lastCount,
    lastCount: 0,
    trend: 0
  });
  return {
    pageViews: toAbsolute(counter.pageViews),
    served: toAbsolute(counter.served),
    viewed: toAbsolute(counter.viewed),
    clicked: toAbsolute(counter.clicked)
  };
};

export const EMPTY_COUNTER = (): ICounter => ({
  pageViews: 0,
  served: 0,
  viewed: 0,
  clicked: 0
});

export const EMPTY_SHORT_COUNTER = (): IShortCounter => ({
  p: 0,
  s: 0,
  v: 0,
  c: 0
});

// Don't just reuse and destructure EMPTY_COUNTER.
// Duplication on purpose, because it's speedier that way!
export const EMPTY_DAILY_COUNTER = (timeKey: string): IDailyCounter => ({
  timeKey,
  pageViews: 0,
  served: 0,
  viewed: 0,
  clicked: 0
});

export const EMPTY_COUNT_WITH_TREND = (): ICountWithTrend => ({
  count: 0,
  lastCount: 0,
  trend: 0
});

export const EMPTY_TREND_COUNTER = (): ICounterWithTrend => ({
  pageViews: EMPTY_COUNT_WITH_TREND(),
  served: EMPTY_COUNT_WITH_TREND(),
  viewed: EMPTY_COUNT_WITH_TREND(),
  clicked: EMPTY_COUNT_WITH_TREND()
});

export interface IDailyCounter extends ICounter {
  timeKey: string;
}

export const mergeCounter = (a: ICounter, b: ICounter): ICounter => ({
  pageViews: a.pageViews + b.pageViews,
  served: a.served + b.served,
  viewed: a.viewed + b.viewed,
  clicked: a.clicked + b.clicked
});

export const safeMergeCounter = (
  a: ICounter | undefined,
  b: ICounter | undefined
): ICounter => {
  if (a && b) {
    return mergeCounter(a, b);
  }
  if (a && !b) {
    return a;
  }
  if (b && !a) {
    return b;
  }
  return EMPTY_COUNTER();
};

const toPercent = (part?: number, total?: number) => {
  return part && total ? part / total : 0;
};

export const getTrend = (before: number, after: number) => {
  if (!after && !before) {
    return 0;
  }
  if (!before) {
    return 1;
  }
  return toPercent(after, before) - 1;
};

export const recalculateTrend = (d: ICountWithTrend) => {
  return {
    count: d.count,
    lastCount: d.lastCount,
    trend: getTrend(d.lastCount, d.count)
  };
};

export const getDiff = (counter: ICountWithTrend) => {
  return counter.count - counter.lastCount;
};

export const mergeCountWithTrend = (
  a: ICountWithTrend,
  b: ICountWithTrend
): ICountWithTrend => {
  const count = a.count + b.count;
  const lastCount = a.lastCount + b.lastCount;
  return {
    count,
    lastCount,
    trend: getTrend(lastCount, count)
  };
};

export const mergeTrendCounter = (
  a: ICounterWithTrend,
  b: ICounterWithTrend
): ICounterWithTrend => ({
  pageViews: mergeCountWithTrend(a.pageViews, b.pageViews),
  served: mergeCountWithTrend(a.served, b.served),
  viewed: mergeCountWithTrend(a.viewed, b.viewed),
  clicked: mergeCountWithTrend(a.clicked, b.clicked)
});

export const reduceCounters = (counters: ICounter[]): ICounter => {
  return counters.reduce<ICounter>(mergeCounter, EMPTY_COUNTER());
};

export const reduceTrendCounters = (
  counters: ICounterWithTrend[]
): ICounterWithTrend => {
  return counters.reduce<ICounterWithTrend>(
    mergeTrendCounter,
    EMPTY_TREND_COUNTER()
  );
};

export const aggregateDailyCounters = (counters: IDailyCounter[]): ICounter => {
  return counters.reduce<ICounter>(mergeCounter, EMPTY_COUNTER());
};

export const mergeDailyCounters = (
  a: IDailyCounter[],
  b: IDailyCounter[]
): IDailyCounter[] => {
  const bByTk: { [tk: string]: IDailyCounter } = {};
  b.forEach((x) => (bByTk[x.timeKey] = x));
  return a.map((x) => {
    const y = bByTk[x.timeKey];
    if (!y) {
      return x;
    }
    const merged = mergeCounter(x, y);
    return {
      timeKey: x.timeKey,
      ...merged
    };
  });
};

export interface IPageCounter {
  href: string;
  total: ICounterWithTrend;
  byOccurrence: { [occurrenceKey: string]: ICounterWithTrend };
}

export interface IProductCounter {
  productId: string;
  total: ICounterWithTrend;
  byOccurrence: { [occurrenceKey: string]: ICounterWithTrend };
}

export const UNKNOWN = 'unknown';
export type Unknown = typeof UNKNOWN;

export interface ICountryCounter {
  code: string | Unknown; // 2-letter country code
  total: ICounterWithTrend;
}

export const TIMEKEY_FORMAT = 'YYYYDDDD';
export const parseTimekey = (tk: string) => ({
  year: parseInt(tk.slice(0, 4), 10),
  dayOfYear: parseInt(tk.slice(4), 10)
});

export type Day = string; // YYYY-MM-DD
export const DAY_FORMAT = 'YYYY-MM-DD';

export const isDayString = (
  day: Day // YYYY-MM-DD
) => /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/.test(day);

export type Timeframe = { start: Day; end: Day; tz: string };
export const isSameTimeframe = (a: Timeframe, b: Timeframe) =>
  a.start === b.start && a.end === b.end;

export const isStrictlyEqualTimeframe = (a: Timeframe, b: Timeframe) =>
  a.start === b.start && a.end === b.end && a.tz === b.tz;

export interface IDeviceCounter {
  desktop: ICounter;
  mobile: ICounter;
  tablet: ICounter;
  unknown: ICounter;
}

export const EMPTY_DEVICE_COUNTER = (): IDeviceCounter => ({
  desktop: EMPTY_COUNTER(),
  mobile: EMPTY_COUNTER(),
  tablet: EMPTY_COUNTER(),
  unknown: EMPTY_COUNTER()
});

export interface IDeviceShortCounter {
  desktop: IShortCounter;
  mobile: IShortCounter;
  tablet: IShortCounter;
  unknown: IShortCounter;
}

export const EMPTY_DEVICE_SHORT_COUNTER = (): IDeviceShortCounter => ({
  desktop: EMPTY_SHORT_COUNTER(),
  mobile: EMPTY_SHORT_COUNTER(),
  tablet: EMPTY_SHORT_COUNTER(),
  unknown: EMPTY_SHORT_COUNTER()
});

export interface IReferrerCounter {
  href: string;
  name: string;
  total: number;
}

export type PageAnalyticsQuery = {
  tf: Timeframe;
  origin?: string[];
  url?: string[];
  groupBy?: 'url' | 'origin'; // defaults to ['url']
  asTimeseries: boolean;

  // the rest is not implemented yet - it just returns all every time
  page?: number;
  limit?: number; // defaults to unlimited
  orderBy?: {
    column: ('url' | 'origin' | 'p' | 's' | 'v' | 'c')[];
    dir?: 'DESC' | 'ASC';
  };
};

export type PageAnalyticsResponseSum = {
  time: number;
  d: [string, number, number, number, number][]; // groupBy column, p, s, v, c
};

export type PageAnalyticsResponseTimeseries = {
  time: number;
  d: [string, number, number, number, number, number][]; // groupBy column, tk, p, s, v, c
};

export type ProductAnalyticsSortColumn =
  | 'partner'
  | 'name'
  | 'pageViews'
  | 'createdAt'
  | 'served'
  | 'viewed'
  | 'clicked'
  | 'earnings'
  | 'url'
  | 'origin'
  | 'epc'
  | 'clickRatio';

export type ProductAnalyticsDirection = 'ASC' | 'DESC';

export type ProductAnalyticsQuery = {
  tf: Timeframe;
  partner_key?: string[];
  product_id?: string[];
  issue_type?: string[]; // e.g. ["LINK_CHECK"]
  groupBy?: 'product_id' | 'partner_key'; // defaults to ['product_id']
  asTimeseries: boolean;

  page?: number;
  mode?: Mode;
  q?: string;
  limit?: number;
  orderBy?: {
    column: string[];
    dir?: ProductAnalyticsDirection;
  };
};

export type ProductAnalyticsResponseSum = {
  time: number;
  d: [
    string, // groupBy column
    number, // p
    number, // s
    number, // v
    number, // c
    number, // revenue
    number, // epc
    number, // ctr
    string, // name
    string, // url
    string, // partnerKey
    IProductIssue[], // issues
    firebase.firestore.Timestamp // createdAt
  ][];
};

export type ProductAnalyticsResponseTimeseries = {
  time: number;
  d: [string, number, number, number, number, number][]; // groupBy column, tk, p, s, v, c
};

export interface IPostgresPageAnalytics {
  space_id: string;
  origin: string;
  path: string;
  tk: number;
  p: number;
  s: number;
  v: number;
  c: number;
}

export interface IPostgresProductAnalytics {
  space_id: string;
  p_id: string;
  tk: number;
  p: number;
  s: number;
  v: number;
  c: number;
}
