import { compact, padStart, uniq } from 'lodash';
import { useMemo } from 'react';
import { formatNumber } from '../../components/Number';
import {
  AnalyticsQuery,
  AnalyticsResponse,
  AnalyticsResponseRowWithComparison,
  SelectableFieldComplete
} from '../../domainTypes/analytics_v2';
import { usePromise } from '../../hooks/usePromise';
import { toChecksum } from '../checksum';
import { callFirebaseFunction } from '../firebaseFunctions';
import { getCache, useAnalyticsV2CacheRevision } from './cache';

const isExpired = (n: number, expiresAt: number | null) =>
  expiresAt !== null && n > expiresAt;

const logQuery = (
  r: AnalyticsResponse,
  start: number,
  cached: boolean,
  logMode: 'full' | 'compact',
  label?: string
) => {
  const totalTime = Date.now() - start;
  const dbTime = r.time;
  const networkOverhead = totalTime - dbTime;
  if (logMode === 'full') {
    const rs = compact([
      [
        'Total time',
        `${formatNumber({
          n: totalTime / 1000,
          digits: 3
        })}s`
      ],
      !cached && [
        'DB time',
        `${formatNumber({
          n: dbTime / 1000,
          digits: 3
        })}s`
      ],
      !cached && [
        'Network Overhead',
        `${formatNumber({
          n: networkOverhead / 1000,
          digits: 3
        })}s`
      ],
      ['Rows', `${r.rows.length}`]
    ]);
    const maxLen =
      Math.max(...rs.map((r) => r[0].length + r[1].length + 2)) || 0;
    const rows = rs.map((r) => {
      return `${r[0]}: ${padStart(r[1], maxLen - r[0].length, ' ')}`;
    });
    console.log(
      `${compact(['Analytics V2 Query', label, cached && 'CACHED']).join(
        ' - '
      )}\n\n${rows.map((r) => `  ${r}`).join('\n')}\n\n`,
      r
    );
  }
  if (logMode === 'compact') {
    if (!cached) {
      console.log(
        compact([
          'AV2Q',
          label,
          `${formatNumber({
            n: totalTime / 1000,
            digits: 3
          })}s (${formatNumber({ n: dbTime / 1000, digits: 3 })}s)`
        ]).join(' - ')
      );
    }
  }
};

export type AnalyticsOpts = Partial<{
  noCache: boolean;
  cacheExpirationDuration: number; // in ms
  logMode: 'full' | 'compact' | 'off';
  logLabel: string;
}>;

export const queryAnalyticsV2 = <T = AnalyticsResponse>(
  spaceId: string,
  q: AnalyticsQuery,
  opts: AnalyticsOpts = {}
) => {
  const logMode =
    opts.logMode ||
    (window?.location?.hostname === 'localhost' ? 'compact' : 'off');
  const n = Date.now();
  const request = () =>
    callFirebaseFunction<AnalyticsResponse>('analytics_v2-query', {
      spaceId,
      q
    });

  const getRequestCached = () => {
    if (opts.noCache) {
      return {
        cached: false,
        promise: (request() as unknown) as Promise<T>
      };
    }
    const cache = getCache(spaceId);
    const checksum = toChecksum({ spaceId, q });
    let cached = false;
    if (!cache[checksum] || isExpired(n, cache[checksum].expiresAt)) {
      cache[checksum] = {
        expiresAt: opts.cacheExpirationDuration
          ? n + opts.cacheExpirationDuration
          : null,
        promise: request()
      };
    } else {
      cached = true;
    }
    return {
      promise: (cache[checksum].promise as unknown) as Promise<T>,
      cached
    };
  };

  const req = getRequestCached();
  if (logMode !== 'off') {
    req.promise.then((res) => {
      logQuery(res as any, n, req.cached, logMode, opts.logLabel);
      return res;
    });
  }
  return req.promise;
};

export const useAnalyticsQueryV2 = (
  spaceId: string,
  q: AnalyticsQuery,
  opts?: AnalyticsOpts
) => {
  // A bit of a weird dance - but with this we avoid that the caller needs to memoize the options
  const noCache = opts?.noCache ?? false;
  const cacheExpirationDuration = opts?.cacheExpirationDuration ?? 0;
  const logMode = opts?.logMode ?? 'off';
  const logLabel = opts?.logLabel ?? '';
  const optsWithDefaults: AnalyticsOpts = useMemo(
    () => ({
      noCache,
      cacheExpirationDuration,
      logMode,
      logLabel
    }),

    [noCache, cacheExpirationDuration, logMode, logLabel]
  );
  const revision = useAnalyticsV2CacheRevision(spaceId);
  return usePromise(() => queryAnalyticsV2(spaceId, q, optsWithDefaults), [
    spaceId,
    q,
    optsWithDefaults,
    revision
  ]);
};

export const mergeAnalyticsRowsData = (
  rows: AnalyticsResponseRowWithComparison['data'][]
) => {
  const next: AnalyticsResponseRowWithComparison['data'] = {};
  rows.forEach((row) => {
    Object.entries(row).forEach(([key, v]) => {
      if (!v) {
        return;
      }
      const k = key as SelectableFieldComplete;
      const n: any = next;
      if (k.startsWith('agg_')) {
        const arrContainer = (n[k] = n[k] || { curr: [] });
        arrContainer.curr.push(...(v as any).curr);
        if (v.prev !== undefined) {
          const prevContainer = (arrContainer.prev = arrContainer.prev || []);
          prevContainer.push(...(v as any).prev);
        }
      } else {
        const nContainer = (n[k] = n[k] || { curr: 0 });
        nContainer.curr += (v as any).curr;
        if (v.prev !== undefined) {
          nContainer.prev = (nContainer.prev || 0) + (v as any).prev;
        }
      }
    });
  });

  Object.entries(next).forEach(([k, v]) => {
    if (k.startsWith('agg_') && v) {
      v.curr = uniq((v as any).curr);
      if (v.prev !== undefined) {
        v.prev = uniq((v as any).prev);
      }
    }
  });

  return next;
};
