import { memoize } from 'lodash';
import { useCallback } from 'react';
import { Timeframe } from '../../domainTypes/analytics';
import { getStableRandomColor } from '../color';
import { useCurrentUser } from '../currentUser';
import {
  useDenormalizedCountryclickCountsForPageInTimeframe,
  useDenormalizedCountryClickCountsForProductsInTimeframe,
  useDenormalizedCountryClickCountsInTimeframe,
  usePartnerFilter
} from './denormalization';

const EMPTY_ARR = () => [];

export const useCountryClickCountsInTimeframe = (
  timeframe: Timeframe,
  compare: boolean
) => {
  const { space } = useCurrentUser();
  return useDenormalizedCountryClickCountsInTimeframe(
    space.id,
    timeframe,
    compare
  );
};

export const useCountryClickCountsForPageInTimeframeFs = (
  href: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const { space } = useCurrentUser();
  return useDenormalizedCountryclickCountsForPageInTimeframe(
    space.id,
    href,
    timeframe,
    compare
  );
};

export const useCountryClickCountsForProductInTimeframeFs = (
  productId: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const { space } = useCurrentUser();
  const filter = useCallback(() => [productId], [productId]);
  return useDenormalizedCountryClickCountsForProductsInTimeframe(
    space.id,
    filter,
    timeframe,
    compare
  );
};

export const useCountryClickCountsForPartnerInTimeframe = (
  partnerKey: string,
  timeframe: Timeframe,
  compare: boolean
) => {
  const { space } = useCurrentUser();
  const [filterProductIds, loadingPs, errorPs] = usePartnerFilter(
    space,
    partnerKey
  );
  const [
    result,
    loading,
    error
  ] = useDenormalizedCountryClickCountsForProductsInTimeframe(
    space.id,
    filterProductIds || EMPTY_ARR,
    timeframe,
    compare
  );

  return [result, loading || loadingPs, error || errorPs] as [
    typeof result,
    boolean,
    any
  ];
};

export const getCountryColor = memoize((countryCode: string) => {
  return getStableRandomColor(countryCode);
});
