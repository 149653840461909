import { Paper } from '@material-ui/core';
import React from 'react';
import { styled } from '../../emotion';
import { TooltipPaper } from '../Charts/CustomTooltip';

type Props = {
  variant?: 'normal' | 'chart';
};

const Wrapper = styled('div')`
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: ${(p) => p.theme.spacing()}px;
  z-index: 5;
`;

export const Popover: React.FC<Props> = ({ children, variant = 'normal' }) => {
  return (
    <Wrapper>
      {variant === 'normal' && <Paper elevation={10}>{children}</Paper>}
      {variant === 'chart' && <TooltipPaper>{children}</TooltipPaper>}
    </Wrapper>
  );
};
