import { Group } from './referrers';
import { Device } from './tracking';

export type FiltersDefinition = Array<FilterDefinition>;

interface UtmCampaignFilterDef {
  k: 'utm_campaign';
  v: Array<string>;
}

interface UtmMediumFilterDef {
  k: 'utm_medium';
  v: Array<string>;
}

interface UtmSourceFilterDef {
  k: 'utm_source';
  v: Array<string>;
}

interface UtmTermFilterDef {
  k: 'utm_term';
  v: Array<string>;
}

interface UtmContentFilterDef {
  k: 'utm_content';
  v: Array<string>;
}

export type ReferrerFilterValue =
  | {
      mode: 'group';
      v: Group | null;
    }
  | {
      mode: 'domains';
      v: Array<string>;
    };

export interface ReferrerFilterDef {
  k: 'referrer';
  v: ReferrerFilterValue;
}

export interface TagFilterDef {
  k: 'tag';
  v: Array<string>;
}

export interface SiteFilterDef {
  k: 'site';
  v: Array<string>;
}

export interface PlatformFilterDef {
  k: 'platform';
  v: Array<string>;
}

export interface DeviceFilterDef {
  k: 'device';
  v: Array<Device>;
}

export interface CountryFilterDef {
  k: 'country';
  v: Array<string>;
}

export interface ClickData01FilterDef {
  k: 'click_data_01';
  v: Array<string>;
}

export type UtmFilterDefinition =
  | UtmCampaignFilterDef
  | UtmMediumFilterDef
  | UtmSourceFilterDef
  | UtmTermFilterDef
  | UtmContentFilterDef;

export type ClickDataFilterDefinition = ClickData01FilterDef;

export type ClickDataSlot = ClickDataFilterDefinition['k'];

export type FilterDefinition =
  | UtmCampaignFilterDef
  | UtmMediumFilterDef
  | UtmSourceFilterDef
  | UtmTermFilterDef
  | UtmContentFilterDef
  | ClickData01FilterDef
  | ReferrerFilterDef
  | TagFilterDef
  | SiteFilterDef
  | DeviceFilterDef
  | CountryFilterDef
  | PlatformFilterDef;

export const readFilters = (str: string): FiltersDefinition =>
  JSON.parse(decodeURIComponent(str)) as FiltersDefinition;

export const printFilters = (filters: FiltersDefinition) =>
  encodeURIComponent(JSON.stringify(filters));
