import {
  capitalize,
  compact,
  keyBy,
  partition,
  snakeCase,
  sortBy,
  uniq
} from 'lodash';
import {
  EMPTY_COUNT_WITH_TREND,
  getTrend,
  ICountWithTrend,
  IDailyCounter,
  mergeCountWithTrend,
  ProductAnalyticsQuery,
  ProductAnalyticsResponseTimeseries,
  Timeframe
} from '../../domainTypes/analytics';
import { Doc } from '../../domainTypes/document';
import { IPartner, PARTNERS } from '../../domainTypes/partners';
import {
  IProduct,
  IProductWithCountsAndTrends
} from '../../domainTypes/product';
import { PARTNERS_WITH_TRACKING } from '../../domainTypes/tracking';
import { usePromise } from '../../hooks/usePromise';
import { padCountsForTimeframe, toComparableTimeframe } from '../analytics';
import { toCounterWithTrend } from '../analytics/denormalization';
import { COLOR_UNKNOWN } from '../color';
import { LoadingValue, LoadingValueLike, useMappedLoadingValue } from '../db';
import { callFirebaseFunction } from '../firebaseFunctions';
import {
  getDestinationUrlForProduct,
  getPartnersWithProductCounts,
  getProductAnalytics,
  getProductsBySpaceId,
  useProductsBySpaceId,
  useProductsWithCountsAndTrendsInTimeframeFs
} from '../products';

export type PartnerListItem = {
  partner: IPartner;
  counts: {
    links: number;
  };
};

export type PartnerListItemWithDailyCounters = {
  partner: IPartner;
  products: number;
  dailyCounters: IDailyCounter[];
};

export type PartnerListItemWithCountsAndTrends = {
  partner: IPartner;
  counts: {
    products: number;
    served: ICountWithTrend;
    viewed: ICountWithTrend;
    clicked: ICountWithTrend;
  };
};

export type PartnerListItemWithCountsAndTrendsAndSales = {
  sales: {
    earnings: ICountWithTrend;
    saleValue: ICountWithTrend;
    orderCount: ICountWithTrend;
    currency: string;
    earningsPerClick: ICountWithTrend;
    conversionRate: number;
    avgCommissionPercent: number;
  };
} & PartnerListItemWithCountsAndTrends;

export type PartnerList = {
  [domain: string]: number;
};

const shortenPartnerUrl = (url: string) => {
  const starters = ['https://www.', 'http://www.', 'https://', 'http://'];
  const toStripIndex = starters.findIndex((s) => url.indexOf(s) === 0);
  if (toStripIndex === -1) {
    return url;
  }
  const toStrip = starters[toStripIndex];
  return url.substr(toStrip.length, url.length);
};

const getPartnerUrlFromUrl = (url: string): string => {
  try {
    const { origin } = new URL(url);
    return origin;
  } catch (err) {
    return url;
  }
};

const constructPartnerForUrl = (url: string): IPartner => {
  const partnerUrl = getPartnerUrlFromUrl(url);
  const shortened = shortenPartnerUrl(partnerUrl);

  return {
    key: snakeCase(shortened),
    name: capitalize(shortened),
    matches: (url) => url.indexOf(shortened) !== -1,
    known: false,
    portalUrl: partnerUrl,
    color: COLOR_UNKNOWN
  };
};

export const constructPartnerForKey = (key: string): IPartner => {
  return {
    key,
    name: key.replace('_', '.'),
    matches: () => false,
    known: false,
    portalUrl: '',
    color: COLOR_UNKNOWN
  };
};

export const getPartnerForUrl = (url: string): IPartner => {
  return PARTNERS.find((p) => p.matches(url)) || constructPartnerForUrl(url);
};

export const getParnterKeyForUrl = (url: string): string => {
  const known = PARTNERS.find((p) => p.matches(url));
  if (known) {
    return known.key;
  }
  const partnerUrl = getPartnerUrlFromUrl(url);
  const shortened = shortenPartnerUrl(partnerUrl);
  return snakeCase(shortened);
};

export const getPartnersForProductInTimeSpan = (
  p: IProduct,
  start: number,
  end: number
) => {
  //         5          10
  //  a            b            c  | a b
  //  a   b                     c  | b
  //  a                            | a
  //  a                         c  | a
  const matches = p.destinations.filter((d) => d.foundAt.toMillis() <= end);
  const [before, within] = partition(
    matches,
    (d) => d.foundAt.toMillis() <= start
  );
  const destinations = compact([before[before.length - 1], ...within]);
  return destinations.map((d) => getPartnerForUrl(d.url));
};

export const getPartnerKeyForProductAtPointInTime = (
  product: IProduct,
  ts: number
) => {
  //                  5
  // a        b            c        d
  for (let i = product.destinations.length - 1; i >= 0; i--) {
    const d = product.destinations[i];
    if (d.foundAt.toMillis() < ts) {
      return getParnterKeyForUrl(d.url);
    }
  }
  return null;
};

export const getKnownPartnerForKey = (partnerKey: string) =>
  PARTNERS.find((p) => p.key === partnerKey) || null;

export const getKnownPartnerForKeyUnsafe = (partnerKey: string) => {
  const p = getKnownPartnerForKey(partnerKey);
  if (!p) {
    // this should never happen
    throw new Error(`UNKNOWN _PARTNER - ${partnerKey}`);
  }
  return p;
};

export const getCurrentPartnerForProduct = (product: IProduct): IPartner => {
  if (product.partnerKey) {
    return (
      getKnownPartnerForKey(product.partnerKey) ||
      constructPartnerForKey(product.partnerKey)
    );
  }
  return getPartnerForUrl(getDestinationUrlForProduct(product));
};

export const getPartnerForKey = async (
  spaceId: string,
  partnerKey: string
): Promise<IPartner | null> => {
  const partner = getKnownPartnerForKey(partnerKey);
  if (partner) {
    return partner;
  }
  return getProductsBySpaceId(spaceId).then((ps) => {
    const partners = uniq(ps.map((p) => getCurrentPartnerForProduct(p.data)));
    return partners.find((p) => p.key === partnerKey) || null;
  });
};

export const aggregatePartnerList = (docs: Doc<IProduct>[]) => {
  const partners = docs.reduce<{
    [key: string]: PartnerListItem;
  }>((m, p) => {
    const partner = getCurrentPartnerForProduct(p.data);
    const item = (m[partner.name] = m[partner.name] || {
      partner,
      counts: { links: 0 }
    });
    item.counts.links++;
    return m;
  }, {});

  return Object.values(partners).sort(
    (a, b) => b.counts.links - a.counts.links
  );
};

export const aggregatePartnerListFromKeys = (partnerKeys: string[]) => {
  const partners = partnerKeys.reduce<{
    [key: string]: PartnerListItem;
  }>((m, key) => {
    const partner = getKnownPartnerForKey(key) || constructPartnerForKey(key);
    const item = (m[partner.name] = m[partner.name] || {
      partner,
      counts: { links: 0 }
    });
    item.counts.links++;
    return m;
  }, {});

  return Object.values(partners).sort(
    (a, b) => b.counts.links - a.counts.links
  );
};

export const usePartnerListFs = (spaceId: string) => {
  return useMappedLoadingValue(
    useProductsBySpaceId(spaceId),
    aggregatePartnerList
  );
};

export const usePartnerListPg = (spaceId: string) => {
  return useMappedLoadingValue(
    usePromise(() => getPartnersWithProductCounts(spaceId), [spaceId]),
    (ds) => aggregatePartnerListFromKeys(ds.map((d) => d.partner_key))
  );
};

export const usePartnerListWithCountsAndTrends = (
  timeframe: Timeframe,
  compare: boolean
) => {
  return useMappedLoadingValue(
    useProductsWithCountsAndTrendsInTimeframeFs(timeframe, compare),
    aggregatePartnerListWithCountsAndTrends
  );
};

const toEmptyPartnerListItem = (
  partner: IPartner
): PartnerListItemWithCountsAndTrends => ({
  partner,
  counts: {
    products: 0,
    served: EMPTY_COUNT_WITH_TREND(),
    viewed: EMPTY_COUNT_WITH_TREND(),
    clicked: EMPTY_COUNT_WITH_TREND()
  }
});

export const aggregatePartnerListWithCountsAndTrends = (
  docs: Doc<IProductWithCountsAndTrends>[]
): PartnerListItemWithCountsAndTrends[] => {
  const partners = docs.reduce<{
    [key: string]: PartnerListItemWithCountsAndTrends;
  }>((m, p) => {
    const partner = getCurrentPartnerForProduct(p.data);
    const item = (m[partner.name] =
      m[partner.name] || toEmptyPartnerListItem(partner));
    const { counts } = item;
    const { counts: pCounts } = p.data;
    counts.products++;
    counts.served = mergeCountWithTrend(counts.served, pCounts.served);
    counts.viewed = mergeCountWithTrend(counts.viewed, pCounts.viewed);
    counts.clicked = mergeCountWithTrend(counts.clicked, pCounts.clicked);
    return m;
  }, {});

  return Object.values(partners).sort(
    (a, b) => b.counts.products - a.counts.products
  );
};

export const getAutoLabellingPartners = () =>
  PARTNERS.filter((p) => !!PARTNERS_WITH_TRACKING[p.key]);

export const fromDailyCountersToTrend = (
  ps: PartnerListItemWithDailyCounters[],
  compare: boolean
): PartnerListItemWithCountsAndTrends[] => {
  return ps.map((p) => {
    const trendCounter = toCounterWithTrend(p.dailyCounters, compare);

    return {
      partner: p.partner,
      counts: {
        products: p.products,
        served: trendCounter.served,
        viewed: trendCounter.viewed,
        clicked: trendCounter.clicked
      }
    };
  });
};

export const usePartnersWithDailyCountersPg = (
  spaceId: string,
  tf: Timeframe
): LoadingValue<PartnerListItemWithDailyCounters[]> => {
  return useMappedLoadingValue(
    usePromise(() => {
      const q: ProductAnalyticsQuery = {
        tf,
        groupBy: 'partner_key',
        asTimeseries: true
      };
      return callFirebaseFunction<
        ProductAnalyticsResponseTimeseries,
        { spaceId: string; q: ProductAnalyticsQuery }
      >('analytics-getProductAnalytics', {
        spaceId,
        q
      });
    }, [spaceId, tf.start, tf.end, tf.tz]),
    (r) => {
      const res: {
        [partnerKey: string]: PartnerListItemWithDailyCounters;
      } = {};
      r.d.forEach(([partnerKey, tk, productCount, s, v, c]) => {
        const container: PartnerListItemWithDailyCounters = (res[
          partnerKey
        ] = res[partnerKey] || {
          partner:
            getKnownPartnerForKey(partnerKey) ||
            constructPartnerForKey(partnerKey),
          products: 0,
          dailyCounters: []
        });
        container.products = Math.max(container.products, productCount);
        container.dailyCounters.push({
          timeKey: `${tk}`,
          pageViews: 0,
          served: s,
          viewed: v,
          clicked: c
        });
      });

      const values = Object.values(res);
      values.forEach((x) => {
        // just mutate for speed
        x.dailyCounters = padCountsForTimeframe(
          sortBy(x.dailyCounters, (r) => r.timeKey),
          tf
        );
      });
      return values; // sort maybe?
    }
  );
};

export const usePartnersWithCountsAndTrendsPg = (
  spaceId: string,
  timeframe: Timeframe,
  compare: boolean
): LoadingValueLike<PartnerListItemWithCountsAndTrends[]> => {
  return usePromise(async () => {
    const [curr, prev = []] = await Promise.all([
      getProductAnalytics(spaceId, {
        groupBy: 'partner_key',
        tf: timeframe
      }).then((x) => x.d),
      compare
        ? getProductAnalytics(spaceId, {
            groupBy: 'partner_key',
            tf: toComparableTimeframe(timeframe)
          }).then((x) => x.d)
        : undefined
    ]);

    const allPartnerKeys = uniq([
      ...curr.map((x) => x[0]),
      ...prev.map((x) => x[0])
    ]);
    const currByKey = keyBy(curr, (x) => x[0]);
    const prevByKey = keyBy(prev, (x) => x[0]);
    return compact(
      allPartnerKeys.map<PartnerListItemWithCountsAndTrends | null>((pk) => {
        const partner = getKnownPartnerForKey(pk);
        if (!partner) {
          return null;
        }
        const c = currByKey[pk] || [pk, 0, 0, 0, 0];
        const p = prevByKey[pk] || [pk, 0, 0, 0, 0];
        return {
          partner,
          counts: {
            products: c[1],
            served: {
              count: c[2],
              lastCount: p[2],
              trend: getTrend(p[2], c[2])
            },
            viewed: {
              count: c[3],
              lastCount: p[3],
              trend: getTrend(p[3], c[3])
            },
            clicked: {
              count: c[4],
              lastCount: p[4],
              trend: getTrend(p[4], c[4])
            }
          }
        };
      })
    );
  }, [spaceId, timeframe.start, timeframe.end, timeframe.tz, compare]);
};
