import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { isEqual, values } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { Plus, X } from 'react-feather';
import { AutocompleteSingleFreeSolo } from '../../../../components/Autocomplete';
import {
  Condition,
  Conditional,
  isRule,
  MatchValue,
  Operator,
  Rule
} from '../../../../domainTypes/rules';
import { styled } from '../../../../emotion';
import { useHover } from '../../../../hooks/useHover';
import { getKnownPartnerForKeyUnsafe } from '../../../../services/partner';

export type RuleDataType = 'boolean' | 'string' | 'number' | 'date';

export const OPERATORS_BY_DATA_TYPE: {
  [K in RuleDataType]: Operator[];
} = {
  boolean: ['==', '!='],
  string: ['==', '!=', '=~', '!~', 'contains', 'not-contains'], // ['<', '<=', '>', '>=', '==', '!=', '=~', '!~'],
  number: ['<', '<=', '>', '>=', '==', '!='],
  date: ['<', '<=', '>', '>=', '==', '!=']
};

export const OPERATORS_TO_STRING: {
  [K in RuleDataType]: {
    [KK in Operator]: string;
  };
} = {
  boolean: {
    '<': '',
    '<=': 'is',
    '>': '',
    '>=': '',
    '==': 'is not',
    '!=': '',
    '=~': '',
    '!~': '',
    contains: '',
    'not-contains': ''
  },
  string: {
    '<': 'is less than',
    '<=': 'is less than or equal to',
    '>': 'is greater than',
    '>=': 'is greater than or equal to',
    '==': 'is',
    '!=': 'is not',
    contains: 'contains',
    'not-contains': 'does not contain',
    '=~': 'matches (regex)',
    '!~': 'does not match (regex)'
  },
  number: {
    '<': 'is less than',
    '<=': 'is less than or equal to',
    '>': 'is greater than',
    '>=': 'is greater than or equal to',
    '==': 'is',
    '!=': 'is not',
    '=~': '',
    '!~': '',
    contains: '',
    'not-contains': ''
  },
  date: {
    '<': 'is before',
    '<=': 'is before or equal to',
    '>': 'is after',
    '>=': 'is after or equal to',
    '==': 'is',
    '!=': 'is not',
    '=~': '',
    '!~': '',
    contains: '',
    'not-contains': ''
  }
};

export const DEFAULT_VALUE_BY_DATA_TYPE: {
  [K in RuleDataType]: MatchValue;
} = {
  boolean: true,
  string: '',
  number: 0,
  date: Date.now()
};

export const LEGAL_VALUE_TYPES_BY_DATA_TYPE: {
  [K in RuleDataType]: Set<string>;
} = {
  boolean: new Set(['boolean']),
  string: new Set(['string']),
  number: new Set(['number']),
  date: new Set(['number'])
};

export type RuleBuilderConfigItemOptions = {
  // only used with string data type
  autocomplete?: {
    renderOption?: (v: string) => string;
    renderInputLabel?: (v: string) => string;
    getOptionSelected?: (o: string, v: string) => boolean;
    getOptionLabel?: (v: string) => string;
    getItems: () => Promise<string[]>;
  };

  // select -> for string
  // min max -> for number and date
};

export type RuleBuilderConfigItem<T extends string> = {
  key: T;
  label: string;
  dataType: 'boolean' | 'string' | 'number' | 'date';

  options?: RuleBuilderConfigItemOptions;
};

export type RuleBuilderConfig<T extends string> = {
  [K in T]: RuleBuilderConfigItem<T>;
};

export type Props<T extends string> = {
  value: Rule<T> | null;
  onChange: (nextvalue: Rule<T> | null) => void;
  config: RuleBuilderConfig<T>;
  variant?: 'compact' | 'normal';
};

const ElementsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing()}px;
`;

const Actions = styled('div')`
  display: flex;
  gap: ${(p) => p.theme.spacing(3)}px;
`;

const GroupContainer = styled('div')<{ hovered: boolean; root: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.theme.spacing()}px;
  padding: ${(p) => p.theme.spacing(2)}px;
  border-radius: ${(p) => p.theme.custom.border.radius()}px;
  background: #f5faff;
  ${(p) => (p.root ? '' : `margin-right: -${p.theme.spacing(2)}px;`)}
  width: 100%;

  &:hover {
    background: ${(p) => (p.hovered ? '#EBF5FF' : 'inherit')};
  }
`;

const GroupHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const getEmptyDefaultCondition = <T extends string>(
  config: RuleBuilderConfig<T>
): Condition<T> => {
  const c = values(config)[0] as RuleBuilderConfigItem<T>;
  const { dataType, key } = c;
  return {
    k: key,
    op: '==',
    v: DEFAULT_VALUE_BY_DATA_TYPE[dataType]
  };
};

export const AddButton = ({
  label,
  startIcon,
  onClick
}: {
  label: React.ReactNode;
  startIcon: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <Button
      color="primary"
      size="small"
      startIcon={startIcon}
      onClick={() => onClick()}
    >
      {label}
    </Button>
  );
};

const Group = <T extends string>({
  value,
  onChange,
  config,
  onInnerHover,
  root,
  variant
}: {
  value: Rule<T> | null;
  onChange: (nextvalue: Rule<T> | null) => void;
  config: RuleBuilderConfig<T>;
  onInnerHover?: (s: boolean) => void;
  root?: boolean;
  variant: 'compact' | 'normal';
}) => {
  const ref = useRef(null);
  const hovered = useHover(ref);
  const [innerHovered, setInnerHovered] = useState(false);

  useEffect(() => {
    if (!onInnerHover) {
      return;
    }
    onInnerHover(hovered);
  }, [onInnerHover, hovered]);

  if (value === null) {
    return null;
  }

  return (
    <GroupContainer ref={ref} hovered={hovered && !innerHovered} root={!!root}>
      <GroupHeader>
        {!root && (
          <IconButton onClick={() => onChange(null)}>
            <X size={16} />
          </IconButton>
        )}
      </GroupHeader>

      <ElementsContainer>
        {value.els.map((el, i) => {
          if (isRule(el)) {
            return (
              <Group
                key={i}
                value={el}
                onInnerHover={setInnerHovered}
                onChange={(nextEl) => {
                  const nextEls = [...value.els];
                  if (nextEl === null) {
                    nextEls.splice(i, 1);
                  } else {
                    nextEls[i] = nextEl;
                  }
                  onChange({
                    ...value,
                    els: nextEls
                  });
                }}
                config={config}
                variant={variant}
              />
            );
          } else {
            return (
              <Cond
                key={i}
                value={el}
                onChange={(nextEl) => {
                  const nextEls = [...value.els];
                  if (nextEl === null) {
                    nextEls.splice(i, 1);
                  } else {
                    nextEls[i] = nextEl;
                  }
                  onChange({
                    ...value,
                    els: nextEls
                  });
                }}
                config={config}
                variant={variant}
              />
            );
          }
        })}
        <Actions>
          {root && value.els.length === 0 && (
            <AddButton
              label="Add rule"
              startIcon={<Plus size={18} />}
              onClick={() => {
                onChange({
                  ...value,
                  els: [...value.els, getEmptyDefaultCondition(config)]
                });
              }}
            />
          )}
        </Actions>
      </ElementsContainer>
    </GroupContainer>
  );
};

const CondTypeSelector = <T extends string>({
  value,
  onChange,
  config
}: {
  value: T;
  onChange: (nextValue: T) => void;
  config: RuleBuilderConfig<T>;
}) => {
  const opts: RuleBuilderConfigItem<T>[] = values(config);
  return (
    <Select
      value={value}
      onChange={(ev) => onChange(ev.target.value as T)}
      variant="outlined"
    >
      {opts.map((c) => (
        <MenuItem key={c.key} value={c.key}>
          {c.label}
        </MenuItem>
      ))}
    </Select>
  );
};

const OperatorSelector = ({
  value,
  onChange,
  operators,
  type
}: {
  value: Operator;
  onChange: (nextValue: Operator) => void;
  operators: Operator[];
  type: RuleDataType;
}) => {
  return (
    <Select
      variant="outlined"
      value={value}
      onChange={(ev) => onChange(ev.target.value as Operator)}
    >
      {operators.map((op) => (
        <MenuItem key={op} value={op}>
          {OPERATORS_TO_STRING[type][op]}
        </MenuItem>
      ))}
    </Select>
  );
};

const CondValue = ({
  value,
  type,
  onChange,
  options
}: {
  value: MatchValue;
  type: RuleDataType;
  onChange: (nextValue: MatchValue) => void;
  options?: RuleBuilderConfigItemOptions;
}) => {
  if (type === 'boolean') {
    return (
      <Select
        value={value ? 'true' : 'false'}
        onChange={(ev) => onChange(ev.target.value === 'true')}
        variant="outlined"
      >
        <MenuItem value={'true'}>true</MenuItem>
        <MenuItem value={'false'}>false</MenuItem>
      </Select>
    );
  }

  if (type === 'string') {
    if (options?.autocomplete) {
      return (
        <AutocompleteSingleFreeSolo
          fullWidth
          value={value as string}
          onChange={onChange}
          options={options.autocomplete.getItems}
          renderOption={options.autocomplete.renderOption}
          renderInputLabel={options.autocomplete.renderInputLabel}
          getOptionSelected={(o, v) =>
            options.autocomplete?.renderOption
              ? options.autocomplete.renderOption(v) === o
              : v === o
          }
          groupBy={() => 'Suggestions'}
          inputProps={{ style: { height: 'auto' } }}
          autoFocus={!value}
        />
      );
    }
    return (
      <TextField
        value={value}
        onChange={(ev) => onChange(ev.target.value)}
        inputProps={{ style: { height: 'auto' } }}
        variant="outlined"
        autoFocus={!value}
      />
    );
  }
  if (type === 'number') {
    return (
      <TextField
        type="number"
        value={value}
        onChange={(ev) => onChange(parseInt(ev.target.value, 10) || 0)}
        inputProps={{ style: { height: 'auto' } }}
        variant="outlined"
        autoFocus={!value}
      />
    );
  }

  if (type === 'date') {
    return (
      <DatePicker
        variant="inline"
        inputVariant="outlined"
        value={value as number}
        clearable={false}
        onChange={(d) => {
          d && onChange(d.valueOf()); // TODO tz handling
        }}
        format="YYYY/MM/DD"
      />
    );
  }

  return null;
};

const CondRowNormal = styled('div')`
  display: grid;
  align-items: center;
  grid-template-columns: 0.7fr 0.5fr 1fr min-content;
  grid-gap: ${(p) => p.theme.spacing(2)}px;
`;

const CondRowCompact = styled('div')`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr min-content;
  grid-gap: ${(p) => p.theme.spacing(2)}px;
`;

const CondRowCompactInner = styled('div')`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 0.5fr;
  grid-gap: ${(p) => p.theme.spacing(2)}px;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
`;

const Cond = <T extends string>({
  value,
  onChange,
  config,
  variant
}: {
  value: Condition<T>;
  onChange: (nextValue: Condition<T> | null) => void;
  config: RuleBuilderConfig<T>;
  variant: 'compact' | 'normal';
}) => {
  const { k, v, op } = value;
  const c = config[k];

  const condTypeSelector = (
    <CondTypeSelector
      value={k}
      config={config}
      onChange={(nextK) => {
        const c = config[nextK];
        const nextV = LEGAL_VALUE_TYPES_BY_DATA_TYPE[c.dataType].has(typeof v)
          ? v
          : DEFAULT_VALUE_BY_DATA_TYPE[c.dataType];
        const nextOp =
          OPERATORS_BY_DATA_TYPE[c.dataType].indexOf(op) !== -1 ? op : '==';
        // also need to check whether we're dealing with an illegal operator
        // or an illegal value
        return onChange({
          k: nextK,
          v: nextV,
          op: nextOp
        });
      }}
    />
  );
  const opSelector = (
    <OperatorSelector
      value={op}
      onChange={(nextOp) => {
        onChange({
          ...value,
          op: nextOp
        });
      }}
      type={c.dataType}
      operators={OPERATORS_BY_DATA_TYPE[c.dataType]}
    />
  );
  const condValue = (
    <CondValue
      value={v}
      key={k}
      onChange={(nextV) =>
        onChange({
          ...value,
          v: nextV
        })
      }
      type={c.dataType}
      options={c.options}
    />
  );
  const removeButton = (
    <IconButton onClick={() => onChange(null)}>
      <X size={16} />
    </IconButton>
  );
  if (variant === 'normal') {
    return (
      <CondRowNormal>
        {condTypeSelector}
        {opSelector}
        {condValue}
        {removeButton}
      </CondRowNormal>
    );
  }
  return (
    <CondRowCompact>
      <div>
        <CondRowCompactInner>
          {condTypeSelector}
          {opSelector}
        </CondRowCompactInner>
        {condValue}
      </div>
      {removeButton}
    </CondRowCompact>
  );
};

export const EMPTY_RULE_GROUP: Rule<any> = {
  type: 'all',
  els: []
};

export const EMPTY_RULE_GROUP_WITH_ONE_DEFUALT_CONDITION = <T extends string>(
  config: RuleBuilderConfig<T>
) => {
  return { ...EMPTY_RULE_GROUP, els: [getEmptyDefaultCondition(config)] };
};

export const isEmptyRule = (r: Rule<any>) => isEqual(r, EMPTY_RULE_GROUP);

export const RuleBuilder = <T extends string>({
  value,
  onChange,
  config,
  nullable,
  variant = 'normal'
}: Props<T> & { nullable?: boolean }) => {
  return (
    <Group
      value={value || { ...EMPTY_RULE_GROUP }}
      config={config}
      onChange={(nextRule) =>
        onChange(
          nullable && nextRule && isEmptyRule(nextRule) ? null : nextRule
        )
      }
      root
      variant={variant}
    />
  );
};

type FormattedRuleElement = {
  type: Conditional;
  els: (string | FormattedRuleElement)[];
};

export const formatValue = <T extends string>(
  k: T,
  v: MatchValue,
  conf: RuleBuilderConfig<T>
): string => {
  const { dataType } = conf[k];
  if (dataType === 'date') {
    return new Date(parseInt(v as any, 10)).toLocaleDateString();
  }

  if (k === 'partnerKey') {
    try {
      const partner = getKnownPartnerForKeyUnsafe(v as string);
      return partner ? partner.name : 'INVALID';
    } catch (err) {
      return 'INVALID';
    }
  }

  return `${v}`;
};

const formatEl = <T extends string>(
  el: Condition<T>,
  conf: RuleBuilderConfig<T>
): string => {
  const { k, v, op } = el;
  const { label, dataType } = conf[k];
  return `${label} ${OPERATORS_TO_STRING[dataType][op]} ${formatValue(
    k,
    v,
    conf
  )}`;
};

const formatRule = <T extends string>(
  rule: Rule<T>,
  conf: RuleBuilderConfig<T>
): FormattedRuleElement => {
  return {
    type: rule.type,
    els: rule.els.map((el) =>
      isRule(el) ? formatRule(el, conf) : formatEl(el, conf)
    )
  };
};

const renderOneliner = (v: FormattedRuleElement) => {
  const els: React.ReactNode[] = [];
  const addEls = (el: FormattedRuleElement, parenthesis: boolean) => {
    if (parenthesis) {
      els.push(<span>(</span>);
    }
    el.els.forEach((x, i) => {
      if (typeof x === 'string') {
        els.push(<span>{x}</span>);
      }
      if (i !== el.els.length - 1) {
        els.push(<strong>{el.type === 'all' ? ' and ' : ' or '}</strong>);
      }
      if (typeof x !== 'string') {
        addEls(x, true);
      }
    });
    if (parenthesis) {
      els.push(<span>)</span>);
    }
  };

  addEls(v, false);

  return (
    <div>
      {els.map((el, i) => (
        <React.Fragment key={i}>{el}</React.Fragment>
      ))}
    </div>
  );
};

const FormattedGroup = styled('div')<{ first: boolean }>`
  ${(p) => (p.first ? `margin-left: ${p.theme.spacing(2)}px` : '')};
`;

const renderMultiliner = (v: FormattedRuleElement, layer = 0) => {
  return (
    <FormattedGroup first={layer !== 0} key={layer}>
      {v.els.map((el, i) => (
        <React.Fragment key={`${layer}-${i}`}>
          {typeof el === 'string' ? (
            <div>{el}</div>
          ) : (
            <div>{renderMultiliner(el, layer)}</div>
          )}
          {i !== v.els.length - 1 && (
            <div>
              <strong>{v.type === 'all' ? ' and ' : ' or '}</strong>
            </div>
          )}
        </React.Fragment>
      ))}
    </FormattedGroup>
  );
};

export const RuleRenderer = <T extends string>({
  value,
  config,
  variant = 'oneline'
}: Omit<Props<T>, 'onChange' | 'variant'> & {
  variant?: 'oneline' | 'multiline';
}) => {
  if (!value) {
    return null;
  }

  const v = formatRule(value, config);

  if (variant === 'oneline') {
    return renderOneliner(v);
  }

  if (variant === 'multiline') {
    return renderMultiliner(v);
  }

  return null;
};
