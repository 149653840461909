import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { Menu as MenuIcon, Bell as NotificationsIcon } from 'react-feather';
import React from 'react';
import { css } from '../../../emotion';
import { signOut } from '../../services/auth';

export default function Header(props: any) {
  const {
    adminUser,
    title,
    tabs,
    activeTabIndex,
    onChange,
    onDrawerToggle
  } = props;

  return (
    <React.Fragment>
      <AppBar
        color="primary"
        position="sticky"
        elevation={0}
        style={{ backgroundColor: '#222' }}
      >
        <Toolbar>
          <Grid container spacing={8} alignItems="center">
            <Hidden smUp>
              <Grid item>
                <IconButton
                  color="inherit"
                  aria-label="Open drawer"
                  onClick={onDrawerToggle}
                  className={css((t) => ({ marginLeft: -t.spacing() }))}
                >
                  <MenuIcon />
                </IconButton>
              </Grid>
            </Hidden>
            <Grid item xs />
            <Grid item>
              <Tooltip title="Alerts • No alerts">
                <IconButton color="inherit">
                  <NotificationsIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Click to sign out">
                <IconButton
                  color="inherit"
                  className={css((t) => ({ padding: t.spacing() * 0.75 }))}
                  onClick={signOut}
                >
                  <Avatar src={adminUser.photoURL} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        className={css((t) => ({ zIndex: 0 }))}
        style={{ backgroundColor: '#222' }}
        color="primary"
        position="static"
        elevation={0}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={8}>
            <Grid item xs>
              <Typography color="inherit" variant="h5">
                {title}
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      {tabs.length > 0 && (
        <AppBar
          component="div"
          className={css((t) => ({
            zIndex: 0,
            backgroundColor: '#eaeff1',
            padding: '12px 36px 0'
          }))}
          color="transparent"
          position="static"
          elevation={0}
        >
          <Tabs value={activeTabIndex} color="inherit" onChange={onChange}>
            {tabs.map((tab: string, i: number) => (
              <Tab color="inherit" label={tab} key={i} />
            ))}
          </Tabs>
        </AppBar>
      )}
    </React.Fragment>
  );
}
