import {
  Button,
  ButtonBase,
  FormControl,
  FormGroup,
  FormLabel,
  Popover
} from '@material-ui/core';
import React, { useRef, useState } from 'react';
import { css, styled } from '../../emotion';
import { SelectorChip } from '../SelectorChip';

export type SingleSelectorOption<T> = {
  label: React.ReactNode;
  value: T;
};

export type SingleSelectorProps<T> = {
  value: T | null | undefined;
  onChange: (nextValue: T) => void;
  options: SingleSelectorOption<T>[];
  legend?: React.ReactNode;
  minMenuWidth?: string | number;
};

const Label = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

export const SingleSelectorChip = SelectorChip;

export const SingleSelectorPopoverBody = <T extends unknown>({
  value,
  onChange,
  options,
  legend,
  minMenuWidth = 150,
  onClose
}: SingleSelectorProps<T> & { onClose: () => void }) => {
  return (
    <FormControl
      component="fieldset"
      className={css((t) => ({
        margin: t.spacing(2),
        minWidth: minMenuWidth
      }))}
    >
      {legend && (
        <FormLabel
          classes={{
            root: css((t) => ({
              padding: t.spacing(1)
            }))
          }}
          component="legend"
        >
          {legend}
        </FormLabel>
      )}
      <FormGroup>
        {options.map((o, i) => (
          <Button
            key={i}
            onClick={() => {
              onChange(o.value);
              onClose();
            }}
          >
            <Label>{o.label}</Label>
          </Button>
        ))}
      </FormGroup>
    </FormControl>
  );
};

export const SingleSelector = <T extends unknown>({
  children,
  ...props
}: SingleSelectorProps<T> & { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <>
      <ButtonBase onClick={() => setOpen((x) => !x)} ref={ref}>
        {children}
      </ButtonBase>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <SingleSelectorPopoverBody {...props} onClose={() => setOpen(false)} />
      </Popover>
    </>
  );
};
