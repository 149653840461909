export type FeatureCategory = 'In beta' | 'Add-ons' | 'Settings' | 'Deprecated';

export type FeatureAccessLevel = 'Self Service' | 'On demand' | 'WIP';

export interface IFeature {
  // Unique key to identify the feature in the database.
  // This decouples usage in our codebase (we can rename them at will)
  // and storage.
  dbKey: string;

  // Default value when a feature flag hasn't been set in a space yet
  defaultValue: boolean;

  // Name used to identify the feature flag in the UI - communicated to customers
  displayName: string;

  // Optional description of a feature flag - communicated to customers
  description?: string;

  // Used to group features in categories to get a better overview
  category: FeatureCategory;

  // Used to automate whether customers themselves can toggle feature flags.
  accessLevel: FeatureAccessLevel;
}

export type Feature =
  // Current features
  | 'API_V1'
  | 'TEAMS'
  | 'SCANLESS_TRACKING'
  | 'CHROME_EXTENSION'
  | 'SCHEMA_BASED_REVISIONS'
  | 'PAYOUTS_V1'
  | 'AMAZON_SUBTAGS'
  | 'REFERRER_REPORTS_V1'
  | 'CUSTOM_DIMENSIONS'
  | 'AUTHOR_TAGS'
  | 'LINK_WRAPPER'
  | 'CHANNELS'
  // Features that shoud be deleted
  | 'LINK_GENERATOR_V1'
  | 'LINK_GENERATOR_RATES'
  | 'HEATMAP_COMPARISON_V1'
  | 'NAV_V2'
  | 'DAILY_EARNINGS_REPORT_V1'
  | 'PAGE_LABELS_V1'
  | 'SALES_EXPORT_V1'
  | 'IDB_DENORMALIZATION_V1'
  | 'RECOMMENDATIONS_V1'
  | 'REFERRERS_V1'
  | 'PERFORMANCE_V2'
  | 'CHROME_EXTENSION_AMAZON_V1'
  | 'CHROME_EXTENSION_BOOKING_V1'
  | 'CHROME_EXTENSION_KLOOK_V1'
  | 'AFFILIATE_PROGRAM_V1'
  | 'POSTGRES_V1'
  | 'API_PEPPERJAM_V1';

export const FEATURES: { [name in Feature]: IFeature } = {
  // TODO: check these values!
  REFERRER_REPORTS_V1: {
    dbKey: 'referrerReportsV1',
    defaultValue: false,
    displayName: 'Active - Traffic Sources & Realtime Reporting',
    category: 'Add-ons',
    accessLevel: 'On demand'
  },
  CUSTOM_DIMENSIONS: {
    dbKey: 'customDimensionsV1',
    defaultValue: false,
    displayName: 'Active - Custom dimensions',
    category: 'Add-ons',
    accessLevel: 'On demand'
  },
  LINK_WRAPPER: {
    dbKey: 'linkWrapperV1',
    defaultValue: false,
    displayName: 'Active - Link Wrapper',
    category: 'In beta',
    accessLevel: 'WIP'
  },
  CHANNELS: {
    dbKey: 'channelsV1',
    defaultValue: false,
    displayName: 'Active - Offsite channels',
    category: 'In beta',
    accessLevel: 'WIP'
  },
  AUTHOR_TAGS: {
    dbKey: 'authorTags',
    defaultValue: false,
    displayName: 'Active - Author Reporting',
    category: 'Add-ons',
    accessLevel: 'On demand'
  },
  API_V1: {
    dbKey: 'apiV1',
    defaultValue: false,
    displayName: 'Active - Publisher API',
    category: 'Add-ons',
    accessLevel: 'On demand'
  },
  CHROME_EXTENSION: {
    dbKey: 'chromeExtensionV2',
    defaultValue: false,
    displayName: 'Active - Chrome Extension (V2)',
    category: 'In beta',
    accessLevel: 'WIP'
  },
  TEAMS: {
    dbKey: 'teamsV1',
    defaultValue: false,
    displayName: 'Active - Teams Access (Override plan)',
    category: 'Settings',
    accessLevel: 'On demand'
  },
  PAYOUTS_V1: {
    dbKey: 'payoutsV1',
    defaultValue: false,
    displayName: 'Active - Payout Reporting',
    category: 'Add-ons',
    accessLevel: 'On demand'
  },
  SCANLESS_TRACKING: {
    dbKey: 'scanlessTracking',
    defaultValue: false,
    displayName: 'Active - Scanless Tracking',
    category: 'Settings',
    accessLevel: 'Self Service'
  },
  SCHEMA_BASED_REVISIONS: {
    dbKey: 'schemaBasedRevisions',
    defaultValue: false,
    displayName: 'Active - Schema-based Revisions',
    category: 'Settings',
    accessLevel: 'Self Service'
  },
  AMAZON_SUBTAGS: {
    dbKey: 'amazonSubtags',
    defaultValue: false,
    displayName: 'Active - Amazon Subtags',
    category: 'Settings',
    accessLevel: 'On demand'
  },
  LINK_GENERATOR_RATES: {
    dbKey: 'linkGeneratorRates',
    defaultValue: false,
    displayName: 'Inactive - Link Generator Rates',
    category: 'Deprecated',
    accessLevel: 'Self Service'
  },
  NAV_V2: {
    dbKey: 'navV2',
    defaultValue: false,
    displayName: 'Inactive - Navigation (v2)',
    category: 'Deprecated',
    accessLevel: 'WIP'
  },
  SALES_EXPORT_V1: {
    dbKey: 'salesExportV1',
    defaultValue: false,
    displayName: 'Inactive - Sales Export V1 (v1)',
    category: 'Deprecated',
    accessLevel: 'WIP'
  },
  DAILY_EARNINGS_REPORT_V1: {
    dbKey: 'dailyEarningsReportV1',
    defaultValue: false,
    displayName: 'Inactive - Daily Earnings Report Schedule (v1)',
    category: 'Deprecated',
    accessLevel: 'WIP'
  },
  PAGE_LABELS_V1: {
    dbKey: 'pageLabelsV1',
    defaultValue: false,
    displayName: 'Inactive - Page Labels (v1)',
    category: 'Deprecated',
    accessLevel: 'WIP'
  },
  HEATMAP_COMPARISON_V1: {
    dbKey: 'heatmapsComparisonV1',
    defaultValue: false,
    displayName: 'Inactive - Heatmap Comparison (v1)',
    category: 'Deprecated',
    accessLevel: 'WIP'
  },
  IDB_DENORMALIZATION_V1: {
    dbKey: 'idbDenormalizaionV1',
    defaultValue: true,
    displayName: 'Inactive - IDB Denormalization (v1)',
    category: 'Deprecated',
    accessLevel: 'On demand'
  },
  RECOMMENDATIONS_V1: {
    dbKey: 'recommendationsV1',
    defaultValue: false,
    displayName: 'Inactive - Recommendations (v1)',
    category: 'Deprecated',
    accessLevel: 'WIP'
  },
  REFERRERS_V1: {
    dbKey: 'referrersV1',
    defaultValue: false,
    displayName: 'Inactive - Referrers (v1)',
    category: 'Deprecated',
    accessLevel: 'WIP'
  },
  PERFORMANCE_V2: {
    dbKey: 'performanceV2',
    defaultValue: false,
    displayName: 'Inactive - Performance (v2)',
    category: 'Deprecated',
    accessLevel: 'WIP'
  },
  CHROME_EXTENSION_AMAZON_V1: {
    dbKey: 'chromeExtensionAmazonV1',
    defaultValue: false,
    displayName: 'Inactive - Chrome Extension: Amazon (v1)',
    category: 'Deprecated',
    accessLevel: 'On demand'
  },
  CHROME_EXTENSION_BOOKING_V1: {
    dbKey: 'chromeExtensionBookingV1',
    defaultValue: false,
    displayName: 'Inactive - Chrome Extension: Booking (v1)',
    category: 'Deprecated',
    accessLevel: 'On demand'
  },
  CHROME_EXTENSION_KLOOK_V1: {
    dbKey: 'chromeExtensionKlookV1',
    defaultValue: false,
    displayName: 'Inactive - Chrome Extension: Klook (v1)',
    category: 'Deprecated',
    accessLevel: 'On demand'
  },
  API_PEPPERJAM_V1: {
    dbKey: 'apiPepperjamV1',
    defaultValue: false,
    displayName: 'Inactive - API: Pepperjam (v1)',
    category: 'Deprecated',
    accessLevel: 'WIP'
  },
  POSTGRES_V1: {
    dbKey: 'postgresV1',
    defaultValue: false,
    displayName: 'Inactive - Postgres (v1)',
    category: 'Deprecated',
    accessLevel: 'On demand'
  },
  AFFILIATE_PROGRAM_V1: {
    dbKey: 'affiliateProgramV1',
    defaultValue: true,
    displayName: 'Inactive - Affiliate Program (v1)',
    category: 'Deprecated',
    accessLevel: 'WIP'
  },
  LINK_GENERATOR_V1: {
    dbKey: 'linkGeneratorV1',
    defaultValue: false,
    displayName: 'Inactive - Link Generator Tool',
    category: 'Deprecated',
    accessLevel: 'WIP'
  }
};

export interface IFeatureStatus {
  enabled: boolean;
  modifiedBy: string;
  modifiedAt: string;
}

export type FeatureMap = { [dbKey: string]: IFeatureStatus };

const getFeature = (feature: Feature) => FEATURES[feature];

export const isFeatureEnabled = (featureMap: FeatureMap, feature: Feature) => {
  const f = getFeature(feature);
  const setting = featureMap[f.dbKey];
  return setting ? setting.enabled : f.defaultValue;
};

export const setFeature = (
  featureMap: FeatureMap,
  feature: Feature,
  enabled: boolean,
  modifiedBy: string,
  modifiedAt = new Date().toISOString()
): FeatureMap => {
  const f = getFeature(feature);
  return {
    ...featureMap,
    [f.dbKey]: {
      enabled,
      modifiedBy,
      modifiedAt
    }
  };
};

export const hasDefaultValue = (featureMap: FeatureMap, feature: Feature) => {
  const f = getFeature(feature);
  return !!featureMap[f.dbKey];
};
