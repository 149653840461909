import { styled } from '../emotion';

export const FlexContainer = styled('div')<{
  justifyContent?: React.CSSProperties['justifyContent'];
  alignItems?: React.CSSProperties['alignItems'];
  direction?: React.CSSProperties['flexDirection'];
  marginBottom?: number;
  spacing?: number;
  wrap?: any;
  fullWidth?: boolean;
}>((p) => ({
  display: 'flex',
  justifyContent: p.justifyContent || 'flex-start',
  flexWrap: p.wrap || undefined,
  alignItems: p.alignItems || 'center',
  flexDirection: p.direction || 'row',
  gap: p.theme.spacing(p.spacing === undefined ? 1 : p.spacing),
  width: p.fullWidth ? '100%' : undefined,
  marginBottom: p.marginBottom ? p.theme.spacing(p.marginBottom) : undefined
}));

export const FlexItem = styled('div')<{ flex?: number }>((p) => ({
  flex: p.flex ?? 1
}));
