import { createTheme } from '@material-ui/core/styles';
import { ChevronDown } from 'react-feather';

const selectIcon = () => (
  <ChevronDown
    size={18}
    className="MuiSelect-icon MuiSelect-iconOutlined"
    style={{
      width: '18px',
      height: '18px',
      flexShrink: 0,
      top: 'calc(50% - 8px)'
    }}
  />
);

let theme = createTheme({
  typography: {
    fontFamily: 'Lato,sans-serif',
    fontSize: 14,
    caption: {
      fontSize: '0.85rem',
      fontWeight: 500
    },
    h4: {
      fontWeight: 600
    },
    h5: {
      fontWeight: 600
    },
    h6: {
      fontWeight: 'inherit'
    }
  },
  palette: {
    primary: {
      light: '#91d5ff',
      main: '#1890ff',
      dark: '#0050b3'
    },
    grey: {
      A100: '#f7f7f9',
      A200: '#ececef',
      A400: '#d7d7d8',
      A700: '#6f6f73',
      900: '#18202c'
    }
  },
  shape: {
    borderRadius: 8
  }
});

theme = {
  ...theme,
  overrides: {
    MuiChip: {
      root: {
        borderRadius: '8px',
        height: '36px',
        backgroundColor: '#FFFFFF'
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '8px',
        minHeight: '36px',
        boxShadow:
          '0px 1px 1px -1px rgba(0,0,0,0.1), 2px 5px 4px -1px rgba(0,0,0,0.01)',
        backgroundColor: '#FFFFFF'
      },
      notchedOutline: {
        borderColor: 'rgb(0 0 0 / 6%)'
      }
    },
    MuiCard: {
      root: {
        padding: theme.spacing(2),
        overflow: 'initial'
      }
    },
    MuiAccordion: {
      root: {
        '&:before': {
          backgroundColor: 'none'
        }
      }
    },
    MuiDialogTitle: {
      root: {
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        paddingTop: theme.spacing(4),
        fontWeight: 700
      }
    },
    MuiDialogContent: {
      root: {
        padding: theme.spacing(4)
      }
    },
    MuiDialogActions: {
      root: {
        padding: theme.spacing(4)
      }
    },
    MuiCardActions: {
      root: {
        padding: theme.spacing(2)
      }
    },
    MuiButtonBase: {
      root: {
        fontFamily: 'Lato'
      }
    },
    MuiButton: {
      containedPrimary: {
        background: 'linear-gradient(45deg,#1890ff 30%,#40a9ff 90%)',
        boxShadow: '0 1px 5px 0px rgba(0,80,179,0.3)',
        fontWeight: 700,
        '&:disabled': {
          background: 'rgba(0, 0, 0, 0.12)',
          fontWeight: 'normal'
        },
        '&:hover': {
          background: 'linear-gradient(45deg,#0083fc 30%,#3c9ffb 90%)'
        }
      },
      label: {
        textTransform: 'initial'
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none'
        }
      }
    },
    MuiTabs: {
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.primary.light
      }
    },
    MuiTab: {
      root: {
        borderBottom: `2px solid transparent`,
        [theme.breakpoints.up('md')]: {
          minWidth: 0
        }
      },
      selected: {
        color: `${theme.palette.primary.main}`,
        zIndex: 1,
        borderBottom: `3px solid ${theme.palette.primary.main} !important`,
        fontWeight: 700
      }
    },
    MuiSvgIcon: {
      fontSizeSmall: {
        fontSize: '16px'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '13px',
        borderRadius: 4,
        padding: '8px',
        backgroundColor: '#111111'
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854'
      }
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium as any
      }
    },
    MuiPaper: {
      elevation1: {
        boxShadow:
          '0px 1px 1px -1px rgba(0,0,0,0.1), 2px 5px 4px -1px rgba(0,0,0,0.01)'
      },
      elevation3: {
        boxShadow:
          '0px 1px 2px 0px rgba(0,0,0,0.1), 2px 5px 7px 2px rgba(0,0,0,0.01)'
      },
      elevation8: {
        boxShadow:
          '0px 1px 5px 5px rgba(0,0,0,0.09), 2px 5px 9px 2px rgba(0,0,0,0.04)'
      }
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20
        }
      }
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32
      }
    },
    MuiTable: {
      stickyHeader: {
        backgroundColor: theme.palette.background.paper
      }
    },
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: '0px 1px 0px 0px rgba(0,0,0,0.1)'
      }
    },
    // @ts-ignore
    MuiToggleButtonGroup: {
      root: {
        borderRadius: `100px`
      }
    },
    // @ts-ignore
    MuiToggleButton: {
      selected: {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: `${theme.palette.common.white} !important`,
        '&:hover': {
          backgroundColor: `${theme.palette.primary.dark} !important`
        }
      },
      root: {
        borderRadius: `100px`,
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px !important`,
        background: 'none',
        border: 'none',
        color: 'gray',
        backgroundColor: '#eee',
        '&:hover': {
          backgroundColor: `${theme.palette.primary.main} !important`,
          color: `${theme.palette.primary.contrastText} !important`
        }
      }
    },
    // @ts-ignore
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'transparent' // overrides not so transparent mui default
        }
      }
    }
  },
  props: {
    MuiTab: {
      disableRipple: true
    },
    MuiModal: {
      disableEnforceFocus: true
    },
    MuiSelect: {
      IconComponent: selectIcon
    }
  },
  mixins: {
    ...theme.mixins,
    toolbar: {
      minHeight: 48
    }
  }
};

export default theme;
