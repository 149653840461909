import React, { FunctionComponent } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  IconButton,
  Typography
} from '@material-ui/core';
import { capitalize, compact, isNil, keyBy, startCase } from 'lodash';
import { X } from 'react-feather';
import { Currency, toPercent } from '../../../../components/Number';
import {
  IConvertedSale,
  ISale,
  toTrackedSaleId
} from '../../../../domainTypes/performance';
import { css, styled } from '../../../../emotion';
import { SalesStatus } from '../SalesStatus';
import { PARTNERS } from '../../../../domainTypes/partners';
import { TrackingLabel } from './TrackingLabel';
import { ITrackedConvertedSaleWithProduct } from './types';
import { SalesTimeline } from './SalesTimeline';
import { FlagWithLabel } from '../../../../components/Flag';
import { DeviceIconWithLabel } from '../../../../components/DeviceIcon';
import { PlatformWithColor } from '../../../../components/PlatformWithColor';
import { useHasPayouts } from '../../../../services/payouts';
import { PayoutStatusBadge } from '../PayoutStatus';
import {
  formatDatePrecise,
  HUMAN_DATE_SHORT,
  toMoment
} from '../../../../services/time';
import { TransactionType } from './TransactionType';
import {
  CommissionWrapper,
  DrawerHeader,
  DrawerSection,
  List,
  SectionHeading
} from '../../../../components/DrawerHeader';
import { deleteSales } from '../../services/sale';
import {
  useCurrentUser,
  useHasCurrentUserRequiredScopes
} from '../../../../services/currentUser';
import { ButtonWithPromise } from '../../../../components/ButtonWithPromise';
import { Doc } from '../../../../domainTypes/document';
import { useBoolean } from '../../../../hooks/useBoolean';
import { useSnackbar } from '../../../../hooks/useSnackbar';
import { ReferringLink } from './ReferringLink';
import { Link } from 'react-router-dom';
import { useRoutes } from '../../../../routes';
import { AdvertiserWithColor } from '../../../../components/AdvertiserWithColor';
import { useFlushAnalyticsV2Cache } from '../../../../services/analyticsV2/cache';

const CommissionSummary = ({ sale }: { sale: IConvertedSale }) => {
  const hasPayoutsAvailable = useHasPayouts();

  return (
    <DrawerSection>
      <SectionHeading>Commission</SectionHeading>
      <CommissionWrapper>
        <Typography variant="h4" component="p">
          <Currency
            cents={sale.amount.commission}
            currency={sale.amount.currency}
          />
        </Typography>
        <SalesStatus status={sale.status} />
        {hasPayoutsAvailable &&
          sale.payoutStatus &&
          sale.payoutStatus !== 'unknown' && (
            <PayoutStatusBadge status={sale.payoutStatus} />
          )}
      </CommissionWrapper>
      {sale.originalAmount.currency !== sale.amount.currency && (
        <Typography
          variant="body2"
          color="textSecondary"
          component="p"
          style={{ marginTop: '6px' }}
        >
          Converted from{' '}
          <Currency
            cents={sale.originalAmount.commission}
            currency={sale.originalAmount.currency}
          />
        </Typography>
      )}
    </DrawerSection>
  );
};

const toCommissionPercent = (sale: ITrackedConvertedSaleWithProduct) => {
  const baseValue = sale.sale.amount.price || sale.sale.amount.revenue;
  if (baseValue === 0 || baseValue === null) {
    return 0;
  }
  const percent = toPercent(sale.sale.amount.commission, baseValue);
  return percent.toLocaleString('en-US', {
    style: 'percent',
    maximumFractionDigits: 2
  });
};

const PayoutSummary = ({
  sale
}: {
  sale: ITrackedConvertedSaleWithProduct;
}) => {
  const hasPayoutsAvailable = useHasPayouts();
  const hasAnyPayoutData =
    (sale.sale.payoutStatus && sale.sale.payoutStatus !== 'unknown') ||
    sale.sale.payoutId !== null;

  if (!hasAnyPayoutData || !hasPayoutsAvailable) {
    return null;
  }

  return (
    <DrawerSection>
      <SectionHeading>Payout details</SectionHeading>
      {!hasPayoutsAvailable ? null : (
        <List>
          {sale.sale.payoutStatus && sale.sale.payoutStatus !== 'unknown' && (
            <>
              <dt>Payout status</dt>
              <dd>{capitalize(sale.sale.payoutStatus)}</dd>
            </>
          )}
          {sale.sale.payoutId && (
            <>
              <dt>Payout ID</dt>
              <dd>{sale.sale.payoutId}</dd>
            </>
          )}
          {sale.sale.payoutDate && (
            <>
              <dt>Paid on</dt>
              <dd>
                {formatDatePrecise(
                  toMoment(sale.sale.payoutDate),
                  HUMAN_DATE_SHORT
                )}
              </dd>
            </>
          )}
        </List>
      )}
    </DrawerSection>
  );
};

const OrderSummary = ({ sale }: { sale: ITrackedConvertedSaleWithProduct }) => {
  const PARTNERS_BY_KEY = keyBy(PARTNERS, (p) => p.key);
  const commissionPercent = toCommissionPercent(sale);
  const { ROUTES } = useRoutes();

  return (
    <DrawerSection>
      <SectionHeading>Order details</SectionHeading>
      <Typography
        variant="h6"
        component="p"
        paragraph
        style={{ wordBreak: 'break-all' }}
      >
        {sale.sale.partnerProductName}
      </Typography>
      <List>
        {sale.saleType && sale.saleType !== 'unknown' && (
          <>
            <dt>Transaction type</dt>
            <dd>
              <TransactionType saleType={sale.saleType} />
            </dd>
          </>
        )}
        {sale.sale.orderId && (
          <>
            <dt>Order ID</dt>
            <dd>{sale.sale.orderId}</dd>
          </>
        )}
        {sale.sale.partnerProductId && (
          <>
            <dt>SKU / Product ID</dt>
            <dd>{sale.sale.partnerProductId}</dd>
          </>
        )}
        {sale.sale.amount.price !== null && sale.sale.amount.price !== 0 && (
          <>
            <dt>Sale amount</dt>
            <dd>
              <Currency
                cents={sale.sale.amount.price}
                currency={sale.sale.amount.currency}
              />
            </dd>
          </>
        )}
        {sale.sale.amount.revenue !== null &&
          sale.sale.amount.revenue !== undefined &&
          sale.sale.amount.revenue !== sale.sale.amount.price &&
          sale.sale.amount.revenue !== 0 && (
            <>
              <dt>Sale revenue</dt>
              <dd>
                <Currency
                  cents={sale.sale.amount.revenue}
                  currency={sale.sale.amount.currency}
                />
              </dd>
            </>
          )}
        {commissionPercent !== 0 && (
          <>
            <dt>Commission rate</dt>
            <dd>{commissionPercent}</dd>
          </>
        )}
        {!isNil(sale.sale.quantity) && sale.sale.quantity > 0 && (
          <>
            <dt>Quantity</dt>
            <dd>{sale.sale.quantity}</dd>
          </>
        )}
        {sale.sale.coupon && (
          <>
            <dt>Coupon</dt>
            <dd>{sale.sale.coupon}</dd>
          </>
        )}
        {sale.sale.advertiserId && (
          <>
            <dt>Advertiser ID</dt>
            <dd>{sale.sale.advertiserId}</dd>
          </>
        )}
        {sale.sale.advertiserName && (
          <>
            <dt>Advertiser</dt>
            <dd>
              <Link
                to={ROUTES.performanceNew.transactions.url({
                  advertisers: sale.sale.advertiserName
                })}
              >
                <AdvertiserWithColor
                  advertiserName={sale.sale.advertiserName}
                  partnerKey={sale.sale.partnerKey}
                />
              </Link>
            </dd>
          </>
        )}
        <dt>Platform</dt>
        <dd>
          <Link
            to={ROUTES.performanceNew.transactions.url({
              partners: sale.sale.partnerKey
            })}
          >
            <PlatformWithColor
              partner={PARTNERS_BY_KEY[sale.sale.partnerKey]}
            />
          </Link>
        </dd>
      </List>
    </DrawerSection>
  );
};

const TrackingSummary = ({
  sale
}: {
  sale: ITrackedConvertedSaleWithProduct;
}) => {
  if (!sale.sale.trackingLabel) {
    return null;
  }

  return (
    <DrawerSection>
      <SectionHeading>Tracking</SectionHeading>
      <Typography
        variant="body2"
        component="p"
        paragraph
        className={css((t) => ({ color: t.palette.grey[600] }))}
      >
        Referring page
      </Typography>
      <TrackingLabel d={sale} />
      {sale.click?.pId && (
        <>
          <Typography
            variant="body2"
            component="p"
            className={css((t) => ({
              color: t.palette.grey[600],
              marginTop: `${t.spacing(2)}px`,
              marginBottom: `${t.spacing(1)}px`
            }))}
          >
            Clicked link
          </Typography>
          <ReferringLink spaceId={sale.spaceId} productId={sale.click?.pId} />
        </>
      )}
    </DrawerSection>
  );
};

const TimelineSummary = ({
  sale
}: {
  sale: ITrackedConvertedSaleWithProduct;
}) => {
  return (
    <DrawerSection>
      <SectionHeading>Timeline</SectionHeading>
      <SalesTimeline sale={sale} />
    </DrawerSection>
  );
};

const MetadataSummary = ({
  sale
}: {
  sale: ITrackedConvertedSaleWithProduct;
}) => {
  if (
    !sale.sale.metadata ||
    compact(Object.values(sale.sale.metadata)).length === 0
  ) {
    return null;
  }

  const keys = Object.keys(sale.sale.metadata)
    .filter((k) => sale.sale.metadata[k])
    .sort();

  return (
    <DrawerSection>
      <SectionHeading>Metadata</SectionHeading>
      <List style={{ gridTemplateColumns: '150px 1fr' }}>
        {keys.map((key) => (
          <React.Fragment key={key}>
            <dt>{startCase(key)}</dt>
            {key.toLowerCase().indexOf('country') !== -1 ? (
              <dd>
                <FlagWithLabel code={sale.sale.metadata[key]} />
              </dd>
            ) : (
              <dd>{sale.sale.metadata[key]}</dd>
            )}
          </React.Fragment>
        ))}
      </List>
    </DrawerSection>
  );
};

const AudienceSummary = ({
  sale
}: {
  sale: ITrackedConvertedSaleWithProduct;
}) => {
  return (
    <DrawerSection>
      <SectionHeading>Audience</SectionHeading>
      <List>
        {sale.click?.country && (
          <>
            <dt>Click country</dt>
            <dd>
              <FlagWithLabel code={sale.click.country} />
            </dd>
          </>
        )}
        {sale.device && (
          <>
            <dt>Device type</dt>
            <dd>
              <DeviceIconWithLabel device={sale.device} />
            </dd>
          </>
        )}
      </List>
    </DrawerSection>
  );
};

const InnerWrapper = styled('div')`
  max-width: 400px;
`;

interface ConfirmationResultProps {
  close: () => void;
  isOpen: boolean;
  spaceId: string;
  saleId: Doc<ISale>['id'];
  afterDelete: () => void;
}

const ConfirmDeleteSaleDialog: FunctionComponent<ConfirmationResultProps> = ({
  isOpen,
  close,
  spaceId,
  saleId,
  afterDelete
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const flushCache = useFlushAnalyticsV2Cache();
  return (
    <Dialog open={isOpen}>
      <DialogTitle>Delete this commission?</DialogTitle>
      <DialogContent>
        <Typography variant="body1" paragraph>
          This commission wil be permanently deleted, and removed from all
          reports.
        </Typography>
        <Typography variant="body1">
          To add it again, you'd need to create a new manual commission.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={close}>Cancel</Button>
        <ButtonWithPromise
          pending={<span>Deleting commission...</span>}
          variant="contained"
          color="secondary"
          onClick={() =>
            deleteSales(spaceId, [saleId])
              .then(() => {
                close();
                afterDelete();
                flushCache(spaceId);
                return enqueueSnackbar(
                  'Your commission was deleted successfully.',
                  {
                    variant: 'success'
                  }
                );
              })
              .catch(() => {
                return enqueueSnackbar(
                  'Commission could not be deleted. Please try again or get in touch with support.',
                  {
                    variant: 'error'
                  }
                );
              })
          }
        >
          Delete commission
        </ButtonWithPromise>
      </DialogActions>
    </Dialog>
  );
};

const DeleteAction: FunctionComponent<{
  sale: ITrackedConvertedSaleWithProduct;
  afterDelete: () => void;
}> = ({ sale, afterDelete }) => {
  const { space } = useCurrentUser();
  const [isDeleteDialogOpen, openDeleteDialog, closeDeleteDialog] = useBoolean(
    false
  );
  const saleId = toTrackedSaleId(sale);
  return (
    <>
      <ConfirmDeleteSaleDialog
        close={closeDeleteDialog}
        isOpen={isDeleteDialogOpen}
        spaceId={space.id}
        saleId={saleId}
        afterDelete={afterDelete}
      />
      <Button color="primary" onClick={openDeleteDialog}>
        Delete commission
      </Button>
    </>
  );
};

function useCanDeleteSale(sale: ITrackedConvertedSaleWithProduct): boolean {
  const isCreatedBySystem = sale.createdBy === 'AFFILIMATE';
  const isBonus = sale.saleType === 'bonus';
  const [canDeleteTransactions] = useHasCurrentUserRequiredScopes([
    'transactions.delete'
  ]);

  return canDeleteTransactions && !isCreatedBySystem && isBonus;
}

const Actions: FunctionComponent<{
  sale: ITrackedConvertedSaleWithProduct;
  closeDrawer: () => void;
}> = ({ sale, closeDrawer }) => {
  const canDeleteSale = useCanDeleteSale(sale);
  if (!canDeleteSale) return null;
  return (
    <DrawerSection>
      <SectionHeading>Actions</SectionHeading>
      <DeleteAction sale={sale} afterDelete={closeDrawer} />
    </DrawerSection>
  );
};

export const SalesDrawer = ({
  open,
  onClose,
  sale
}: {
  open: boolean;
  onClose: () => void;
  sale: ITrackedConvertedSaleWithProduct | null;
}) => {
  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <DrawerHeader>
        <Typography variant="body1" component="span">
          Transaction details
        </Typography>
        <IconButton onClick={onClose}>
          <X size={16} />
        </IconButton>
      </DrawerHeader>
      {sale && (
        <InnerWrapper>
          <CommissionSummary sale={sale.sale} />
          <OrderSummary sale={sale} />
          <TrackingSummary sale={sale} />
          <AudienceSummary sale={sale} />
          <TimelineSummary sale={sale} />
          <PayoutSummary sale={sale} />
          <MetadataSummary sale={sale} />
          <Actions sale={sale} closeDrawer={onClose} />
        </InnerWrapper>
      )}
    </Drawer>
  );
};
