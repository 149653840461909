import firebase from 'firebase/app';
import { IPubSubInstruction } from './pubsub';

// A product scan is an object to track the progress and result of a product scan
// it's creation will lead to

export type ProductScanPageStatus =
  | 'PENDING'
  | 'SUCCESS'
  | 'ERROR'
  | 'ABORTED'
  | 'UNMODIFIED';

export type ProductScanPageStatusShort = 'P' | 'S' | 'E' | 'A' | 'U';

export const PRODUCT_SCAN_PAGE_STATUS_TO_SHORT: {
  [K in ProductScanPageStatus]: ProductScanPageStatusShort;
} = {
  PENDING: 'P',
  SUCCESS: 'S',
  ERROR: 'E',
  ABORTED: 'A',
  UNMODIFIED: 'U'
};

export const PRODUCT_SCAN_PAGE_STATUS_From_SHORT: {
  [K in ProductScanPageStatusShort]: ProductScanPageStatus;
} = {
  P: 'PENDING',
  S: 'SUCCESS',
  E: 'ERROR',
  A: 'ABORTED',
  U: 'UNMODIFIED'
};

export type ProductScanSpeed =
  | 'CRAWL'
  | 'ULTRA_SLOW'
  | 'SLOW'
  | 'MEDIUM'
  | 'FAST';
export const PRODUCT_SCAN_SPEEDS: ProductScanSpeed[] = [
  'CRAWL',
  'ULTRA_SLOW',
  'SLOW',
  'MEDIUM',
  'FAST'
];
export type ProductScanNote =
  | 'SITEGROUND_SLOW_DOWN'
  | 'GODADDY_SLOW_DOWN'
  | 'SITEMAP_EMPTY';

export const isScanDone = (scan: IProductScan | IProductScanResult) =>
  scan.status === 'DONE' ||
  scan.status === 'ERROR' ||
  scan.status === 'ABORTED';
export const isPageDone = (page: IProductScanPage) => page.status !== 'PENDING';
export const hasPageErrored = (page: IProductScanPage) =>
  page.status === 'ERROR';

export const wasPageAborted = (page: IProductScanPage) =>
  page.status === 'ABORTED';

export interface IProductScanPage {
  url: string;
  status: ProductScanPageStatus;
  message: string;
  suggestionsFound: number;
  checkedAt: number | null;
}

export interface IProductScanPageResult {
  url: string;
  status: ProductScanPageStatus;
  message: string;
  suggestions: IProductSuggestion[];
  checkedAt: number | null;
}

export interface IProductScanTaskParams {
  spaceId: string;
  scanId: string;
  pages: string[];
  speed: ProductScanSpeed;
  knownInternalPages: string[];
}

export interface IProductScanTracker {
  spaceId: string;
  scanId: string;
  status: ProductScanStatus;
  createdAt: number;
  finishedAt: number | null;
  pages: { [i: string]: [ProductScanPageStatusShort] } | null; // null when no pages are present
}

export interface IProductScanRescheduleParams {
  spaceId: string;
  scanId: string;
  pages: string[];
}

export interface IProductScanTarget {
  type: 'DOMAIN' | 'PAGE';
  url: string;
}

export type ProductScanStatus =
  | 'INITIALISING'
  | 'SPEED_TEST'
  | 'PENDING'
  | 'DONE'
  | 'ERROR'
  | 'ABORTED';

export type ProductScanType = 'FULL' | 'PARTIAL';

export interface IProductScan {
  spaceId: string;
  status: ProductScanStatus;
  pages: { [md5Url: string]: IProductScanPage };
  targets: IProductScanTarget[];
  scanType: ProductScanType;
  createdAt: number;
  createdBy: string;
  triggerReport?: boolean;
  finishedAt: number | null;
  speed?: ProductScanSpeed;
  notes?: ProductScanNote[];
  publishOnDone?: IPubSubInstruction[] | null;
}

// This is a flattened version of IProductScan, to make it easier to handle on the frontend
export interface IProductScanResult {
  spaceId: string;
  status: ProductScanStatus;
  pages: IProductScanPage[];
  targets: IProductScanTarget[];
  createdAt: firebase.firestore.Timestamp;
  createdBy: string;
  finishedAt: firebase.firestore.Timestamp | null;
  speed?: ProductScanSpeed;
  notes: ProductScanNote[];
}

export interface INameSuggestion {
  type: 'aText' | 'aTitle' | 'imgTitle' | 'imgAlt';
  pre: string;
  name: string;
  post: string;
}

export interface IImgSuggestion {
  src: string;
  title: string;
  alt: string;
}

export interface IProductSuggestion {
  nameSuggestions: INameSuggestion[];
  imgSuggestions: IImgSuggestion[];
  href: string;
  destHref: string;
  page: string;
  foundAt: firebase.firestore.Timestamp;
  score: number;
}

export interface IProductSuggestionGroupPageInfo {
  url: string;
  timesFound: number;
  /**
   * @deprecated
   * Don't collect this information anymore, as it's too heavy.
   * Large spaces simply have too much volume there.
   */
  nameSuggestions: INameSuggestion[];
  /**
   * @deprecated
   * Don't collect this information anymore, as it's too heavy.
   * Large spaces simply have too much volume there.
   */
  imgSuggestions: IImgSuggestion[];
}

export interface IProductSuggestionGroup {
  href: string;
  destHref: string;
  names: string[];
  images: string[];
  pages: IProductSuggestionGroupPageInfo[];
  score: number;
  timesFound: number;
  productId?: string;
}

export interface IProductSuggestionTrashItem {
  href: string;
  trashedAt: firebase.firestore.Timestamp;
  trashedBy: string;
}

export interface IProductScanSpeedRestriction {
  url: string; // a domain url
  maxSpeed: ProductScanSpeed;
}
