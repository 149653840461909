import firebase from 'firebase/app';
import { mapValues } from 'lodash';
import {
  counterToShortCounter,
  ICounter,
  IDeviceShortCounter,
  IShortCounter,
  Timeframe
} from './analytics';
import { Device } from './tracking';

export interface IDenormalizationParams {
  spaceId: string;
  timeframe: Timeframe;
  goToCloudStorageIfNeedBe?: boolean;
  execute?: boolean;
}

export type EventType = 'served' | 'viewed' | 'clicked';

export interface IEvent {
  spaceId: string;
  timeKey: string;
  pageViewId: string;
  device: Device;
  country: string;
  href: string; // page url
  originalHref: string; // link before smart label
  partnerKey?: string; // dynamic partnerkey, i.e. skimlinks
  productId: string;
  occurrence: number;
  type: EventType;
  trackingId: string;
  createdAt: string; // a string, because we only need this in specific cases. Transform lazily later
}

export interface IRow {
  time_key: string;
  space_id: string;
  href: string; // page url
  original_href: string; // link before smart label
  product_id: string;
  occurrence: number | null;
  page_view_id: string;
  geo_country: string | null;
  device: Device;
  type: EventType;
  partner_key: string | null; // dynamic partnerkey, i.e. skimlinks
  tracking_id: string | null;
  created_at: { value: string }; // a BigQuery Timestamp
}

export interface IDenormalization {
  spaceId: string;
  timeKey: string; // Year, day of year. E.g. 2019002 - January 2nd 2019
  tz: string;
  denormalizedAt: firebase.firestore.Timestamp;
  version: string;
}

export interface IDenormalizedClickEvent {
  pvId: string; // pageViewId
  sId: string; // spaceId - NEW

  /*
   * Want to add these but cannot do this easily,
   * seems there are two different IEvent types
   * and one doesn't include the referring domain data?
   *
  rUrl: string;
  rOrigin: string;
  */
  device: Device;
  country: string;
  href: string;
  pId: string; // productId
  tId: string; // trackingId
  occ: number; // occurrence
  createdAt: firebase.firestore.Timestamp;

  // This field is a later addition.
  // It is NOT extracted in the old denormalization process (just defaulting to '' there)
  // This object is also stored inside of an ITrackedSale, hence we keep this type intact for now
  // These days the data is coming from clicks_v1 in PG.
  // The ITrackedSale object in FS is also practically deprecated and will eventually be fully
  // on it's way out. There is more technical work to do to make this possible, as right now
  // it is also the intermediary object which gets transformed to an IPostgresSale. This will
  // all need to change - which in essence is a simplifcation and improvement of the whole system
  cId: string; // channelId
}

export interface IDenormalizationByClick extends IDenormalization {
  // If storage path is present, events // will be null.
  // Events need to be resolved through the storage path!
  // The buffer stored in Cloud Storage is binary encoded and compressed.
  // To get the events back out call decompress(buffer.toString('binary'))
  storagePath?: string;
  events: IDenormalizedClickEvent[];
}

interface IDenormalizedPageWithFullCounters {
  totalCounts: ICounter;
  byCountry: { [country: string]: ICounter };
  byDevice: { [device in Device]: ICounter };
  byProduct: {
    [productId: string]: {
      [occurrence: string]: ICounter;
    };
  };
}

export interface IDenormalizedPage {
  totalCounts: IShortCounter;
  byCountry: { [country: string]: IShortCounter };
  byDevice: IDeviceShortCounter;
  byProduct: {
    [productId: string]: {
      [occurrence: string]: IShortCounter;
    };
  };
}

export interface IDenomarlizationByPage extends IDenormalization {
  // If storage path is present, pages
  // will be null.
  // Pages need to be resolved through the storage path!
  // The buffer stored in Cloud Storage is binary encoded and compressed.
  // To get the pages back out call decompress(buffer.toString('binary'))
  storagePath?: string;
  pages: {
    [href: string]: IDenormalizedPage;
  };
}

interface IDenormalizedProductWithFullCounters {
  totalCounts: ICounter;
  byCountry: { [country: string]: ICounter };
  byDevice: { [device in Device]: ICounter };
  byPage: {
    [href: string]: {
      [occurrence: string]: ICounter;
    };
  };
}

export interface IDenormalizedProduct {
  totalCounts: IShortCounter;
  byCountry: { [country: string]: IShortCounter };
  byDevice: IDeviceShortCounter;
  byPage: {
    [href: string]: {
      [occurrence: string]: IShortCounter;
    };
  };
}
export interface IDenormalizationByProduct extends IDenormalization {
  // If storage path is present, products
  // will be null.
  // Products need to be resolved through the storage path!
  // The buffer stored in Cloud Storage is binary encoded and compressed.
  // To get the products back out call decompress(buffer.toString('binary'))
  storagePath?: string;
  products: {
    [productId: string]: IDenormalizedProduct;
  };
}

export const PRODUCT_MIGRATIONS: {
  from: string;
  to: string;
  migrate: (p: IDenormalizedProduct) => IDenormalizedProduct;
}[] = [
  {
    from: 'v1',
    to: 'v2',
    migrate: (product) => {
      product.byPage = mapValues(product.byPage, (v) => {
        const oldValue = (v as unknown) as IShortCounter; // used to be an ICounter really
        return { '0': oldValue };
      });
      return product;
    }
  },
  {
    from: 'v2',
    to: 'v3',
    migrate: (product) => {
      product.byCountry = { unknown: product.totalCounts };
      return product;
    }
  },
  {
    from: 'v3',
    to: 'v4',
    migrate: (product) => {
      const oldProduct = (product as unknown) as IDenormalizedProductWithFullCounters;
      return {
        totalCounts: counterToShortCounter(oldProduct.totalCounts),
        byCountry: mapValues(oldProduct.byCountry, counterToShortCounter),
        byDevice: mapValues(oldProduct.byDevice, counterToShortCounter),
        byPage: mapValues(oldProduct.byPage, (p) =>
          mapValues(p, counterToShortCounter)
        )
      };
    }
  }
];

export const PAGE_MIGRATIONS: {
  from: string;
  to: string;
  migrate: (p: IDenormalizedPage) => IDenormalizedPage;
}[] = [
  {
    from: 'v1',
    to: 'v2',
    migrate: (page) => {
      page.byProduct = mapValues(page.byProduct, (v) => {
        const oldValue = (v as unknown) as IShortCounter; // used to be an ICounter really
        return { '0': oldValue };
      });
      return page;
    }
  },
  {
    from: 'v2',
    to: 'v3',
    migrate: (page) => {
      page.byCountry = { unknown: page.totalCounts };
      return page;
    }
  },
  {
    from: 'v3',
    to: 'v4',
    migrate: (page) => {
      const oldPage = (page as unknown) as IDenormalizedPageWithFullCounters;
      return {
        totalCounts: counterToShortCounter(oldPage.totalCounts),
        byCountry: mapValues(oldPage.byCountry, counterToShortCounter),
        byDevice: mapValues(oldPage.byDevice, counterToShortCounter),
        byProduct: mapValues(oldPage.byProduct, (p) =>
          mapValues(p, counterToShortCounter)
        )
      };
    }
  }
];
