import { ButtonBase, Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { truncate } from 'lodash';
import React from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Copy } from 'react-feather';
import { Truncated } from '../../../../../../components/Truncated';
import { Doc } from '../../../../../../domainTypes/document';
import { styled } from '../../../../../../emotion';
import { withStoppedPropagation } from '../../../../../../helpers';
import { usePromise } from '../../../../../../hooks/usePromise';
import { useSnackbar } from '../../../../../../hooks/useSnackbar';
import {
  getProductByIdPg,
  renderLinkName
} from '../../../../../../services/products';
import { PartnerLogo } from '../../../../../../components/PartnerLogo';
import {
  constructPartnerForKey,
  getKnownPartnerForKey
} from '../../../../../../services/partner';
import { FlexContainer } from '../../../../../../layout/Flex';

const ProductLink = styled('span')`
  position: relative;
  display: inline-block;
  font-size: ${(p) => p.theme.custom.fontSize.s}px;
  color: ${(p) => p.theme.palette.grey[500]};
`;

const Wrapper = styled('span')`
  width: 100%;
  display: block;

  .copy-clipboard {
    display: none;
    position: absolute;
    right: -50px;
    color: ${(p) => p.theme.palette.grey[600]};

    &:hover {
      color: ${(p) => p.theme.palette.grey[800]};
    }
  }

  &:hover .copy-clipboard {
    display: initial;
  }
`;

const LinkText = styled('span')`
  display: inline-block;
`;

export const ProductLinkCell = <
  T extends { url: string; name: string; partner_key: string }
>({
  p
}: {
  p: Doc<T>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const linkName = renderLinkName({
    name: p.data.name,
    url: p.data.url,
    partnerKey: p.data.partner_key
  });

  return (
    <Tooltip title={linkName} placement="top">
      <Wrapper>
        <Typography
          style={{
            fontSize: '13px',
            fontWeight: 700,
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
          }}
          title="See more details"
        >
          {linkName}
        </Typography>
        <ProductLink>
          <LinkText>{truncate(p.data.url, { length: 60 })}</LinkText>
          <CopyToClipboard text={p.data.url}>
            <ButtonBase
              className="copy-clipboard"
              title="Click to copy link"
              onClick={withStoppedPropagation(() => {
                return enqueueSnackbar('Link copied!', { variant: 'info' });
              })}
            >
              <Copy size={10} /> Copy
            </ButtonBase>
          </CopyToClipboard>
        </ProductLink>
      </Wrapper>
    </Tooltip>
  );
};

export const ProductLinkCellWithPartner = <
  T extends { url: string; name: string; partner_key: string }
>({
  p
}: {
  p: Doc<T>;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const linkName = renderLinkName({
    name: p.data.name,
    url: p.data.url,
    partnerKey: p.data.partner_key
  });

  const partner =
    getKnownPartnerForKey(p.data.partner_key) ||
    constructPartnerForKey(p.data.partner_key);

  return (
    <Tooltip title={linkName} placement="top">
      <FlexContainer>
        <PartnerLogo partner={partner} />
        <Wrapper>
          <Typography
            style={{
              fontSize: '13px',
              fontWeight: 700,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis'
            }}
            title="See more details"
          >
            {linkName}
          </Typography>
          <ProductLink>
            <LinkText>{truncate(p.data.url, { length: 60 })}</LinkText>
            <CopyToClipboard text={p.data.url}>
              <ButtonBase
                className="copy-clipboard"
                title="Click to copy link"
                onClick={withStoppedPropagation(() => {
                  return enqueueSnackbar('Link copied!', { variant: 'info' });
                })}
              >
                <Copy size={10} /> Copy
              </ButtonBase>
            </CopyToClipboard>
          </ProductLink>
        </Wrapper>
      </FlexContainer>
    </Tooltip>
  );
};

const ProductSkeleton = styled(Skeleton)`
  width: 30%;
`;

export const ProductLinkCellLazy = ({
  spaceId,
  productId
}: {
  spaceId: string;
  productId: string;
}) => {
  const [product, loading] = usePromise(() => {
    return getProductByIdPg(spaceId, productId);
  }, [spaceId, productId]);

  if (loading) {
    return <ProductSkeleton />;
  }
  if (!product) {
    return <div>{productId}</div>;
  }
  return (
    <ProductLinkCell
      p={{ id: productId, collection: 'NOT_DEFINED', data: product }}
    />
  );
};

export const ProductLinkCellWithPartnerLazy = ({
  spaceId,
  productId
}: {
  spaceId: string;
  productId: string;
}) => {
  const [product, loading] = usePromise(() => {
    return getProductByIdPg(spaceId, productId);
  }, [spaceId, productId]);

  if (loading) {
    return <ProductSkeleton />;
  }
  if (!product) {
    return <div>{productId}</div>;
  }
  return (
    <ProductLinkCellWithPartner
      p={{ id: productId, collection: 'NOT_DEFINED', data: product }}
    />
  );
};

export const ProductLinkCellLazyMinimal = ({
  spaceId,
  productId
}: {
  spaceId: string;
  productId: string;
}) => {
  const [p, loading] = usePromise(() => {
    return getProductByIdPg(spaceId, productId);
  }, [spaceId, productId]);

  if (loading) {
    return <ProductSkeleton />;
  }
  if (!p) {
    return <div>{productId}</div>;
  }

  const linkName = renderLinkName({
    name: p.name,
    url: p.url,
    partnerKey: p.partner_key
  });
  return (
    <a href={p.url} target="_blank" rel="noopener noreferrer">
      <Truncated
        title={`Open "${p.url}" in a new tab`}
        alwaysShowTooltip={linkName !== p.url}
      >
        {linkName}
      </Truncated>
    </a>
  );
};
