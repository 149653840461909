import {
  Button,
  ButtonBase,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Popover
} from '@material-ui/core';
import React, { useMemo, useRef, useState } from 'react';
import { compact } from 'lodash';
import { css, styled } from '../../emotion';
import { pluralize } from '../../services/pluralize';
import { SelectorChip } from '../SelectorChip';
import { SearchInput } from '../SearchInput';

export type MultiSelectorOption<Key> = {
  label: React.ReactNode;
  value: Key;
};

export type MultiSelectorOptionGrouper<Key> = {
  key: string;
  label: React.ReactNode;
  predicate: (el: MultiSelectorOption<Key>) => boolean;
};

export type MultiSelectorProps<Key> = {
  value: Set<Key>;
  onChange: (nextValue: Set<Key>) => void;

  options: MultiSelectorOption<Key>[];
  groupers?: MultiSelectorOptionGrouper<Key>[];

  legend?: React.ReactNode;
  minMenuWidth?: string | number;
  allOption?: React.ReactNode;
  allowFocusing?: boolean;
  allowSearch?: boolean;
};

const Label = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FocusButton = styled(Button)`
  margin-left: ${(p) => p.theme.spacing()}px !important;
`;

const SearchWrapper = styled('div')`
  margin: ${(p) => p.theme.spacing(1)}px;
  width: calc(100% - ${(p) => p.theme.spacing(2)}px);

  & > div {
    width: 100%;
  }
`;

export const getAppliedLabel = (
  name: string,
  labels: string[],
  prefix = 'Filtered by'
) => {
  const ls =
    labels.length === 1 ? labels[0] : pluralize(name, labels.length, true);
  return `${prefix} ${ls}`;
};

export const MultiSelectorChip = SelectorChip;

export const MultiSelectorPopoverBody = <Key extends string>({
  value,
  onChange,
  options = [],
  groupers,
  legend,
  minMenuWidth = 150,
  allOption,
  allowFocusing,
  allowSearch
}: MultiSelectorProps<Key>) => {
  const [search, setSearch] = useState('');
  const toggle = (key: Key) => {
    const nextValue = new Set(value);
    if (nextValue.has(key)) {
      nextValue.delete(key);
    } else {
      nextValue.add(key);
    }
    onChange(nextValue);
  };
  const allSelected = value.size === options.length;

  const groups = useMemo(() => {
    if (!groupers) {
      return null;
    }
    const groups: {
      [key: string]: {
        key: string;
        label: React.ReactNode;
        options: MultiSelectorOption<Key>[];
      };
    } = {};
    options.forEach((o) => {
      const grouper = groupers.find((g) => g.predicate(o));
      if (grouper) {
        const group = (groups[grouper.key] = groups[grouper.key] || {
          key: grouper.key,
          label: grouper.label,
          options: []
        });
        group.options.push(o);
      }
    });
    // keep sort order
    return compact(groupers.map((g) => groups[g.key]));
  }, [groupers, options]);

  const renderOptions = (opts: MultiSelectorOption<Key>[]) => (
    <>
      {opts
        .filter((o) => {
          if (!search) {
            return true;
          }
          return o.value
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        })
        .map((o) => (
          <FormControlLabel
            key={o.value}
            control={
              <Checkbox
                checked={value.has(o.value)}
                color="primary"
                value={o.value}
                onChange={() => toggle(o.value)}
              />
            }
            classes={{
              label: css(() => ({ width: '100%' })),
              root: css(() => ({
                marginLeft: 0,
                marginRight: 0
              }))
            }}
            label={
              <Label>
                <div>{o.label}</div>
                {allowFocusing && (
                  <FocusButton
                    size="small"
                    variant="text"
                    color="primary"
                    onClick={() => onChange(new Set([o.value]))}
                  >
                    Only
                  </FocusButton>
                )}
              </Label>
            }
          />
        ))}
    </>
  );

  return (
    <FormControl
      component="fieldset"
      className={css((t) => ({
        margin: t.spacing(2),
        minWidth: minMenuWidth
      }))}
    >
      {legend && (
        <FormLabel
          classes={{
            root: css((t) => ({
              padding: t.spacing(1)
            }))
          }}
          component="legend"
        >
          {legend}
        </FormLabel>
      )}
      {allowSearch && (
        <SearchWrapper>
          <SearchInput
            placeholder={`Search ${legend?.toString().toLowerCase()}`}
            fullWidth
            value={search}
            onChange={setSearch}
          />
        </SearchWrapper>
      )}
      <FormGroup>
        {allOption && (
          <FormControlLabel
            classes={{
              root: css(() => ({
                marginLeft: 0,
                marginRight: 0
              }))
            }}
            control={
              <Checkbox
                checked={allSelected}
                color="primary"
                indeterminate={value.size > 0 && !allSelected}
                value={'____ALL____'}
                onChange={() =>
                  allSelected
                    ? onChange(new Set())
                    : onChange(new Set(options.map((o) => o.value)))
                }
              />
            }
            label={allOption}
          />
        )}
        {groups
          ? groups.map((g) => (
              <React.Fragment key={g.key}>
                <FormLabel
                  classes={{
                    root: css((t) => ({
                      padding: t.spacing(1)
                    }))
                  }}
                  component="legend"
                >
                  {g.label}
                </FormLabel>
                {renderOptions(g.options)}
              </React.Fragment>
            ))
          : renderOptions(options)}
      </FormGroup>
    </FormControl>
  );
};

export const MultiSelector = <Key extends string>({
  children,
  ...props
}: MultiSelectorProps<Key> & { children: React.ReactNode }) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  return (
    <>
      <ButtonBase onClick={() => setOpen((x) => !x)} ref={ref}>
        {children}
      </ButtonBase>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        anchorEl={ref.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <MultiSelectorPopoverBody {...props} />
      </Popover>
    </>
  );
};
