import { IconButton, Tooltip, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { groupBy, keyBy, truncate } from 'lodash';
import moment from 'moment-timezone';
import React, { ChangeEvent, useMemo, useState } from 'react';
import { ArrowRightCircle, Info } from 'react-feather';
import { Link } from 'react-router-dom';
import { Arrow } from '../../../../components/Arrow';
import { CustomPagination } from '../../../../components/CustomPagination';
import {
  DeviceIcon,
  DeviceIconWithLabel
} from '../../../../components/DeviceIcon';
import {
  applySorterOnGroups,
  applySorterOnItems,
  Grouper,
  Groupers,
  RowsRenderer,
  Sorter,
  Sorters,
  UNKNOWN
} from '../../../../components/GroupableList';
import { Currency, Number } from '../../../../components/Number';
import { PartnerLogo } from '../../../../components/PartnerLogo';
import { IColumn } from '../../../../components/Table/Column';
import { CurrencyCode } from '../../../../domainTypes/currency';
import { Doc } from '../../../../domainTypes/document';
import { EMPTY_ARR, NOOP } from '../../../../domainTypes/emptyConstants';
import { PARTNERS } from '../../../../domainTypes/partners';
import {
  aggregateSales,
  getTrackingLabelWithoutAffilimateId,
  IEarning,
  ITrackedConvertedSale
} from '../../../../domainTypes/performance';
import { IProduct } from '../../../../domainTypes/product';
import { interpolatePath, PATHS } from '../../../../domainTypes/routes';
import { Device, getDeviceSortValue } from '../../../../domainTypes/tracking';
import { styled } from '../../../../emotion';
import { Section } from '../../../../layout/Section';
import { debuggedShallowEqual } from '../../../../services/debug';
import { getPathName } from '../../../../services/pages';
import { getKnownPartnerForKey } from '../../../../services/partner';
import { pluralize } from '../../../../services/pluralize';
import { formatDatePrecise, SIMPLE_DATE } from '../../../../services/time';
import { SalesDrawer } from './SalesDrawer';
import { SalesStatusCompact } from '../SalesStatus';
import { ITrackedConvertedSaleWithProduct } from './types';
import { TrackingLabel } from './TrackingLabel';
import { useHasPayouts } from '../../../../services/payouts';
import { ReferringLink } from './ReferringLink';

const HEIGHT = 500;

export type ColumnName =
  | 'status'
  | 'partnerKey'
  | 'trackingLabel'
  | 'referringProduct'
  | 'referringPage'
  | 'amount'
  | 'origin'
  | 'trackingId'
  | 'coupon'
  | 'subid1'
  | 'subid2'
  | 'subid3'
  | 'subid4'
  | 'subid5'
  | 'subid6'
  | 'quantity'
  | 'completionDate'
  | 'clickDate'
  | 'commissionPercent'
  | 'partnerProductName'
  | 'advertiser'
  | 'advertiserId'
  | 'orderId'
  | 'partnerProductId'
  | 'price'
  | 'device'
  | 'saleDate';

const PARTNERS_BY_KEY = keyBy(PARTNERS, (p) => p.key);

const PartnerProductWrapper = styled('div')`
  display: flex;
  align-items: center;
  width: 100%;
  word-break: break-all;

  div:first-of-type {
    margin-right: 12px;
  }
`;

const PartnerProductLink: React.FC<{
  d: ITrackedConvertedSaleWithProduct;
}> = ({ d }) => {
  const text = document.createElement('textarea');
  const isCpcWithNoProductName =
    d.sale.saleType === 'cpc' && !d.sale.partnerProductName;

  text.innerHTML =
    d.sale.partnerProductName ||
    (d.sale.saleType === 'cpc' ? 'CPC commission' : 'Not provided');

  const value = text.value;
  const partner = PARTNERS_BY_KEY[d.sale.partnerKey];

  return (
    <Tooltip title={value} placement="top">
      <PartnerProductWrapper>
        {partner && <PartnerLogo partner={partner} />}{' '}
        {value ? truncate(value, { length: 80 }) : ''}
        {isCpcWithNoProductName && (
          <Tooltip
            placement="top"
            title="CPC commissions do not include product names"
          >
            <span
              style={{
                display: 'flex',
                marginLeft: 6,
                color: '#999',
                position: 'relative',
                top: '1px'
              }}
            >
              <Info size={14} />
            </span>
          </Tooltip>
        )}
      </PartnerProductWrapper>
    </Tooltip>
  );
};

const OrderIdColumn = ({ d }: { d: ITrackedConvertedSaleWithProduct }) => {
  if (!d.sale.orderId) {
    return null;
  }

  return <div>{d.sale.orderId}</div>;
};

const ProductIdColumn = ({ d }: { d: ITrackedConvertedSaleWithProduct }) => {
  if (!d.sale.partnerProductId) {
    return null;
  }

  return <div>{d.sale.partnerProductId}</div>;
};

const ClickDateColumn = ({ d }: { d: ITrackedConvertedSaleWithProduct }) => {
  if (!d.dates.click) {
    return null;
  }

  return <div>{d.dates.click.format('MMM D, YYYY HH:mm:ss')}</div>;
};

const CompletionDateColumn = ({
  d
}: {
  d: ITrackedConvertedSaleWithProduct;
}) => {
  if (!d.dates.completion) {
    return null;
  }

  return <div>{d.dates.completion.format('MMM D, YYYY')}</div>;
};

const SaleDateColumn = ({ d }: { d: ITrackedConvertedSaleWithProduct }) => {
  if (!d.dates.completion) {
    return <div>{formatDatePrecise(d.dates.sale, 'MMM D, YYYY HH:mm:ss')}</div>;
  }

  const isSameTimestamp =
    d.dates.completion &&
    d.dates.sale.valueOf() === d.dates.completion.valueOf();
  const daysBetween = d.dates.completion.diff(d.dates.sale, 'days');

  if (isSameTimestamp) {
    return <div>{formatDatePrecise(d.dates.sale, SIMPLE_DATE)}</div>;
  }

  return (
    <div>
      <div>{formatDatePrecise(d.dates.sale, SIMPLE_DATE)}</div>
      <Typography variant="caption" component="p" color="textSecondary">
        {!isSameTimestamp &&
          `${daysBetween} ${pluralize('day', daysBetween)} ahead`}
      </Typography>
    </div>
  );
};

export type SalesListColumn = IColumn<
  ITrackedConvertedSaleWithProduct,
  ColumnName,
  { hasPayouts: boolean }
>;

export const COLUMNS: SalesListColumn[] = [
  {
    key: 'status',
    head: () => 'Status',
    cell: (d, o) => {
      return (
        <SalesStatusCompact
          status={d.sale.status}
          payoutStatus={
            o.hasPayouts && d.sale.payoutStatus
              ? d.sale.payoutStatus
              : undefined
          }
        />
      );
    },
    align: 'left',
    width: 24,
    flexGrow: 1
  },
  {
    key: 'orderId',
    head: () => 'Order ID',
    cell: (d) => <OrderIdColumn d={d} />,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'partnerProductId',
    head: () => 'Product ID or SKU',
    cell: (d) => <ProductIdColumn d={d} />,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'partnerProductName',
    head: () => 'Product name or description',
    cell: (d) => <PartnerProductLink d={d} />,
    align: 'left',
    width: 300,
    flexGrow: 5
  },
  {
    key: 'advertiser',
    head: () => 'Advertiser',
    cell: (d) => d.sale.advertiserName,
    align: 'left',
    width: 120,
    flexGrow: 2
  },
  {
    key: 'advertiserId',
    head: () => 'Advertiser ID',
    cell: (d) => d.sale.advertiserId,
    align: 'left',
    width: 120,
    flexGrow: 2
  },
  {
    key: 'saleDate',
    head: () => 'Date',
    cell: (d) => <SaleDateColumn d={d} />,
    align: 'left',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'completionDate',
    head: () => 'Completion Date',
    cell: (d) => <CompletionDateColumn d={d} />,
    align: 'left',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'clickDate',
    head: () => 'Click Date',
    cell: (d) => <ClickDateColumn d={d} />,
    align: 'left',
    width: 100,
    flexGrow: 2
  },
  {
    key: 'trackingLabel',
    head: () => 'Referrer',
    cell: (d) => <TrackingLabel d={d} />,
    align: 'left',
    width: 200,
    flexGrow: 5
  },
  {
    key: 'origin',
    head: () => 'Origin domain',
    cell: (d) => d.origin,
    align: 'left',
    width: 200,
    flexGrow: 5
  },
  {
    key: 'referringPage',
    head: () => 'Referring page',
    cell: (d) => d.pageUrl,
    align: 'left',
    width: 200,
    flexGrow: 5
  },
  {
    key: 'referringProduct',
    head: () => 'Clicked link',
    cell: (d) =>
      d.click?.pId && (
        <ReferringLink spaceId={d.spaceId} productId={d.click.pId} />
      ),
    align: 'left',
    width: 200,
    flexGrow: 3
  },
  {
    key: 'coupon',
    head: () => 'Coupon',
    cell: (d) => d.sale.coupon || null,
    align: 'left',
    width: 200,
    flexGrow: 3
  },
  {
    key: 'device',
    head: () => 'Device',
    cell: (d) => {
      const { device } = d;
      if (device === 'unknown') {
        return null;
      }
      return (
        <Tooltip title={`Sale occurred on ${device}`} placement="top">
          <div>
            <DeviceIcon size={18} device={device} ignoreUnknown />
          </div>
        </Tooltip>
      );
    },
    align: 'center',
    width: 48
  },
  {
    key: 'commissionPercent',
    head: () => 'Commission Rate',
    cell: (d) =>
      d.sale.commissionPercent !== null && (
        <Number n={d.sale.commissionPercent} format="percent" digits={2} />
      ),
    align: 'right',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'trackingId',
    head: () => 'Tracking',
    cell: (d) =>
      d.sale.trackingLabel ? (
        <Tooltip title={d.sale.trackingLabel} placement="top">
          <div>{d.sale.trackingLabel || ''}</div>
        </Tooltip>
      ) : null,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'subid1',
    head: () => 'SubID 1',
    cell: (d) => d.sale.metadata?.subId1 || null,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'subid2',
    head: () => 'SubID 2',
    cell: (d) => d.sale.metadata?.subId2 || null,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'subid3',
    head: () => 'SubID 3',
    cell: (d) => d.sale.metadata?.subId3 || null,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'subid4',
    head: () => 'SubID 4',
    cell: (d) => d.sale.metadata?.subId4 || null,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'subid5',
    head: () => 'SubID 5',
    cell: (d) => d.sale.metadata?.subId5 || null,
    align: 'left',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'quantity',
    head: () => 'Quantity',
    cell: (d) => d.sale.quantity,
    align: 'right',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'price',
    head: () => 'Sale amount',
    cell: (d) =>
      d.sale.amount.price && (
        <Currency
          cents={d.sale.amount.price}
          currency={d.sale.amount.currency}
        />
      ),
    align: 'right',
    width: 100,
    flexGrow: 1
  },
  {
    key: 'amount',
    head: () => 'Commission',
    cell: (d) => (
      <>
        {d.sale.status === 'Refunded' && '-'}
        <Currency
          cents={d.sale.amount.commission}
          currency={d.sale.amount.currency}
        />
      </>
    ),
    align: 'right',
    width: 100,
    flexGrow: 1
  }
];

export const DEFAULT_VISIBLE_COLUMNS = COLUMNS.filter(
  (h) =>
    ![
      'completionDate',
      'clickDate',
      'orderId',
      'advertiserId',
      'partnerProductId',
      'referringProduct',
      'referringPage',
      'saleDate',
      //'device',
      'trackingId',
      'subid1',
      'subid2',
      'subid3',
      'subid4',
      'subid5',
      'quantity',
      'price',
      'origin',
      'coupon',
      'commissionPercent'
    ].includes(h.key)
).map((h) => h.key);

const ROW_HEIGHT = 40;

const SalesGroupHeaderWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: ${(p) => p.theme.spacing(2)}px;
  padding-left: 0;
  cursor: pointer;
`;

const SalesGroupDate = styled('div')`
  font-size: 14px;
  font-weight: 700;
  display: flex;
  align-items: center;
`;

const SalesGroupStats = styled('div')`
  display: flex;
  justify-content: 'space-between';
  text-align: right;
  > :last-child {
    min-width: ${(p) => p.theme.spacing(16)}px;
  }
`;

const ArrowContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  font-size: 12px;
`;

export const SalesGroupHeader: React.FC<{
  earnings: IEarning;
  expanded: boolean;
  onExpand: (nextExpanded: boolean) => void;
}> = ({ earnings, expanded, onExpand, children }) => {
  return (
    <SalesGroupHeaderWrapper role="button" onClick={() => onExpand(!expanded)}>
      <SalesGroupDate>
        <ArrowContainer>
          <Arrow width={16} height={16} dir={expanded ? 'DOWN' : 'RIGHT'} />
        </ArrowContainer>
        <div>{children}</div>
      </SalesGroupDate>
      <SalesGroupStats>
        {earnings.salesCount > 0 && (
          <div>{pluralize('rows', earnings.transactionCount, true)}</div>
        )}
        {earnings.salesCount === 0 && (
          <div>
            {pluralize(
              'sale',
              earnings.refundCount + earnings.cancelledCount,
              true
            )}
          </div>
        )}
        <div>
          <Currency cents={earnings.total} currency={earnings.currency} />
        </div>
      </SalesGroupStats>
    </SalesGroupHeaderWrapper>
  );
};

const groupSales = (
  sales: ITrackedConvertedSaleWithProduct[],
  currency: CurrencyCode,
  groupFn: (s: ITrackedConvertedSaleWithProduct) => string
) => {
  const grouped = groupBy(sales, groupFn);
  return Object.entries(grouped).map(([key, sales]) => ({
    key,
    items: sales,
    summary: aggregateSales(
      sales.map((s) => s.sale),
      currency
    )
  }));
};

export type SalesSorter = Sorter<IEarning, ITrackedConvertedSaleWithProduct>;

export const SORTERS: Sorters<IEarning, ITrackedConvertedSaleWithProduct> = {
  date: {
    key: 'date',
    label: 'Sale Date',
    groups: { sort: (g) => g.key, dir: 'desc' },
    items: { sort: (s) => s.sale.saleDate.toMillis(), dir: 'desc' }
  },
  clickDate: {
    key: 'clickDate',
    label: 'Click Date',
    groups: { sort: (g) => g.key, dir: 'desc' },
    items: { sort: (s) => s.sale.clickDate?.toMillis() || null, dir: 'asc' }
  },
  amount: {
    key: 'amount',
    label: 'Earnings',
    groups: { sort: (g) => g.summary.total, dir: 'desc' },
    items: {
      sort: (s) =>
        s.sale.amount.commission * (s.sale.status === 'Refunded' ? -1 : 1),
      dir: 'desc'
    }
  },
  itemsSold: {
    key: 'itemsSold',
    label: 'Items sold',
    groups: { sort: (g) => g.summary.salesCount, dir: 'desc' },
    items: {
      sort: (s) =>
        s.sale.amount.commission * (s.sale.status === 'Refunded' ? -1 : 1),
      dir: 'desc'
    }
  },
  itemsRefunded: {
    key: 'itemsRefunded',
    label: 'Items refunded',
    groups: { sort: (g) => g.summary.refundCount, dir: 'desc' },
    items: {
      sort: (s) =>
        s.sale.amount.commission * (s.sale.status === 'Refunded' ? -1 : 1),
      dir: 'desc'
    }
  },
  itemsCancelled: {
    key: 'itemsCancelled',
    label: 'Items cancelled',
    groups: { sort: (g) => g.summary.cancelledCount, dir: 'desc' },
    items: {
      sort: (s) =>
        s.sale.amount.commission * (s.sale.status === 'Refunded' ? -1 : 1),
      dir: 'desc'
    }
  }
};

export type GrouperType =
  | 'date'
  | 'page'
  | 'origin'
  | 'productClicked'
  | 'productSold'
  | 'device'
  | 'partner'
  | 'subId'
  | 'advertiser'
  | string;

export type SalesGrouper = Grouper<
  GrouperType,
  IEarning,
  ITrackedConvertedSaleWithProduct
>;

export const GROUPERS: Groupers<
  GrouperType,
  IEarning,
  ITrackedConvertedSaleWithProduct
> = {
  date: {
    key: 'date',
    label: 'Date',
    toKey: (s) => s.dates.sale.format('YYYY-MM-DD'),
    toLabel: ({ key }) => moment(key, 'YYYY-MM-DD').format('MMMM Do (dddd)'),
    defaultSorter: SORTERS.date
  },
  page: {
    key: 'page',
    label: 'Page',
    toKey: (s) => s.pageUrl || UNKNOWN,
    toLabel: ({ key }) => (key === UNKNOWN ? key : getPathName(key)), // replace with a link
    defaultSorter: {
      key: 'page',
      label: 'Page',
      groups: {
        sort: (g) => (g.key === UNKNOWN ? 'zzzzzzz' : g.key),
        dir: 'asc'
      },
      items: SORTERS.date.items
    }
  },
  origin: {
    key: 'origin',
    label: 'Origin domain',
    toKey: (s) => s.origin || UNKNOWN,
    toLabel: ({ key }) => (key === UNKNOWN ? key : key), // replace with a link
    defaultSorter: {
      key: 'origin',
      label: 'Site',
      groups: {
        sort: (g) => (g.key === UNKNOWN ? 'zzzzzzz' : g.key),
        dir: 'asc'
      },
      items: SORTERS.date.items
    }
  },
  productClicked: {
    key: 'productClicked',
    label: 'Link clicked',
    toKey: (s) =>
      s.product
        ? JSON.stringify({ id: s.product.id, name: s.product.data.name })
        : UNKNOWN,
    toLabel: ({ key }) => {
      if (key === UNKNOWN) {
        return key;
      }
      const { id, name } = JSON.parse(key) as { id: string; name: string };
      return (
        <>
          {truncate(name, { length: 50 })}&nbsp;
          <Link
            to={interpolatePath(PATHS.links.details.overview, {
              productId: id
            })}
          >
            <Tooltip
              title="Open analytics for this specific link"
              placement="top"
            >
              <IconButton
                color="primary"
                style={{ position: 'relative', top: '-2px' }}
              >
                <ArrowRightCircle size={18} />
              </IconButton>
            </Tooltip>
          </Link>
        </>
      );
    },
    defaultSorter: SORTERS.amount
  },
  productSold: {
    key: 'productSold',
    label: 'Product sold',
    toKey: (s) => s.sale.partnerProductName || UNKNOWN,
    toLabel: ({ key }) => {
      const label = truncate(key, { length: 80 });
      return <div title={key}>{label}</div>;
    },
    defaultSorter: SORTERS.amount
  },
  advertiser: {
    key: 'advertiser',
    label: 'Advertiser',
    toKey: (s) => {
      const partner = getKnownPartnerForKey(s.sale.partnerKey);
      if (!partner) {
        return UNKNOWN;
      }
      if (partner.isNetwork) {
        return `${s.sale.advertiserName} (${partner.name})`;
      }
      return s.sale.advertiserName;
    },
    toLabel: (g) => g.key,
    defaultSorter: {
      key: 'advertiser',
      label: 'Advertiser',
      groups: {
        sort: (g) => g.key,
        dir: 'asc'
      },
      items: SORTERS.date.items
    }
  },
  device: {
    key: 'device',
    label: 'Device',
    toKey: (s) => s.sale.device || 'unknown',
    toLabel: ({ key }) => (
      <DeviceIconWithLabel size={18} device={key as Device} />
    ),
    defaultSorter: {
      key: 'device',
      label: 'Device',
      groups: { sort: (g) => getDeviceSortValue(g.key as Device), dir: 'asc' },
      items: SORTERS.date.items
    }
  },
  partner: {
    key: 'partner',
    label: 'Network',
    toKey: (s) => s.sale.partnerKey,
    toLabel: ({ key }) => {
      const partner = getKnownPartnerForKey(key);
      return partner ? partner.name : key;
    },
    defaultSorter: {
      key: 'partner',
      label: 'Network',
      groups: { sort: (g) => g.key, dir: 'asc' },
      items: SORTERS.date.items
    }
  },
  subId: {
    key: 'subId',
    label: 'Sub ID',
    toKey: (s) =>
      getTrackingLabelWithoutAffilimateId(s.sale.trackingLabel || '') ||
      UNKNOWN,
    toLabel: ({ key }) => (key === UNKNOWN ? 'None' : key),
    defaultSorter: {
      key: 'subId',
      label: 'Sub ID',
      groups: { sort: (g) => g.key, dir: 'asc' },
      items: SORTERS.date.items
    }
  }
};

const ROW_TO_KEY = (s: ITrackedConvertedSale) => s.sale.saleId;

export const SalesListCard = ({
  sales,
  columns,
  sorter
}: {
  sales: ITrackedConvertedSale[];
  columns: SalesListColumn[];
  sorter: SalesSorter;
}) => {
  const [
    selectedSale,
    setSelectedSale
  ] = useState<ITrackedConvertedSaleWithProduct | null>(null);
  const hasPayouts = useHasPayouts();

  return (
    <>
      <RowsRenderer
        variant="contained"
        rows={sales}
        columns={columns}
        sorter={sorter}
        renderHead={true}
        chunkSize={30}
        rootMargin="400px"
        otherProps={{ hasPayouts }}
        rowHeight={ROW_HEIGHT}
        rowToKey={ROW_TO_KEY}
        onRowClick={(d) => {
          setSelectedSale(d);
        }}
      />
      <SalesDrawer
        sale={selectedSale}
        open={selectedSale !== null}
        onClose={() => {
          setSelectedSale(null);
        }}
      />
    </>
  );
};

type GroupedListProps = {
  groupKey: string;
  label: React.ReactNode;
  earnings: IEarning;
  sales: ITrackedConvertedSaleWithProduct[];
  columns: SalesListColumn[];
  sorter: SalesSorter;
  collapsed: boolean;
  setGroupCollapsed: (key: string, collapsed: boolean) => void;
};

const GroupedList = React.memo(
  ({
    groupKey,
    label,
    earnings,
    sales,
    columns,
    sorter,
    collapsed,
    setGroupCollapsed
  }: GroupedListProps) => {
    return (
      <Section collapse={collapsed}>
        <SalesGroupHeader
          earnings={earnings}
          expanded={!collapsed}
          onExpand={() => setGroupCollapsed(groupKey, !collapsed)}
        >
          {label}
        </SalesGroupHeader>
        {!collapsed && (
          <SalesListCard sales={sales} columns={columns} sorter={sorter} />
        )}
      </Section>
    );
  },
  debuggedShallowEqual(
    (p: GroupedListProps) => `Group ${p.groupKey.substring(0, 20)}`,
    { logOnlyOnChange: true, disable: true }
  )
);

export const DEFAULT_SALESLIST_PAGE_SIZE = 100;

export const SalesList: React.FC<{
  sales: ITrackedConvertedSale[];
  page: number;
  setPage: (n: number) => void;
  pageSize: number;
  paginateExternally?: boolean;
  products?: Doc<IProduct>[];
  visibleColumns?: Set<ColumnName>;
  search?: string;
  height?: number;
  currency: CurrencyCode; // pass in later
  grouper?: SalesGrouper | null;
  groupsCollapsed?: {
    defaultValue: boolean;
    byKey: { [key: string]: boolean };
  };
  setGroupCollapsed?: (key: string, collapsed: boolean) => void;
  sorter?: SalesSorter;
}> = React.memo(
  ({
    sales,
    page,
    setPage,
    pageSize,
    paginateExternally,
    products = EMPTY_ARR,
    search = '',
    height = HEIGHT,
    visibleColumns = new Set(DEFAULT_VISIBLE_COLUMNS),
    currency,
    grouper = null,
    groupsCollapsed = { defaultValue: false, byKey: {} },
    setGroupCollapsed = NOOP,
    sorter = SORTERS.date
  }) => {
    const trackedSales = useMemo(() => {
      const productsById = keyBy(products, (p) => p.id);
      return sales.map<ITrackedConvertedSaleWithProduct>((s) =>
        s.click && s.click.pId
          ? { ...s, product: productsById[s.click.pId] }
          : s
      );
    }, [sales, products]);

    const columns = useMemo(
      () =>
        visibleColumns
          ? COLUMNS.filter((c) => visibleColumns.has(c.key))
          : COLUMNS,
      [visibleColumns]
    );

    const groups = useMemo(
      () =>
        grouper
          ? applySorterOnGroups(
              sorter,
              groupSales(trackedSales, currency, grouper.toKey),
              sorter.groups.dir
            )
          : EMPTY_ARR,
      [trackedSales, grouper, currency, sorter]
    );

    const { paginatedGroups, groupPageCount } = useMemo(() => {
      if (paginateExternally) {
        return {
          paginatedGroups: groups,
          groupPageCount: 1
        };
      }
      const zeroPage = page - 1;
      const pageStart = zeroPage * pageSize;
      const pageEnd = pageStart + pageSize;
      const paginatedGroups = groups.slice(pageStart, pageEnd);
      const groupPageCount = Math.ceil(groups.length / pageSize);

      return {
        paginatedGroups,
        groupPageCount
      };
    }, [groups, page, pageSize, paginateExternally]);

    const { paginatedSales, salesPageCount } = useMemo(() => {
      if (paginateExternally) {
        return {
          paginatedSales: trackedSales,
          salesPageCount: trackedSales.length < pageSize ? page : page + 1
        };
      }
      const zeroPage = page - 1;
      const pageStart = zeroPage * pageSize;
      const pageEnd = pageStart + pageSize;
      const sortedSales = applySorterOnItems(
        sorter,
        trackedSales,
        sorter.items.dir
      );
      const paginatedSales = sortedSales.slice(pageStart, pageEnd);
      const salesPageCount = Math.ceil(trackedSales.length / pageSize);

      return {
        paginatedSales,
        salesPageCount
      };
    }, [trackedSales, page, pageSize, sorter, paginateExternally]);

    const PaginationComponent = paginateExternally
      ? CustomPagination
      : Pagination;

    console.log(salesPageCount, grouper);

    if (!grouper) {
      return (
        <Section>
          <SalesListCard
            sales={paginatedSales}
            columns={columns}
            sorter={sorter}
          />
          {salesPageCount > 1 && (
            <>
              <br />
              <PaginationComponent
                count={salesPageCount}
                page={page}
                siblingCount={0 /* this is buggy and MUI and doesn't work */}
                onChange={(_: ChangeEvent<unknown>, newPage: number) => {
                  setPage(newPage);
                }}
              />
            </>
          )}
        </Section>
      );
    }

    return (
      <div>
        {paginatedGroups.map((group) => (
          <GroupedList
            key={group.key}
            groupKey={group.key}
            label={grouper.toLabel(group)}
            earnings={group.summary}
            sales={group.items}
            columns={columns}
            sorter={sorter}
            collapsed={
              groupsCollapsed.byKey[group.key] === undefined
                ? groupsCollapsed.defaultValue
                : !!groupsCollapsed.byKey[group.key]
            }
            setGroupCollapsed={setGroupCollapsed}
          />
        ))}
        {grouper.key === 'date' && salesPageCount > 1 && (
          <>
            <br />
            <PaginationComponent
              count={salesPageCount}
              page={page}
              siblingCount={0 /* this is buggy and MUI and doesn't work */}
              onChange={(_: ChangeEvent<unknown>, newPage: number) => {
                setPage(newPage);
              }}
            />
          </>
        )}
        {grouper.key !== 'date' && groupPageCount > 1 && (
          <PaginationComponent
            count={groupPageCount}
            page={page}
            onChange={(_: ChangeEvent<unknown>, newPage: number) => {
              setPage(newPage);
            }}
          />
        )}
      </div>
    );
  },
  debuggedShallowEqual('SalesList')
);
