import { Chip } from '@material-ui/core';
import React, { useMemo } from 'react';
import { ChevronDown, Columns as IconColumns } from 'react-feather/dist';
import { css } from '../../../emotion';
import { MultiSelector } from '../../MultiSelector';
import { IColumn } from '../Column';

export interface ColumnSelectorProps<Key extends string> {
  value: Set<Key>;
  onChange: (nextValue: Set<Key>) => void;
  columns: IColumn<any, Key, any>[];
  short?: boolean;
}

export const ColumnSelector = <Key extends string>({
  value,
  onChange,
  columns,
  short
}: ColumnSelectorProps<Key>) => {
  const options = useMemo(
    () =>
      columns.map((c) => ({
        label: c.alternateHead ? c.alternateHead() : c.head(),
        value: c.key
      })),
    [columns]
  );
  return (
    <MultiSelector
      value={value}
      onChange={onChange}
      options={options}
      legend="Columns"
    >
      <Chip
        classes={{
          label: css(() => ({
            display: 'inline-flex',
            alignItems: 'center'
          })),
          root: css(() => ({
            border: '1px solid rgb(0 0 0 / 6%)',
            '&:hover': {
              borderColor: 'rgba(0, 0, 0, 0.87) !important'
            }
          }))
        }}
        label={
          <>
            {short ? <IconColumns size={18} /> : 'Columns'}
            <ChevronDown
              size={18}
              className={css((t) => ({
                marginLeft: t.spacing(1),
                color: 'rgba(0, 0, 0, 0.54)'
              }))}
            />
          </>
        }
      />
    </MultiSelector>
  );
};
